import React from 'react';
import { View, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import signUpStyles from './signup.styles';
import { Text } from 'native-base';

export default class ValidationService {

  public static renderSignupErrorMsg = ( type: string, invalid: boolean | null, empty: boolean | null = null,
                                  invalidPassword: boolean | null = null, reasonTooShort: boolean | null = null ): JSX.Element | null => {
    let errorMessage = '';
    switch (type) {
      case 'email':
        if (invalid) {
          errorMessage = 'Please enter a valid email'
        }
        break;
      case 'emailConfirmation':
        if (invalid) {
          errorMessage = 'Emails do not match'
        }
        break;
      case 'firstName':
        if (invalid) {
          errorMessage = 'Please enter your First Name'
        }
        break;
      case 'lastName':
        if (invalid) {
          errorMessage = 'Please enter your Last Name'
        }
        break;
      case 'city':
        if (invalid) {
          errorMessage = 'Please enter your City'
        }
        break;
      case 'zip':
        if (invalid) {
          errorMessage = 'Please enter a valid Zip Code'
        }
        break;

      case 'password':
        if (empty) {
          errorMessage = 'Please enter a password'
        } else if (!empty && invalidPassword) {
          errorMessage = 'Must be more than 8 characters and include at least 1 number & 1 one letter'
        }
        break;
      case 'confirmPassword':
        if (empty) {
          errorMessage = 'Please confirm your password'
        } else if (!empty && invalidPassword) {
          errorMessage = 'Passwords do not match'
        }
        break;
      case 'enrollmentReason':
        if (empty) {
          errorMessage = 'Please explain why you want to enroll.'
        } else if (!empty && reasonTooShort) {
          errorMessage = 'Your response must be at least 100 characters.'
        }
        break;
    }
    if (errorMessage) {
      return (
        <View style={signUpStyles.errorTextContainer}>
          <Text style={signUpStyles.errorTextStyle}>
            {errorMessage}
          </Text>
        </View>
      )
    } else {
      return null;
    }
  };

  public static renderValidationIcon = (valid: boolean | null = false, invalid: boolean = false, showSuccess: boolean = true): JSX.Element | null => {
    if(invalid) {
      return <Icon name={Platform.OS === 'ios' ? 'ios-close-circle' : 'md-close-circle'} size={24} color="#ff4081" style={{marginRight: -8}}/>
    } else if (valid && showSuccess) {
      return <Icon name={Platform.OS === 'ios' ? 'ios-checkmark-circle' : 'md-checkmark-circle'} size={24} color="#FFB300" style={{marginRight: -8}} />
    } else {
      return null;
    }
  };

}