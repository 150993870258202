import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create(
	{
		container: {
			backgroundColor: '#474646',
			flexDirection: 'column',
			alignItems: 'flex-start',
			height: 'auto',
			marginHorizontal: 0,
			// ...Platform.select({
			// 	android: {
			// 		elevation: 2,
			// 	},
			// 	ios: {
			// 		shadowColor: '#000000',
			// 		shadowOffset: {
			// 			height: .5,
			// 			width: 0,
			// 		},
			// 		shadowOpacity: 0.4,
			// 		shadowRadius: 1.5,
			// 	},
			// }),
		},
		input: {
			borderBottomWidth: 1, // 0 if no line
			borderColor: '#a1a1a1',
			fontSize: 15,
			color: '#fff',
			fontWeight: '400',
			marginBottom: -2,
			marginHorizontal: 8,
			marginTop: -2,
			overflow: 'hidden',
			paddingBottom: 0,
			paddingLeft: 2,
			paddingTop: 0,
		},
		textInputContainer: {
			flexDirection: 'row',
		},
	},
);