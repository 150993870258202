import React, { Component } from 'react';
import { Dimensions, Platform, Text, View } from 'react-native';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import { Course } from '../../models/course';
import { Content, Container, Card, CardItem, Left, Icon, Accordion, List, ListItem, Fab } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { CourseProgress } from '../../models/user';
import { fetchCoursesApi } from '../../redux/actions';
import { DrawerNavigationProps } from '../../navigation/navigation';

interface State {
  readonly test: string;
}

interface Props extends DrawerNavigationProps<'Progress'> {
  readonly courses: Array<Course>;
  readonly progress: Array<CourseProgress>
  readonly fetchCoursesApi: () => void;
}

class ProgressContainer extends Component <Props, State> {

  public componentDidMount(): void {
    if(this.props.courses.length === 0 ) {
      this.props.fetchCoursesApi();
    }
  }

  private renderCompletionHeader = (item: any, expanded: boolean): JSX.Element => {
    return (
      <View
        style={[
        {
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center' ,
          backgroundColor: '#ffe57f',
        },
        expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null]}
      >
        <View style={{flex: .90}}>
          <Text style={{ fontWeight: expanded ? '600' : '400', fontSize: 14, paddingLeft: 8, paddingRight: 16, textAlign: 'left'}}>
            {item.completedCourses.length}  Course{ item.completedCourses.length === 1 ? '' : 's' } Completed
          </Text>
        </View>
        <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
          {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} style={{ fontSize: 20, color: ACTION_COLOR }} />
            : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} style={{ fontSize: 20, color: ACTION_COLOR }} />
          }
        </View>
      </View>
    )
  };

  private renderCompletionContent = (item: { completedCourses: Array<CourseProgress> }): JSX.Element => {
    return (
      <View style={{ backgroundColor: '#FFAB00'}}>
        <List>
          {item.completedCourses.map((course: CourseProgress, index: number) => {
            if(course) {
              return (
                <ListItem
                  key={index}>
                  <Left>
                    <Text style={{ fontWeight: 'bold', color: '#f7f7f7', fontSize: 16 }}>{course.title}</Text>
                  </Left>
                </ListItem>
              )
            }
          })}
        </List>
      </View>
    )
  };

  private renderProgressHeader = (item: any, expanded: boolean): JSX.Element => {
    return (
      <View
        style={[
        {
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          padding: 8,
          alignItems: 'center' ,
          backgroundColor: '#ffe57f',
        },
          expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null]}
      >
        <View style={{flex: .90}}>
          <Text style={{ fontWeight: expanded ? '600' : '400', fontSize: 14, paddingLeft: 8, paddingRight: 16, textAlign: 'left'}}>
            Progress on Unfinished Courses
          </Text>
        </View>
        <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
          {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} style={{ fontSize: 20, color: ACTION_COLOR }} />
            : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} style={{ fontSize: 20, color: ACTION_COLOR }} />
          }
        </View>
      </View>
    )
  };

  private renderProgressContent = (item: { incompleteCourses: Array<CourseProgress> }): JSX.Element => {
    return (
      <View style={{ backgroundColor: '#FFAB00'}}>
        <List>
          {item.incompleteCourses.map((course: CourseProgress, index: number) => {
            if(course) {
              const content = course?.chaptersCompleted?.length ? `Ch(s) ${course?.chaptersCompleted?.toString()} Finished` :
                'No chapters finished yet.';
              return (
                <ListItem
                  key={index}>
                  <View style={{ flex: .40 }}>
                    <Text style={{ fontWeight: 'bold', color: '#000', fontSize: 16 }}>{course.title}:</Text>
                  </View>
                  <View style={{ flex: .60 }}>
                    <Text style={{ textAlign: 'right' }}>{content}</Text>
                  </View>
                </ListItem>
              )
            }
          })}
        </List>
      </View>
    )
  }

  public render = (): React.ReactNode =>  {
    const { progress, courses } = this.props;

    const coursesWithName = progress.map(record => {
      return { ...record, title: courses.length > 0 ? courses.find((data) => data.id === record.courseId).title: '' }
    });

    const completedCourses = coursesWithName.filter(record => record.completed);
    const incompleteCourses = coursesWithName.filter(record => !record.completed);

    return (
      <Container  style={{backgroundColor: '#474646'}}>
        <Content
          style={{margin: 0, padding: 16, backgroundColor: '#474646'}}
          contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Card style={[ Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
            <CardItem
              header
              bordered
              first
              style={{ backgroundColor: PRIMARY_COLOR_ACCENT,  borderColor: '#474646'}}
            >
              <Text style={{fontWeight: 'bold'}}>Progress</Text>
            </CardItem>
            <CardItem style={{ backgroundColor: '#ffe57f', marginBottom: -10 }}>
              <Accordion
                dataArray={[{completedCourses}]}
                renderHeader={this.renderCompletionHeader}
                renderContent={this.renderCompletionContent}
                expanded={[]}
              />
            </CardItem>
            <CardItem style={{ backgroundColor: '#ffe57f' }}>
              <Accordion
                dataArray={[{incompleteCourses}]}
                renderHeader={this.renderProgressHeader}
                renderContent={this.renderProgressContent}
                expanded={[]}
              />
            </CardItem>
          </Card>
          <Fab
            style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center'  }}
            onPress={this.props.navigation.toggleDrawer}
            position={Platform.OS === 'web' && Dimensions.get('screen').width > 500  ? "topLeft" : "bottomRight"}
          >
            <Icon
              name="menu"
              style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0  }}
            />
          </Fab>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: Props) => {
  return {
    courses: state.coursesData.courses,
    progress: state.userData.progress,
  }
};

export default connect(mapStateToProps, { fetchCoursesApi })(ProgressContainer);
