import React from 'react';
import { Content, View, Text} from 'native-base';
import { contentStyles } from '../../content/content.styles';

type Prop = {
  readonly description: string;
  readonly fontSize: number;
}

const OverviewTab: React.FunctionComponent<Prop> = ({ description, fontSize }: Prop): JSX.Element => {
  const formattedDescription = description.replace(/\\n/g, '\n');

  return (
    <Content style={{margin: 0, padding: 16, backgroundColor: '#474646'}}>
      <View>
        <Text style={[contentStyles.text, { fontSize, lineHeight: fontSize + (fontSize/3) }]}>
          {formattedDescription}
        </Text>
      </View>
    </Content>
  );
};

export { OverviewTab }
