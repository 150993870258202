import React from 'react';
import { DispatchValidationFields, SignUpFormResults } from '../../../models/validation.model';

export default class SignUpService {
  public static validationChecks = ( {firstName, lastName, email, emailConfirmation, enrollmentReason, password, confirmPassword, authenticatedButNotRegistered } : DispatchValidationFields) => {
    let firstNameValid, lastNameValid, enrollmentReasonValid, emailValid, emailConfirmationValid, passwordConfirmationNotMissing,
    passwordNotMissing, passwordValid, confirmPasswordValid = null;

    firstNameValid = firstName.length >= 2;

    lastNameValid = lastName.length >= 2;

    if (email.length < 3) {
      if (!authenticatedButNotRegistered || (authenticatedButNotRegistered && (emailConfirmation || email))) {
        emailValid = false;
      } else {
        emailValid = true;
      }
    } else {
      emailValid = SignUpService.validateEmail(email);
    }

    enrollmentReasonValid = enrollmentReason.length >= 100;

    if (emailConfirmation.length < 3) {
      if (!authenticatedButNotRegistered || (authenticatedButNotRegistered && (emailConfirmation || email))) {
        emailConfirmationValid = false;
      } else {
        emailConfirmationValid = true;
      }
    } else {
      emailConfirmationValid = SignUpService.validateEmail(emailConfirmation) && (email === emailConfirmation);
    }

    if (!authenticatedButNotRegistered) {
      if (password.length === 0) {
        passwordValid = false;
        passwordNotMissing = false;
      } else if (password.length > 0) {
        passwordNotMissing = true;
        passwordValid = SignUpService.validatePassword(password);
      }

      if (confirmPassword.length === 0) {
        confirmPasswordValid = false;
        passwordConfirmationNotMissing = false;
      } else if (confirmPassword.length > 0) {
        passwordConfirmationNotMissing = true;
        confirmPasswordValid = (password === confirmPassword);
      }
    } else {
      passwordValid = true;
      passwordNotMissing = true;
      passwordConfirmationNotMissing = true;
      confirmPasswordValid = true;
    }

    const validationResults: SignUpFormResults  = {firstName, lastName, email, emailConfirmation, enrollmentReason, password, confirmPassword, firstNameValid, lastNameValid,
      emailValid, enrollmentReasonValid, emailConfirmationValid,
      passwordValid, passwordNotMissing, confirmPasswordValid, passwordConfirmationNotMissing};
    return validationResults;
  };

  public static validateEmail = ( email: string ) => {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(email.toLowerCase());
  };
  "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
  public static validatePassword = ( password: string ) => {
    function hasNumber(myString) {
      return /\d/.test(myString);
    }
    return hasNumber(password) && password?.length >= 8;
  };
}



