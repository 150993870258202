import React, { useState } from 'react'
import { View, Text, KeyboardType, Animated, Easing } from 'react-native';
import { Input, Item, Label, Icon} from 'native-base';
import signUpStyles from '../../features/auth/signup/signup.styles';
import ValidationService from './validation.service';

const SUCCESS_COLOR = '#ffe57f';
const ORIGINAL_COLOR = '#FFF';
const ORIGINAL_VALUE = 0;
const SUCCESS_VALUE = 1;

let interpolatedColor = new Animated.Value(ORIGINAL_VALUE);
const AnimatedVirtualizedItem = Animated.createAnimatedComponent(Item);

type Props = {
    label: string;
    onChange: (data: string) => void;
	valid: boolean;
    value: string;
	errMsg?: string;
    placeholder?: string;
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
    textArea?: boolean;
    icon?: any;
	numberOfLines?: number;
}

let displayErrMsg = false;

const AnimatedInput: React.FunctionComponent<Props>
	= ({ label, value, onChange, valid = true, icon = 'text', errMsg = '',
		   autoCapitalize = 'sentences', textArea = false, numberOfLines = 4 } : Props) => {

	const [focusState, setFocusState] = useState(false);

	const onFocusHandler = (focus: boolean) => {
		if(!focus && !displayErrMsg){
			displayErrMsg = true;
		}
        interpolatedColor = new Animated.Value(ORIGINAL_VALUE);
		setFocusState(focus);
        Animated.timing(interpolatedColor, {
            duration: 1000,
			easing: Easing.in(Easing.ease),
            toValue: SUCCESS_VALUE,
        }).start();
	};

    let specialColor = interpolatedColor.interpolate({
        inputRange: [ORIGINAL_VALUE, SUCCESS_VALUE],
        outputRange: [ORIGINAL_COLOR, SUCCESS_COLOR]
    });

	return (
		<View>
			<AnimatedVirtualizedItem
				floatingLabel
				success={valid ? valid : false}
				error={!valid}
				style={{borderColor: focusState ? specialColor : '#FFF', borderBottomWidth: focusState ? 8/3 : 2/3}}
			>
				<Label style={{color: focusState ? '#ffe57f' : '#FFF', fontWeight: focusState ? '600' : '200' }}>{label}</Label>
					<Input
						value={value}
						onChangeText={value => onChange(value)}
						numberOfLines={textArea ? numberOfLines : 1}
						multiline={textArea}
						onFocus={() => onFocusHandler(true)}
						onBlur={() => onFocusHandler(false)}
                        style={{color: focusState ? '#ffe57f' : '#FFF', fontSize: focusState ? 21 : 17 }}
						autoCapitalize={autoCapitalize}
						autoCorrect={textArea}
						spellCheck={textArea}
					/>
				<Icon active name={icon} style={{color: focusState ? '#ffe57f' : '#FFF'}} />
				</AnimatedVirtualizedItem>
				{
					(!valid && displayErrMsg) ?
					<View style={signUpStyles.errorTextContainer}>
						<Text style={signUpStyles.errorTextStyle}>
							{errMsg}
						</Text>
					</View> :
					null
				}
		</View>

	);
};

export { AnimatedInput };