export class NewSection {
  constructor(
    public topic: string = '',
    public likes: number = 0,
    public pages: [
      {
        id: string;
        question: string;
        note: string;
        content: string;
        pageNumber: number;
        sectionIndex: number;
        userAnswer?: string;
        answerId?: string;
        shareAnswer?: boolean;
      }
      ] = [
      {
        id: '',
        question:  '',
        note: '',
        content: '',
        pageNumber:  0,
        sectionIndex: 0
      }
    ]
  ){

  }

}