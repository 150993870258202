import React, { Component } from 'react';
import { Alert, Platform, Image, StatusBar, Text, View, ScrollView, Dimensions, } from 'react-native';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import { updateProfileImage, updateProfileInfo, } from '../../redux/actions';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { Container, Card, Icon, Fab, Button, ActionSheet, H2, Label, Switch, Toast } from 'native-base';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { Spinner } from '../../common/components/spinner/spinner.component';
import { DrawerNavigationProps } from '../../navigation/navigation';
import * as Sentry from 'sentry-expo';
import { ImagePickerOptions } from 'expo-image-picker/src/ImagePicker.types';
import { MatInput } from '../../common/components/mat-input/mat-input.component';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface State {
  readonly tempImage: string;
  readonly manipulatingImage: boolean;
  readonly shareEdited: boolean;
  readonly aboutMeEdited: string;
  readonly testimonyEdited: string;
  readonly enrollmentEdited: string;
  readonly editMode: boolean;
}

const IMAGE_PICKER_OPTIONS: ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  allowsEditing: true,
  quality: .75,
  aspect: [1, 1],
};

interface Props extends DrawerNavigationProps<'Settings'> {
  readonly aboutMe: string;
  readonly testimony: string;
  readonly profilePhotoUrl: string;
  readonly enrollmentReason: string;
  readonly updatingProfile: boolean;
  readonly updateProfileImage: (imageUri: string) => void;
  readonly updateProfileInfo: (aboutMe: string, testimony: string, enrollmentReason: string) => void;
}

class ProfileContainer extends Component <Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      editMode: false,
      tempImage: '',
      manipulatingImage: false,
      aboutMeEdited: props.aboutMe,
      testimonyEdited: props.testimony,
      enrollmentEdited: props.enrollmentReason,
    };

    this.openProfileImagePicker = this.openProfileImagePicker.bind(this);
    this.takePhoto = this.takePhoto.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.getPhotoFromLibrary = this.getPhotoFromLibrary.bind(this);
    this.saveProfileInfoUpdates = this.saveProfileInfoUpdates.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.updatingProfile && !this.props.updatingProfile) {
      this.setState({
        tempImage: '',
        editMode: false,
      })
    }
  }

  saveProfileInfoUpdates() {
    const { aboutMeEdited, enrollmentEdited, testimonyEdited, shareEdited } = this.state;

    if ((aboutMeEdited && (aboutMeEdited.length < 50 || aboutMeEdited.length > 500)) ||
      (testimonyEdited && (testimonyEdited.length < 50 || testimonyEdited.length > 500)) ||
      (!enrollmentEdited  || enrollmentEdited.length < 50 || enrollmentEdited.length > 250)) {
      Toast.show({
        duration: 5000,
        text: 'You must first fix the form errors.',
        type: 'danger'
      });
      return;
    }
    this.props.updateProfileInfo(aboutMeEdited, testimonyEdited, enrollmentEdited, shareEdited);
  }

  saveImage() {
    this.props.updateProfileImage(this.state.tempImage);
  }

  async takePhoto() {
    try {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status !== 'granted') {
        Alert.alert('Sorry, we need photo permissions to make this work.');
        return;
      }
      this.setState({ manipulatingImage: true });

      const image = await ImagePicker.launchCameraAsync(IMAGE_PICKER_OPTIONS);
      await this.processPhoto(image.uri);
      this.setState({ manipulatingImage: false });

    } catch (error) {
      this.setState({ manipulatingImage: false });
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      Alert.alert('Sorry there was an error taking the photo.');

    }
  }

  async getPhotoFromLibrary() {
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        Alert.alert('Sorry, we need camera roll permissions to make this work.');
        return;
      }

      this.setState({ manipulatingImage: true });
      const image = await ImagePicker.launchImageLibraryAsync(IMAGE_PICKER_OPTIONS);
      await this.processPhoto(image.uri);
      this.setState({ manipulatingImage: false });
    } catch (error) {
      this.setState({ manipulatingImage: false });
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      Alert.alert('Sorry there was an error uploading the photo.');
    }
  }

  async processPhoto(imageUri: string) {
    const manipulatedImage = await manipulateAsync(
      imageUri,
      [{ resize: { height: 512, width: 512 } }],
      { compress: .5, format: SaveFormat.PNG }
    );

    this.setState({
      tempImage: manipulatedImage.uri,
    })
  }

  openProfileImagePicker() {
    const renderIOSButtons = () => [ 'Take Photo', 'Upload Image', 'Cancel'];
    const renderAndroidButton = () => {
      return [
        { text: 'Take Photo', icon: 'camera', iconColor: ACTION_COLOR},
        { text: 'Upload Image', icon: 'folder', iconColor: ACTION_COLOR},
        { text: 'Cancel', icon: 'close', iconColor: '#fa213b'}
      ];
    };

    const renderWebButton = () => {
      return [
        { text: 'Upload Image', icon: 'folder', iconColor: ACTION_COLOR},
        { text: 'Cancel', icon: 'close', iconColor: '#fa213b'}
      ];
    };

    ActionSheet.show({
        options: Platform.OS === 'ios' ? renderIOSButtons() : Platform.OS === 'web' ? renderWebButton() : renderAndroidButton(),
        cancelButtonIndex: Platform.OS === 'web' ? 1 : 2,
        title: 'Update Profile Image'
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            Platform.OS === 'web' ? void this.getPhotoFromLibrary() : void this.takePhoto();
            break;
          case 1:
            if (Platform.OS === 'web') {
              break;
            }
            void this.getPhotoFromLibrary();
            break;
          case 2:
            break;
        }
      }
    )
  }

  public render(): React.ReactNode {
    const { profilePhotoUrl, updatingProfile, enrollmentReason, testimony, aboutMe } = this.props;
    const { tempImage, manipulatingImage, aboutMeEdited, testimonyEdited, enrollmentEdited, editMode, shareEdited } = this.state;

    return (
      <Container style={[{backgroundColor: '#474646', flex: 1 },  Platform.OS === 'web' && { paddingTop: 32 } ]}>
        <StatusBar barStyle="light-content" animated/>
        <KeyboardAwareScrollView style={{ margin: 0, padding: 16, backgroundColor: '#474646', flex: 1 }}>
          <View style={[{ flex: .25, justifyContent: 'center', alignItems: 'center', marginTop: 54 }, Platform.OS === 'web' && { minHeight: 200 }]}>
            {
              (tempImage || profilePhotoUrl) ? <Image source={{ uri: tempImage || profilePhotoUrl }} style={{ height: 148, width: 148, borderRadius: 74, borderColor: '#ffe57f', borderWidth: 4 }}/>
                : <Icon name="person-circle-outline" style={{ fontSize: 148, color: 'white' }}/>
            }
            { tempImage && (
              <View style={{ justifyContent: 'center', marginTop: 16, alignItems: 'center' }}>
                <Button onPress={this.saveImage} style={{  borderWidth: 1, backgroundColor: '#ff4081', borderColor: 'white', }}>
                  <Text style={{ fontFamily: 'Roboto', paddingHorizontal: 8, fontSize: 18, color: 'white' }}>Save Image</Text>
                </Button>
              </View>
            )}
            <View style={{ justifyContent: 'center', marginTop: 36, alignItems: 'center' }}>
              <Button iconRight onPress={this.openProfileImagePicker}>
                <Text style={{ fontFamily: 'Roboto', paddingLeft: 8, fontSize: 16, color: 'white' }}>{tempImage ? 'Change Image' : 'Update Profile Image'}</Text>
                <MaterialIcon
                  name="file-edit"
                  style={{ color: 'white', fontSize: 24, paddingHorizontal: 12 }}
                />
              </Button>
            </View>
            {(updatingProfile || manipulatingImage) && <Spinner size="large"/>}
          </View>
          <View style={{ marginTop: 32, alignItems: 'center' }}>
            <Card style={[{ backgroundColor: '#ffe57f', marginVertical: 16, }, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
              <View style={{ borderWidth: 2, borderColor: 'black' }}>
                {editMode ? (
                  <View style={{ padding: 16,   }}>
                    <MatInput
                      label="About Me"
                      onChange={(edit: string) => this.setState({ aboutMeEdited: edit })}
                      value={aboutMeEdited}
                      validation={aboutMeEdited?.length >= 50 && aboutMeEdited?.length <= 500}
                      displayValidationIcons={aboutMeEdited?.length > 0}
                      textArea
                      autoCapitalize="sentences"
                    />
                  </View>
                ) :(
                  <View>
                    <View style={{ backgroundColor: PRIMARY_COLOR_ACCENT, paddingTop: 8 }}>
                      <H2 style={{ fontFamily: 'Roboto_medium', textAlign: 'center', paddingBottom: 8, textDecorationLine: 'underline' }}>About Me</H2>
                    </View>
                    <Text style={{ fontFamily: 'Roboto', textAlign: 'left', padding: 16, fontSize: 18 }}>{aboutMe || 'Do you want to share about yourself?'}</Text>
                  </View>
                )}
              </View>
            </Card>
            <Card style={[{ backgroundColor: '#ffe57f', marginVertical: 16, }, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
              <View style={{ borderWidth: 2, borderColor: 'black' }}>
                {editMode ? (
                  <View style={{ padding: 16,   }}>
                    <MatInput
                      label="My Testimony"
                      onChange={(edit: string) => this.setState({ testimonyEdited: edit })}
                      value={testimonyEdited}
                      validation={testimonyEdited?.length >= 50 && testimonyEdited?.length <= 500}
                      textArea
                      displayValidationIcons={testimonyEdited?.length > 0}
                      autoCapitalize="sentences"
                    />
                  </View>
                ) :(
                  <View>
                    <View style={{ backgroundColor: PRIMARY_COLOR_ACCENT, paddingTop: 8 }}>
                      <H2 style={{ fontFamily: 'Roboto_medium', textAlign: 'center', paddingBottom: 8, textDecorationLine: 'underline' }}>Testimony</H2>
                    </View>
                    <Text style={{ fontFamily: 'Roboto', textAlign: 'left', padding: 16, fontSize: 18 }}>{testimony || 'Do you want to add your testimony?'}</Text>
                  </View>
                )}
              </View>
            </Card>
            <Card style={[{ backgroundColor: '#ffe57f', marginVertical: 16, }, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
              <View style={{ borderWidth: 2, borderColor: 'black' }}>
                {editMode ? (
                  <View style={{ padding: 16,   }}>
                    <MatInput
                      label="Enrollment Reason"
                      onChange={(edit: string) => this.setState({ enrollmentEdited: edit })}
                      value={enrollmentEdited}
                      validation={enrollmentEdited?.length >= 50 && enrollmentEdited?.length <= 250}
                      displayValidationIcons={enrollmentEdited?.length > 0}
                      textArea
                      autoCapitalize="sentences"
                    />
                  </View>
                ) :(
                  <View>
                    <View style={{ backgroundColor: PRIMARY_COLOR_ACCENT, paddingTop: 8 }}>
                      <H2 style={{ fontFamily: 'Roboto_medium', textAlign: 'center', paddingBottom: 8, textDecorationLine: 'underline' }}>Enrollment Reason</H2>
                    </View>
                    <Text style={{ fontFamily: 'Roboto', textAlign: 'left', padding: 16, fontSize: 18 }}>{enrollmentReason}</Text>
                  </View>
                )}
              </View>
            </Card>
            <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', marginTop: 16, paddingBottom: 90  }}>
              {!editMode ? (
                <Button
                  style={{ backgroundColor: ACTION_COLOR, borderWidth: 2, borderColor: 'black' }}
                  onPress={() => this.setState({ editMode: true })}
                >
                  <Text style={{ fontSize: 20, color: 'white', fontFamily: 'Roboto_medium', paddingHorizontal: 8 }}>Update Profile Info</Text>
                </Button>
              ) : (
                <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', }}>
                  <Button
                    style={{ backgroundColor: 'grey', borderWidth: 2, borderColor: 'black', marginRight: 16 }}
                    onPress={() => this.setState({ editMode: false, enrollmentEdited: '', testimonyEdited: '', aboutMeEdited: '' })}
                  >
                    <Text style={{ fontSize: 20, color: 'white', fontFamily: 'Roboto_medium', paddingHorizontal: 8 }}>CANCEL</Text>
                  </Button>
                  <Button
                    style={{ backgroundColor: ACTION_COLOR, borderWidth: 2, borderColor: 'black' }}
                    onPress={this.saveProfileInfoUpdates}
                  >
                    <Text style={{ fontSize: 20, color: 'white', fontFamily: 'Roboto_medium', paddingHorizontal: 8 }}>SAVE</Text>
                  </Button>
                </View>
              )}
            </View>
          </View>
        </KeyboardAwareScrollView>
        <Fab
          style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center' }}
          onPress={this.props.navigation.toggleDrawer}
          position={Platform.OS === 'web' && Dimensions.get('screen').width > 500  ? "topLeft" : "bottomRight"}
        >
          <Icon
            name="menu"
            style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0 }}
          />
        </Fab>
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState) => {

  return {
    aboutMe: state.userData.aboutMe,
    testimony: state.userData.testimony,
    enrollmentReason: state.userData.enrollmentReason,
    profilePhotoUrl: state.userData.profilePhotoUrl,
    updatingProfile: state.userData.updatingProfile,
  }
};

export default connect(mapStateToProps, { updateProfileImage, updateProfileInfo })(ProfileContainer);
