import React, { Component } from 'react';
import { Dimensions, Linking, NativeModules, Platform, StatusBar, Text, View, } from 'react-native';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import { addPushToken, adjustFontSize, initializeAccountDeletion, initializeLogout, removePushToken } from '../../redux/actions';
import { Content, Container, Card, CardItem, Icon, Right, Fab, H2, H3, Form, } from 'native-base';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { Spinner } from '../../common/components/spinner/spinner.component';
import { DrawerNavigationProps } from '../../navigation/navigation';
import { InputModal } from '../../common/components/input-modal-component';
import { AnimatedInput } from '../../common/components/input.component';
import { SubmitButton } from '../../common/components/submit-button';
import { registerForPushNotificationsAsync } from '../../common/services/notification.service';
import * as Notifications from 'expo-notifications';

const { RNAndroidOpenSettings } = NativeModules;

interface State {
  readonly deleteAccountConfirmation: string;
  readonly displayDeleteAccountModal: boolean;
}

interface Props extends DrawerNavigationProps<'Settings'> {
  readonly addPushToken: (token: string) => void;
  readonly removePushToken: (token: string) => void;
  readonly adjustFontSize: (increase: boolean) => void;
  readonly fontSize: number;
  readonly loggingOut: boolean;
  readonly loggedOut: boolean | null;
  readonly currentExpoToken: string;
  readonly initializeLogout: () => void;
  readonly initializeAccountDeletion: () => void;
}

class SettingsContainer extends Component <Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displayDeleteAccountModal: false,
      deleteAccountConfirmation: '',
    };
    this.togglePushNotifications = this.togglePushNotifications.bind(this);
  }

  public componentDidUpdate(prevProps: Props){
    
    if(!this.props.loggingOut && prevProps.loggingOut) {
      if(this.props.loggedOut) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: 'Login' }],
        })
      }
    }
  }

  private openAppSettings = (): void => {
    if (Platform.OS === 'ios') {
      void Linking.openURL('app-settings:');
    } else {
      RNAndroidOpenSettings.appDetailsSettings();
    }
  }

  public async togglePushNotifications() {
    const { currentExpoToken } = this.props;
    if (currentExpoToken) {
      this.disablePushNotifications();
    }

    if (!currentExpoToken) {
      const settings = await Notifications.getPermissionsAsync();
      settings.status === 'denied' ? this.openAppSettings() : this.registerForPushNotifications();
    }
  }

  private disablePushNotifications() {
    const { currentExpoToken } = this.props;
    this.props.removePushToken(currentExpoToken);
  }

  private async registerForPushNotifications() {
    const token = await registerForPushNotificationsAsync();
    this.props.addPushToken(token);
  }

  public renderAccountDeletionModal() {
    const { deleteAccountConfirmation, displayDeleteAccountModal } = this.state;
    return (
      <InputModal displayModal={displayDeleteAccountModal}>
        <Form>
          <View style={{ flex: 1, justifyContent: 'center', marginTop: 16, marginHorizontal: 8 }}>
            <H2 style={{ color: 'white', textAlign: 'center', marginBottom: 16, fontWeight: 'bold' }}>Delete Account</H2>
            <H3 style={{ color: 'white', flex: 1, textAlign: 'center'  }}>{`Are you sure you wish to delete your account and all of your user data?\n\nIf you are using the same account to play Eternity's Call, then your game play data will be wiped also.`}</H3>
            <H3 style={{ color: 'white', textAlign: 'center', fontWeight: 'bold', marginTop: 16  }}>Warning: This account cannot be undone.</H3>
          </View>
          <AnimatedInput
            value={deleteAccountConfirmation}
            label="Type DELETE to confirm"
            onChange={(text) => this.setState({ deleteAccountConfirmation: text })}
          />
        </Form>
        <View style={{marginTop: 16}}>
          <SubmitButton
            loading={false}
            disabled={deleteAccountConfirmation.toLowerCase() !== 'delete'}
            onPress={this.props.initializeAccountDeletion}
            text="DELETE"
          />
          <SubmitButton
            text="cancel"
            color={PRIMARY_COLOR_ACCENT}
            onPress={() => this.setState({ displayDeleteAccountModal: false })}/>
        </View>
      </InputModal>
    );
  }

  public render = (): React.ReactNode =>  {
    const { fontSize, currentExpoToken } = this.props;
    const realFontSize = Platform.OS === 'web' && Dimensions.get('screen').width > 750 ? (fontSize * 1.5) : fontSize
    if(this.props.loggingOut) {
      return <Spinner size="large"/>
    }

    return (
      <Container style={{backgroundColor: '#474646'}}>
        <StatusBar barStyle="light-content" animated/>
        <Content style={{margin: 0, padding: 16, backgroundColor: '#474646'}} contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Card style={[ Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
            <CardItem
              header
              bordered
              first
              style={{ backgroundColor: '#ffe57f', borderColor: PRIMARY_COLOR_ACCENT}}
            >
              <Text style={{fontWeight: 'bold', fontSize: realFontSize }}>Current content Font Size: {realFontSize}</Text>
            </CardItem>
            <CardItem
              button
              onPress={this.props.adjustFontSize.bind(this, true)}
              style={{ backgroundColor: '#ffe57f', paddingLeft: 32 }}>
              <Text>Increase Text Size</Text>
              <Right>
                <MaterialIcon name="format-font-size-increase" size={20} color={ACTION_COLOR}  />
              </Right>
            </CardItem>
            <CardItem
              button
              onPress={this.props.adjustFontSize.bind(this, false)}
              style={{ backgroundColor: '#ffe57f', paddingLeft: 32 }}
            >
              <Text>Decrease Text Size</Text>
              <Right>
                <MaterialIcon name="format-font-size-decrease" size={20} color={ACTION_COLOR}  />
              </Right>
            </CardItem>
            <CardItem
              button
              header
              bordered
              onPress={() => this.setState({ displayDeleteAccountModal: true })}
              style={{ backgroundColor: '#ffe57f', paddingLeft: 32, borderColor: PRIMARY_COLOR_ACCENT}}
            >
              <Text>Delete Account</Text>
              <Right>
                <MaterialIcon name="account-remove" size={20} color={ACTION_COLOR}  />
              </Right>
            </CardItem>
            <CardItem
              button
              header
              bordered
              onPress={this.togglePushNotifications}
              style={{ backgroundColor: '#ffe57f', paddingLeft: 32, borderColor: PRIMARY_COLOR_ACCENT}}
            >
              <Text>{currentExpoToken ? 'Disable Notification': 'Enable Notification'}</Text>
              <Right>
                <MaterialIcon name={currentExpoToken ?  'bell-off': 'bell-circle'} size={20} color={ACTION_COLOR}  />
              </Right>
            </CardItem>
            <CardItem
              button
              header
              bordered
              onPress={this.props.initializeLogout}
              style={{ backgroundColor: '#ffe57f', borderColor: PRIMARY_COLOR_ACCENT}}
            >
              <Text style={{fontWeight: 'bold' }}>Logout</Text>
              <Right>
                <Ionicons name={Platform.OS === 'android' ? 'md-log-out' : 'ios-log-out'} size={20} color={ACTION_COLOR}  />
              </Right>
            </CardItem>
          </Card>
          <Fab
            style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center' }}
            onPress={this.props.navigation.toggleDrawer}
            position={Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? "topLeft" : "bottomRight"}
          >
            <Icon
              name="menu"
              style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0 }}
            />
          </Fab>
        </Content>
        {this.renderAccountDeletionModal()}
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    fontSize: state.userData.fontSize,
    loggingOut: state.userData.loggingOut,
    loggedOut: state.userData.loggedOut,
    currentExpoToken: state.userData.currentExpoToken
  }
};

export default connect(mapStateToProps, { adjustFontSize, initializeLogout, initializeAccountDeletion, addPushToken, removePushToken })(SettingsContainer);
