import { combineReducers } from 'redux';

import coursesReducer, { CoursesAction, CoursesReducerState } from './courses.reducer';
import signUpReducer from './sign-up.reducer';
import userReducer, { UserAction, UserReducerState } from './user.reducer';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

const coursePersistConfig = {
    key: 'coursesData',
    storage: AsyncStorage,
    whitelist: ['courses', 'currentCourseId', 'currentChapterId', 'currentSectionId', 'currentPageIndex']
};

const userPersistConfig = {
    key: 'userData',
    storage: AsyncStorage,
    whitelist: ['answers', 'textToSpeechOptions', 'firstName', 'lastName', 'email', 'fontSize', 'progress', 'forumTopicsCreated',
    'forumResponses', 'forumAnswers', 'privateChats']
};

export default combineReducers({
    coursesData: persistReducer<CoursesReducerState, CoursesAction>(coursePersistConfig, coursesReducer),
    signUpData: signUpReducer,
    userData: persistReducer<UserReducerState, UserAction>(userPersistConfig, userReducer)
});
