import { ActionSheet, Body, Button, Form, Icon, Input, Item, ListItem, Right, Text, Textarea, View } from "native-base";
import React, { Fragment, useContext, useState } from 'react';
import { FlatList, LayoutAnimation, Platform, ScrollView, StyleSheet } from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { InputModal } from '../../../../../../common/components/input-modal-component';
import { SubmitButton } from '../../../../../../common/components/submit-button';
import { ACTION_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../../../../common/global.styles';
import { ForumAnswers, ForumResponse } from '../../../../../../models/course';
import ChatRoomStyles from './chat-room.styles';
import CourseContext, { CourseContextProps } from '../../../../course.context';
import { getAuth } from 'firebase/auth';

const { right, timeContainer, timeText, chatButton, chatIcon, input, sendIcon } = ChatRoomStyles;

type Props = {
    record: ForumAnswers,
    isReplyThreadChat: boolean,
    openedForumTopicId: string,
}

export const ChatRoom: React.FunctionComponent<Props> =
    ({ record, isReplyThreadChat, openedForumTopicId }: Props) => {

    const [displayReplyThreadState, setDisplayReplyThreadState] = useState({
        display: false,
        responseId: ''
    });

    const [editResponseState, setEditResponseState] = useState({
        response: '',
        responseId: ''
    });

    const [responseState, setResponseState] = useState('');

    const [replyState, setReplyState] = useState('');

    const { handleForumResponseEdit, handleForumResponseDelete, handleForumResponse } =  useContext<CourseContextProps>(CourseContext);

    const openResponseOptions = (response: string, responseId: string, answerId: string) => {
        // Vibration.vibrate();
        const renderIOSButtons = () => ['Edit', 'Delete', 'Close'];
        const renderAndroidButton = () => {
            return [
                {text: 'Edit Response', icon: 'create', iconColor: PRIMARY_COLOR_ACCENT},
                {text: 'Delete Response', icon: 'trash', iconColor: '#fa213b'},
                {text: 'Close', icon: 'close', iconColor: ACTION_COLOR}
            ]
        };

        ActionSheet.show({
                options: Platform.OS === 'ios' ? renderIOSButtons() : renderAndroidButton(),
                cancelButtonIndex: 2,
                destructiveButtonIndex: 1,
                title: 'Options'
            },
            buttonIndex => {
                if(buttonIndex === 0) {
                    setEditResponseState({
                        response,
                        responseId
                    })
                } else if (buttonIndex === 1){
                    handleForumResponseDelete({responseId, forumTopicId: openedForumTopicId, answerId});
                }
            }
        )
    };

    const toggleReplyThread = (responseId: string) => {
        if(displayReplyThreadState.display) {
            LayoutAnimation.linear();
            setDisplayReplyThreadState({
                display: false,
                responseId: ''
            });

            setReplyState('');
        } else {
            LayoutAnimation.linear();
            setDisplayReplyThreadState({
                display: true,
                responseId: responseId
            });
        }
    };

    const submitResponse = () => {
        if(isReplyThreadChat && displayReplyThreadState.display) {
            setReplyState('');
            handleForumResponse({response: replyState, forumTopicId: openedForumTopicId, answerId: record.id || '', responseId: displayReplyThreadState.responseId});
        } else {
            setResponseState('');
            handleForumResponse({response:responseState, forumTopicId: openedForumTopicId, answerId: record.id || '', responseId: ''});
        }
    };

    const handleCommentRowAction = (response: ForumResponse, userId: string, answerId: string) => {
        if(userId && response.userId === userId) {
            openResponseOptions(response.response, response.id || '', answerId)
        } else {
            toggleReplyThread(response.id || '')
        }
    }

    const renderResponseEditModal = (answerId: string) => {
        const { responseId, response } = editResponseState;
        return (
            <InputModal displayModal={responseId.length > 0}>
                <Form style={{margin: 16}}>
                    <Textarea
                        value={response}
                        onChangeText={value => setEditResponseState({...editResponseState, response: value})}
                        rowSpan={7}
                        multiline={true}
                        style={{color: PRIMARY_COLOR, fontSize: 16 }}
                        autoCapitalize='sentences'
                        autoCorrect={true}
                        spellCheck={true}/>
                </Form>
                <View style={{marginTop: 16}}>
                    <SubmitButton
                      loading={false}
                      disabled={response.length < 2}
                      onPress={() => {
                          LayoutAnimation.linear();
                          handleForumResponseEdit({
                              response,
                              responseId,
                              forumTopicId: openedForumTopicId,
                              answerId
                          });
                          setEditResponseState({response: '', responseId: ''});
                      }}
                      text='update'
                    />
                    <SubmitButton
                        text='cancel'
                        color={PRIMARY_COLOR_ACCENT}
                        onPress={() => setEditResponseState({response: '', responseId: ''})}/>
                </View>
            </InputModal>
        )
    };

    const renderRightSideCommentRow = (response: ForumResponse, answerId: string, responseId: string) => {
        const { currentUser } = getAuth();
        const timestamp = response.timeStamp.toDate().toLocaleDateString();

        if(!responseId) {
            return (
                <Right style={right}>
                    <View style={timeContainer}>
                        <Text note style={timeText}> {timestamp} </Text>
                    </View>
                    <View style={{flex: .30}} >
                        <Button transparent iconRight
                                onPress={() => handleCommentRowAction(response, currentUser ? currentUser.uid : '', answerId)}
                                style={chatButton}
                        >
                            <Icon active
                                  name={(currentUser && response.userId === currentUser.uid) ? "more" : "chatbubbles"}
                                  style={chatIcon}
                            />
                        </Button>
                    </View>
                </Right>
            )
        } else {
            return (
                <Right>
                    <Text note style={{fontSize: 11}}>{timestamp}</Text>
                </Right>
            )
        }
    };

    const renderInput = (): JSX.Element | null=> {
        if(!isReplyThreadChat || (isReplyThreadChat && displayReplyThreadState.display)) {
            return (
                <View style={{marginHorizontal: 10, marginTop: 10, marginLeft: isReplyThreadChat && displayReplyThreadState.display ? 32 : 0}}>
                    <Item rounded style={{backgroundColor: 'white'}}>
                        <Input
                            placeholder={isReplyThreadChat && displayReplyThreadState.display ? 'Submit a Reply...' : 'Submit a Comment...'}
                            style={input}
                            value={(isReplyThreadChat && displayReplyThreadState.display) ? replyState : responseState}
                            placeholderTextColor="purple"
                            maxLength={100}
                            autoCapitalize="sentences"
                            onChangeText={(isReplyThreadChat && displayReplyThreadState.display) ? setReplyState : setResponseState}
                        />
                        <Button
                            transparent
                            onPress={submitResponse}
                        >
                            { isReplyThreadChat && displayReplyThreadState.display ?
                                <MaterialCommunityIcon
                                    name='reply'
                                    size={32}
                                    style={{paddingRight: 16}}
                                /> :
                                <Icon name=''
                                      active ios='ios-send'
                                      android='md-send'
                                      style={sendIcon} /> }
                        </Button>
                    </Item>
                </View>
            )
        }

        return null;
    };

    return (
        <ScrollView>
            <FlatList<ForumResponse>
                keyExtractor={(item, index) => item.id || `${index}`}
                data={record.responses || []}
                renderItem={({item}: {item: ForumResponse}) => {
                    if((isReplyThreadChat && item.responseId && displayReplyThreadState.display) || (!isReplyThreadChat && !item.responseId)) {
                        return (
                            <Fragment>
                                <ListItem style={{
                                    marginLeft:(isReplyThreadChat && item.responseId && displayReplyThreadState.display) ? 32 : 16
                                }}>
                                    <Body>
                                        <Text note
                                              style={{fontSize: 11}}> {item.firstName} {item.lastName} </Text>
                                        <Text style={{fontSize: 12}}>{item.response}</Text>
                                    </Body>
                                    {renderRightSideCommentRow(item, record.id || '', item.responseId || '')}
                                </ListItem>
                                { !isReplyThreadChat ?
                                    <ChatRoom
                                        record={record}
                                        isReplyThreadChat={true}
                                        openedForumTopicId={openedForumTopicId}
                                    /> : null }
                            </Fragment>
                        )
                    } else {
                        return <Fragment/>;
                    }
                }}
                extraData={[setDisplayReplyThreadState, record.responses]}
            />
            {renderInput()}
            {renderResponseEditModal(record.id || '')}
        </ScrollView>
    )
};
