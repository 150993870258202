import React, { useContext } from 'react';
import { Content, Text } from 'native-base';
import { contentStyles } from '../content.styles';
import ContentContext, { ContentContextProps } from '../content.context';
import * as Clipboard from 'expo-clipboard';
import { Alert } from 'react-native';

type Prop = {
  readonly note: string;
}

const NotesTab: React.FunctionComponent<Prop> = ({note}: Prop): JSX.Element => {
  const { fontSize } =  useContext<ContentContextProps>(ContentContext);

  async function onPress() {
    await Clipboard.setStringAsync(note);
    Alert.alert('Copied', 'This note was copied to your clipboard.')
  }

  return (
    <Content style={{margin: 0, padding: 16, backgroundColor: '#474646'}}>
      <Text style={[contentStyles.text, { fontSize, lineHeight: fontSize + (fontSize/3) }]} onPress={onPress}>{note}</Text>
    </Content>
  );
};

export { NotesTab }