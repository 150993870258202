import React, { FC, useEffect, useState } from 'react';
import { GiftedChat } from 'react-native-gifted-chat'
import { useDispatch, useSelector } from 'react-redux';
import * as Clipboard from 'expo-clipboard';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import {
  deletePrivateChatMessage,
  editPrivateChatMessage, fetchOtherUserData,
  initPrivateChat,
  recachePrivateChatMessagesAndListenForUpdates,
  submitPrivateChatResponse,
  unsubscribeFromChatroom
} from '../../../redux/actions';
import { ForumResponse } from '../../../models/course';
import { Form, H3, Textarea } from 'native-base';
import { RootState } from '../../../redux/redux.typescript';
import { getPrivateChatRoomById } from '../../../redux/selectors';
import { PrivateChat } from '../../../models/private-chat';
import { Spinner } from '../../../common/components/spinner/spinner.component';
import { InputModal } from '../../../common/components/input-modal-component';
import { PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../common/global.styles';
import { SubmitButton } from '../../../common/components/submit-button';
import { getAuth } from 'firebase/auth';
import { ContentNavigationProps } from '../../../navigation/navigation';
import { generateColor } from '../../../common/helper-methods';
import { ProfileModal } from '../../profile/profile-modal.component';

export const PrivateChatPage: FC<ContentNavigationProps<'PrivateCourseChatRoom'>> = ({ navigation, route }: ContentNavigationProps<'PrivateCourseChatRoom'>): JSX.Element => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [editModalState, setEditModalState] = useState({ display: false, msgId: '', originalMsg: '', newMsg: '' });
  const newChatRoom = route.params?.new ?? false;
  const newChatRoomData: ForumResponse = route.params?.newChatRoomData ?? {} as ForumResponse;
  const chatRoomId: string = route.params?.chatRoomId ?? '';
  const [displayUserProfile, setDisplayUserProfile]: [{ id: string, name: string; }, any] = useState({ id: '', name: '',});
  const chatRoomData: PrivateChat = useSelector((state: RootState) => getPrivateChatRoomById(state, chatRoomId));
  const newChatRoomId: string = useSelector((state: RootState) => state.userData.newChatRoomId);
  const profilePhotoUrl: string = useSelector((state: RootState) => state.userData.profilePhotoUrl);
  const otherUserProfile = useSelector((state: RootState) => state.userData.otherUserProfiles.byUserId?.[chatRoomData?.otherUserId]);
  const dispatch = useDispatch();

  const { currentUser } = getAuth();

  useEffect(() => {
    dispatch(fetchOtherUserData(chatRoomData.otherUserId))
    dispatch(recachePrivateChatMessagesAndListenForUpdates(chatRoomId));
    setIsLoadingState(true);

    navigation.setParams({
      otherUserName: chatRoomData.originalCommenterId === currentUser.uid ? chatRoomData.originalCommenter : chatRoomData.chatStarter,
    })

    return () => {
      unsubscribeFromChatroom?.();
    }
  }, []);

  useEffect(() => {
    if (newChatRoomId){
      navigation.setParams({
        chatRoomId: newChatRoomId, new: false
      })
    }
  }, [newChatRoomId]);

  useEffect(() => {
    if(isLoadingState){
      setIsLoadingState(false);
    }

    // if (!chatRoomData.messages || chatRoomData.messages.length !== (chatRoomData.msgCount - 1) && !isLoadingState) {
    //   /// fetch messages
    //   dispatch(recachePrivateChatMessages(chatRoomId));
    //   setIsLoadingState(true);
    // }
  }, [chatRoomData, chatRoomData.messages]);

  function onSend(messages = []): void {
    setIsLoadingState(true);

    if (newChatRoom) {
      messages.forEach((msg) => {
        dispatch(initPrivateChat(newChatRoomData.userId, `${newChatRoomData.firstName} ${newChatRoomData.lastName}`, newChatRoomData.id,
          newChatRoomData.response || newChatRoomData.answer, msg.text));
      });
    } else {
      messages.forEach((msg) => {
        dispatch(submitPrivateChatResponse(chatRoomId, msg.text));
      });
    }
  }

  function endEditSession(): void {
    setEditModalState({ display: false, msgId: '', originalMsg: '', newMsg: '' })
  }

  function _longPressHandler(context, data): void {
    let options = [
      'Copy Text',
      'Edit Message',
      'Delete Message',
      'Cancel',
    ];

    let callback = buttonIndex => {
      switch (buttonIndex) {
        case 0:
          void Clipboard.setStringAsync(data?.text);
          return;
        case 1:
          setEditModalState({ display: true, msgId: data._id, originalMsg: data.text, newMsg: data.text });
          return;
        case 2:
          dispatch(deletePrivateChatMessage(chatRoomId, data._id));
      }
    };

    if (data.user._id !== currentUser.uid) {
      options = [
        'Copy Text',
        'Cancel',
      ];

      callback = buttonIndex => {
        if (buttonIndex === 0) {
          void Clipboard.setStringAsync(data?.text);
        }
      }
    }

    const cancelButtonIndex = options.length - 1;
    context.actionSheet().showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      callback,
    )
  }

  const renderResponseEditModal = () => {
    return (
      <InputModal displayModal={editModalState.display}>
        <Form style={{margin: 16}}>
          <Textarea
            value={editModalState.newMsg}
            onChangeText={value => setEditModalState({...editModalState, newMsg: value})}
            rowSpan={5}
            multiline={true}
            style={{color: PRIMARY_COLOR, fontSize: 16 }}
            autoCapitalize='sentences'
            autoCorrect={true}
            spellCheck={true}/>
        </Form>
        <View style={{marginTop: 16}}>
          <SubmitButton
            loading={false}
            disabled={editModalState.originalMsg === editModalState.newMsg || editModalState.newMsg.length < 2}
            onPress={() => {
              setIsLoadingState(true);
              endEditSession();
              dispatch(editPrivateChatMessage(chatRoomId, editModalState.msgId, editModalState.newMsg ));
            }}
            text="update"
          />
          <SubmitButton
            text="cancel"
            color={PRIMARY_COLOR_ACCENT}
            onPress={endEditSession}/>
        </View>
      </InputModal>
    )
  };

  function renderHeaderText(): string {
    if (newChatRoom) {
      return `\n${newChatRoomData.firstName} ${newChatRoomData.lastName} said, "${newChatRoomData.response.trim()}" (${newChatRoomData.timeStamp})`;
    } else {
      return `\n${chatRoomData.originalCommenterId === currentUser.uid ? 'You' : chatRoomData.originalCommenter} said, "${chatRoomData.originalComment.trim()}" (${chatRoomData.startDate.toLocaleDateString()})`;
    }
  }

  if (isLoadingState) {
    return <Spinner size="large" />;
  }

  return (
    <View style={{flex: 1}}>
      <H3 style={{ margin: 16, fontSize: 16 }}>Chat started from this original message:{renderHeaderText()}</H3>
      <GiftedChat
        messages={chatRoomData ? chatRoomData.messages?.reverse() : []}
        onSend={messages => onSend(messages)}
        onLongPress={(context, data) => _longPressHandler(context, data)}
        user={{
          _id: currentUser.uid
        }}
        showUserAvatar
        renderAvatar={(data) => {
          const { _id, name } = data.currentMessage.user;
          const isUser = _id === currentUser?.uid;
          const source = isUser ? profilePhotoUrl : otherUserProfile?.profilePhotoUrl;

          if (source) {
            return (
              <TouchableOpacity
                onPress={() => setDisplayUserProfile({ id: _id, name })}
                style={{ flex: 1 }} key={_id}>
                <Image source={{ uri: source}} style={{ width: 48, height: 48, borderRadius: 24 }} />
              </TouchableOpacity>
            );
          }
          const nameSplit = name.split(' ');
          return (
            <TouchableOpacity
              onPress={() => setDisplayUserProfile({ id: _id, name })}
              style={{ backgroundColor: generateColor(), maxHeight: 48, width: 48, borderRadius: 24, justifyContent: 'center', flex: 1, alignItems: 'center' }}>
              <Text style={{ fontSize: 20 }}>{nameSplit?.[0]?.[0]}{nameSplit?.[1]?.[0]}</Text>
            </TouchableOpacity>
          )
        }}
        isAnimated
      />
      {renderResponseEditModal()}
      <ProfileModal
        userId={displayUserProfile.id}
        userName={displayUserProfile.name}
        onClose={() => setDisplayUserProfile({ id: '', name: '' })}
      />
    </View>
  )
}