import React, { Component } from 'react';
import * as WebBrowser from 'expo-web-browser';
import { Platform, View, StatusBar, NativeModules, LayoutAnimation } from 'react-native';
import { connect } from 'react-redux';
import {
	CardItem, Content, Container, Card, Form, Text, CheckBox, Body, Item, Toast
} from 'native-base';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import signUpStyles from './signup.styles';
import {
	firstNameValueChanged, lastNameValueChanged,emailConfirmationValueChanged,
  emailValueChanged, passwordValueChanged, confirmPasswordValueChanged, initValidation,
  fieldIsValid, initializeFormValidation, enrollmentReasonChanged
} from '../../../redux/actions';
import { ValidationFields, ValidationState } from '../../../models/validation.model';
import { SubmitButton } from '../../../common/components/submit-button';
import { RootState } from '../../../redux/redux.typescript';
import { MatInput } from '../../../common/components/mat-input/mat-input.component';
import { AuthNavigationProps } from '../../../navigation/navigation';
const { UIManager } = NativeModules;

type State = {
  readonly delay: number,
  readonly enableAndroid: boolean;
  readonly hasError: boolean;
  readonly offset: number,
  readonly overThirteen: boolean;
  readonly passwordHidden: boolean;
}

interface Props extends AuthNavigationProps<'SignUp'>, ValidationState, ValidationFields {
	readonly confirmPasswordValueChanged: (confirmPasswordValueChanged: string) => {type: string, payload: string};
	readonly emailConfirmationValueChanged: (email: string) => {type: string, payload: string};
	readonly initializeFormValidation: any;
	readonly initValidation: any;
	readonly lastNameValueChanged: (lastName: string) => {type: string, payload: string};
	readonly validationInProgress: () => {type: string};
  readonly emailValueChanged: (email: string) => {type: string, payload: string};
  readonly enrollmentReasonChanged: (reason: string) => {type: string, payload: string};
  readonly fieldIsValid: (field: string) => {type: string, payload: string};
  readonly firstNameValueChanged: (firstName: string) => {type: string, payload: string};
  readonly formInvalid: boolean | null;
  readonly passwordValueChanged: (password: string) => {type: string, payload: string};
  readonly signUpBeingProcessed: boolean;
  readonly signUpSuccess: boolean | null;
}

class SignUpPage extends Component<Props, State> {

	public static navigationOptions = {
		title: 'Please Sign Up',
		headerStyle: { backgroundColor: '#ffe57f'}
	};

  constructor(props: Props) {
    super(props);

		this.state = {
			overThirteen: false,
      delay: 1000,
      enableAndroid: true,
      hasError: false,
      offset: Platform.OS === 'ios' ? 25 : 115,
      passwordHidden: true,
    };

    if(Platform.OS === 'android') {
	    UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    LayoutAnimation.easeInEaseOut();

    this.initiateFormSubmission = this.initiateFormSubmission.bind(this);
    this.confirmationClick = this.confirmationClick.bind(this);
    this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { signUpSuccess } = this.props;
    if (!prevProps.signUpSuccess && signUpSuccess) {
      this.signUpSuccess();
    }
  }

  private signUpSuccess = () => {
    this.props.navigation.navigate('Login');
    const authenticatedButNotRegistered = this.props.route?.params?.authenticatedButNotRegistered ?? false;

    if (!authenticatedButNotRegistered) {
      Toast.show({
        duration: 5000,
        text: 'Sign up was successful. Please verify email before trying to login.',
        type: 'success'
      });
    }
	};

  private initiateFormSubmission(): void {
		const {
			firstName, lastName, email, emailConfirmation, enrollmentReason, password, confirmPassword, route
		} = this.props;
    const authenticatedButNotRegistered = route?.params?.authenticatedButNotRegistered ?? false;

    this.props.initValidation();
    this.props.initializeFormValidation({
      firstName, lastName, email, emailConfirmation, enrollmentReason, password, confirmPassword, authenticatedButNotRegistered
    });
	};


  private onFirstNameValueChange = ( firstName: string ) => {
    this.props.firstNameValueChanged(firstName.trim());

      if (!this.props.firstNameValid && firstName.trim().length > 1) {
        this.props.fieldIsValid('firstNameValid');
      }
    };

  private onLastNameValueChange = ( lastName: string ) => {
		this.props.lastNameValueChanged(lastName.trim());

		if (!this.props.lastNameValid && lastName.trim().length > 1) {
			this.props.fieldIsValid('lastNameValid');
		}
	};

  private async openPrivacyPolicy(): Promise<void> {
    await WebBrowser.openBrowserAsync('https://sites.google.com/view/theologicalacademy/privacy_policy');
  }
  private confirmationClick(): void {
		this.setState({
			overThirteen: !this.state.overThirteen
		});
	};

  public render = () => {
    const { containerSignUp, contentStyle, cardStyle, confirmContainer, checkboxContainer, confirmTextContainer, urlLink } = signUpStyles;
    const { passwordHidden } = this.state;

    const {
      firstName, lastName, email, enrollmentReason, enrollmentReasonValid, emailConfirmation, password, confirmPassword, passwordConfirmationNotMissing,
      emailValid, emailConfirmationValid, firstNameValid, lastNameValid, passwordValid, confirmPasswordValid, signUpBeingProcessed,
      passwordNotMissing, route,
    } = this.props;
    const authenticatedButNotRegistered = route?.params?.authenticatedButNotRegistered ?? false;

    return (
      <Container style={containerSignUp}>
        {Platform.OS === 'ios' ? <StatusBar barStyle="dark-content" animated={true}/> : null}
        <KeyboardAwareScrollView
          enableOnAndroid={this.state.enableAndroid}
          enableAutomaticScroll={this.state.enableAndroid}
          extraScrollHeight={this.state.offset}
          extraHeight={this.state.offset}
          keyboardShouldPersistTaps="handled"
          keyboardOpeningTime={this.state.delay}
        >
          <Content style={contentStyle} disableKBDismissScroll={true}>
            <Card style={cardStyle}>
              <Form>
                <MatInput
                  label="First Name"
                  onChange={this.onFirstNameValueChange}
                  value={firstName}
                  keyword="firstName"
                  validation={firstNameValid}
                  autoCapitalize="words"
                  icon="person"
                />
                <MatInput
                  label="Last Name"
                  onChange={this.onLastNameValueChange}
                  value={lastName}
                  autoCapitalize="words"
                  keyword="lastName"
                  validation={lastNameValid}
                  icon="person"
                />
                <MatInput
                  label={authenticatedButNotRegistered ? 'Email (optional)' : 'Email'}
                  onChange={this.props.emailValueChanged}
                  value={email}
                  keyword="email"
                  validation={emailValid}
                  icon="mail"
                  autoCapitalize="none"
                  extraProps={{
                    keyboardType: 'email-address'
                  }}
                />
                <MatInput
                  label={authenticatedButNotRegistered ? 'Confirm Email (optional)' : 'Confirm Email'}
                  onChange={this.props.emailConfirmationValueChanged}
                  value={emailConfirmation}
                  keyword="emailConfirmation"
                  validation={emailConfirmationValid}
                  icon="mail"
                  autoCapitalize="none"
                  extraProps={{
                    keyboardType: 'email-address'
                  }}
                />
                {!authenticatedButNotRegistered && (
                  <MatInput
                    label="Password"
                    onChange={this.props.passwordValueChanged}
                    value={password}
                    keyword="password"
                    validation={passwordValid}
                    icon={password?.length ? (passwordHidden ? 'eye' : 'eye-off') : 'lock-closed'}
                    iconAction={password?.length ? () => this.setState(prevState => ({ passwordHidden: !prevState.passwordHidden })) : null}
                    autoCapitalize="none"
                    invalidPassword={!passwordValid && passwordValid !== null}
                    empty={(!passwordNotMissing && passwordNotMissing !== null)}
                    extraProps={{
                      secureTextEntry: passwordHidden,
                    }}
                  />
                )}
                {!authenticatedButNotRegistered && (
                  <MatInput
                    label="Confirm Password"
                    onChange={this.props.confirmPasswordValueChanged}
                    value={confirmPassword}
                    keyword="confirmPassword"
                    validation={confirmPasswordValid}
                    icon={confirmPassword?.length ? (passwordHidden ? 'eye' : 'eye-off') : 'lock-closed'}
                    iconAction={confirmPassword?.length ? () => this.setState(prevState => ({ passwordHidden: !prevState.passwordHidden })) : null}
                    autoCapitalize="none"
                    invalidPassword={!confirmPasswordValid && confirmPasswordValid !== null}
                    empty={(!passwordConfirmationNotMissing && passwordConfirmationNotMissing !== null)}
                    extraProps={{
                      secureTextEntry: passwordHidden,
                     }}
                  />
                )}
                <MatInput
                  label="Why do you want to enroll?"
                  onChange={this.props.enrollmentReasonChanged}
                  value={enrollmentReason}
                  keyword="enrollmentReason"
                  validation={enrollmentReasonValid}
                  textArea={true}
                  icon="text"
                  autoCapitalize="sentences"
                  empty={!enrollmentReasonValid && enrollmentReasonValid !== null && enrollmentReason.length === 0}
                />
                <Item style={confirmContainer}>
                  <View style={checkboxContainer}>
                    <CheckBox checked={this.state.overThirteen} color="#FFB300" onPress={this.confirmationClick} />
                  </View>
                  <Body style={confirmTextContainer} >
                    <Text style={{fontSize: 13, color: '#fff'}}>
                      I confirm that I am over 13 years of age and agree to the
                      <Text
                        style={urlLink}
                        onPress={this.openPrivacyPolicy}
                      >
                        {` Privacy Policy`}
                      </Text>
                    </Text>
                  </Body>
                </Item>
                <CardItem style={{  backgroundColor: '#474646',}}>
                  <SubmitButton
                    loading={signUpBeingProcessed}
                    disabled={!this.state.overThirteen}
                    onPress={this.initiateFormSubmission}
                    text="submit"
                  />
                </CardItem>
              </Form>
            </Card>
          </Content>
        </KeyboardAwareScrollView>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => {
	const {
		email, emailConfirmation, firstName, enrollmentReason, enrollmentReasonValid, lastName, password, confirmPassword, emailValid, emailConfirmationValid,
		firstNameValid, lastNameValid, passwordValid, confirmPasswordValid,
		passwordNotMissing, passwordConfirmationNotMissing, signUpBeingProcessed, signUpSuccess, formInvalid
	} = state.signUpData;

  return {
    email, emailConfirmation, firstName, enrollmentReason, enrollmentReasonValid, lastName, password, confirmPassword, emailValid, emailConfirmationValid,
		firstNameValid, lastNameValid, passwordValid, confirmPasswordValid,
		passwordNotMissing, passwordConfirmationNotMissing, signUpBeingProcessed, signUpSuccess, formInvalid
	}
};

export default connect(mapStateToProps,  {
	firstNameValueChanged, lastNameValueChanged, enrollmentReasonChanged, emailConfirmationValueChanged,
	emailValueChanged, passwordValueChanged, confirmPasswordValueChanged,
    initValidation, fieldIsValid, initializeFormValidation
})(SignUpPage);

