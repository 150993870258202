import { Platform, StyleSheet } from 'react-native';

const loginStyles = StyleSheet.create({
  loginHeader: Platform.select ({
    android: {
      backgroundColor: '#ffe57f',
    },
    ios: {
      height: 16
    }
  }),
  container: {
    backgroundColor: '#303030',
    height: '100%',
    justifyContent: 'center',
    flex: 1
  },
  recoveryContainer: {
    backgroundColor: '#303030',
    height: '100%',
    justifyContent: 'space-around',
  },
  imageStyle: {
    height: 215,
    width: 175,
    marginTop: 16,
    alignSelf: 'center'
  },
  textContainer: {
    alignItems: 'center',
    marginBottom: 16
  },
  textStyle: {
    paddingTop: 20,
    fontSize: 20,
    color: '#FFF',
  },
  textUnderlineStyle: {
    paddingTop: 5,
    paddingBottom: 10,
    fontSize: 20,
    color: '#FFD740',
    textDecorationLine: 'underline'
  },
  errorTextStyle: {
    color: 'white',
    fontSize: 15,
    marginTop: 15,
    alignSelf: 'center'
  },
  forgotPasswordContainer : {
    marginBottom: -10
  },
  forgotPasswordText: {
    textDecorationLine: 'underline',
    color: '#ff4081',
  },
  form: {
    width: '100%',
    paddingBottom: 8,
    marginTop: Platform.OS === 'web' ? 12 : 0,
    marginBottom: Platform.OS === 'web' ? 24 : 0,
  },
  formContainer: {
    marginTop: 16,
  },
  spinnerContainer: {
    marginVertical: 48
  }
});

export default loginStyles;
