export function typescriptFind<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T  {
    if (argument === undefined || argument === null) {
        throw new TypeError(message);
    }

    return argument;
}


export function generateColor() {
    const randomColor = Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0');
    return `#${randomColor}`;
}