import { StyleSheet } from 'react-native';
import { ACTION_COLOR } from '../../../../../../common/global.styles';

export default StyleSheet.create({
    right: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 8,
        flex: .20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timeContainer: {
        flex: 1,
        display: 'flex',
        marginTop: -4
    },
    timeText: {
        fontSize: 11,
        paddingRight: 8,
        paddingTop: 8
    },
    chatButton: {
        paddingRight: 10,
        marginRight: -20,
        paddingTop: 5,
        paddingLeft: 8
    },
    chatIcon: {
        marginRight: 0,
        marginLeft: -5,
        color: ACTION_COLOR
    },
    input: {
        marginLeft: 10,
        paddingRight: 0,
        marginVertical: -5,
        fontSize: 15
    },
    sendIcon: {
        fontSize: 36,
        paddingRight: 0,
        paddingTop: 5
    }
});