import * as Speech from 'expo-speech';
import { Platform, Vibration } from 'react-native';
import { ActionSheet } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../global.styles';
import { Dispatch } from 'redux';
import { toggleTextToSpeechAutoContinue } from '../../redux/actions';


export interface TextToSpeechOptions {
	rate: number;
	pitch: number;
	voice?: string;
	onDone?: () => void;
	autoContinue?: boolean;
}

const renderIOSButtons = (continueActivated: boolean) => [ 'Change Pitch', 'Change Speed', 'Change Voice', `${continueActivated ? 'Do Not ' : ''}Go To Next Page When Done`, 'Close'];
const renderAndroidButton = (continueActivated: boolean) => {

	return [
		{ text: 'Change Pitch', icon: 'musical-note', iconColor: ACTION_COLOR},
		{ text: 'Change Speed', icon: 'speedometer', iconColor: ACTION_COLOR},
		{ text: 'Change Voice', icon: 'person-circle', iconColor: ACTION_COLOR},
		{ text: `${continueActivated ? 'Do Not ' : ''}Go To Next Page When Done`, icon: 'arrow-forward-circle-sharp', iconColor: ACTION_COLOR},
		{ text: 'Close', icon: 'close', iconColor: '#fa213b'}
	];
};

export default class VoiceService {
	private static instance: VoiceService;
	public static englishVoiceList = [];
	public static iosCurrentVoiceIndex = 0;
	public static autoContinueHandler: () => void;
	public static autoContinueActivated = false;

	public static async getVoices() {
		if (!VoiceService?.englishVoiceList?.length) {
			const voicesList = await Speech.getAvailableVoicesAsync();
			VoiceService.englishVoiceList = voicesList.filter((lang) => lang.language.startsWith('en'));
		}
	}

	public static getInstance() {
		if (!VoiceService.instance) {
			VoiceService.instance = new VoiceService();
		}
		return VoiceService.instance;
	}

	public static async speak(text: string, options: TextToSpeechOptions) {
		const isSpeaking = await Speech.isSpeakingAsync();
		if (isSpeaking){
			await Speech.resume();
		} else {
			Speech.speak(text, options);
		}
	}

	public static async stopSpeaking(){
		VoiceService.autoContinueActivated = false;
		await Speech.stop();
	}

	public static async pauseSpeaking(){
		await Speech.pause();
	}

	public static openSettings = (textToSpeechAction: () => void, dispatch: Dispatch, setDisplayVoicePicker: (display: boolean) => void, setDisplayVoicePitchSlider: (display: boolean) => void, setDisplayVoiceRateSlider: (display: boolean) => void, autoContinue: boolean) => {
		Vibration.vibrate();

		ActionSheet.show({
				options: Platform.OS === 'ios' ? renderIOSButtons(autoContinue) : renderAndroidButton(autoContinue),
				cancelButtonIndex: Platform.OS === 'ios' ? 5 : 4,
				title: 'Text-To-Speech Settings'
			},
			async (buttonIndex) => {
				switch (buttonIndex) {
					case 0:
						await VoiceService.stopSpeaking();
						setDisplayVoicePitchSlider(true);
						break;
					case 1:
						await VoiceService.stopSpeaking();
						setDisplayVoiceRateSlider( true)
						break;
					case 2:
						await VoiceService.getVoices;
						setDisplayVoicePicker(true);
						break;
					case 3:
						dispatch(toggleTextToSpeechAutoContinue());
						break;
				}
			}
		)
	};
}
