import React from 'react';
import { Content, View, Text} from 'native-base';
import { contentStyles } from '../../content/content.styles';

const UpdatesTab: React.FunctionComponent<{}> = () => {
  return (
    <Content style={{margin: 0, padding: 16, backgroundColor: '#474646'}}>
      <View>
        <Text style={[contentStyles.text, {fontSize: 16, lineHeight: 20}]}>
          No Updates at this Time
        </Text>
      </View>
    </Content>
  );
};

export { UpdatesTab }
