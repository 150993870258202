import React, { Component } from 'react';
import {
  Container, Content, Text, Title,
  Subtitle, Header, Tabs, View, Icon,
} from 'native-base';
import { RootState } from '../../redux/redux.typescript';

import { Platform, StatusBar, TouchableOpacity } from 'react-native';
import { Course, ForumResponseData } from '../../models/course';
import { connect } from 'react-redux';
import {
  fetchChaptersApi,
  fetchPagesApi,
  setCurrentChapter,
  setCurrentSection,
  editForumResponse,
  initializeNewForumTopic,
  fetchCourseForumApi,
  fetchForumAnswersApi,
  submitForumResponse,
  fetchResponsesApi,
  deleteForumResponse,
  submitForumAnswer,
  setCurrentPageIndex
} from '../../redux/actions'
import { getChapterAnswerCollection, getCourseById, getNumberOfAnswersByCourseId } from '../../redux/selectors';
import { ForumTab } from './components/forum/components/forum-tab/forum-tab.component';
import { UpdatesTab } from './components/update-tab.component';
import { OverviewTab } from './components/overview-tab.component';
import { ContentTab } from './components/content-tab.component';
import CommonService from '../../common/common.service';
import { Spinner } from '../../common/components/spinner/spinner.component';
import CourseContext from './course.context';
import { ContentNavigationProps } from '../../navigation/navigation';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface CourseContainerProps extends ContentNavigationProps<'Journey'> {
  course: Course;
  courseAnswerCount: number;
  chapterAnswerCollection: Array<{id: number; answerCount: number}>
  fetchingChapters: boolean;
  fetchingChaptersSuccess: boolean | null;
  fetchingForumAnswers: boolean;
  fetchingForumAnswersSuccess: boolean | null;
  fetchChaptersApi: (courseId: string) => void;
  fetchCourseForumApi: (courseId: string) => void;
  fetchForumAnswersApi:(courseId: string, forumTopicId: string) => void;
  fontSize: number;
  setCurrentChapter: (chapterId: number) => void;
  setCurrentSection: (sectionIndex: number) => void;
  setCurrentPageIndex: (pgIndex: number) => void;
  submitForumResponse: (data: ForumResponseData) => void;
  editForumResponse: (data: ForumResponseData) => void;
  deleteForumResponse: (data: ForumResponseData) => void;
  initializeNewForumTopic: ({ topic, answer} : {topic: string, answer: string }) => void;
  submitForumAnswer: ({topic, answer, forumTopicId} : {topic: string, answer: string, forumTopicId: string }) => any;
  fetchResponsesApi: (forumTopicId: string, answerId: string) => void;
}

export class CourseContainer extends Component <CourseContainerProps, any> {

  componentDidMount(): void{
    this.checkForCachedChapters();
  }

  private checkForCachedChapters = async () => {
    const course = this.props.route?.params?.course ?? null;
    const lastCached = await AsyncStorage.getItem(`@TheologicalAcademy:ChaptersLastCachedForCourse:${course.id}`);
    const millisecondsInWeek = 604800000;

    if (!course.chapters || course.chapters.length === 0 || course?.chapters?.[0]?.sectionsData?.[0]?.topic || !lastCached || (JSON.parse(lastCached) < (Date.now() - millisecondsInWeek))) {
      this.props.fetchChaptersApi(course.id);
      await AsyncStorage.setItem(`@TheologicalAcademy:ChaptersLastCachedForCourse:${course.id}`, JSON.stringify(Date.now()));
    }
  };

  private navigateToContent = (chapterId: number, sectionIndex: number): void => {
    this.props.setCurrentChapter(chapterId);
    this.props.setCurrentSection(sectionIndex);
    this.props.setCurrentPageIndex(0);
    this.props.navigation.navigate('Content', {
      course: this.props.course
    });
  };

  public goToForumAnswersPage = (forumTopicId: string, isChapterQuestion: boolean, questionPageId?: string) => {
    const { course} = this.props;

    this.props.navigation.navigate('CourseAnswers', {
      topicId: forumTopicId,
      courseId: course.id,
      isChapterQuestion,
      questionPageId: questionPageId || '',
    })

  };

  public fetchCourseForumAnswers = (forumTopicId: string) => {
    const {course} = this.props;
    this.props.fetchForumAnswersApi(course.id, forumTopicId);
  }

  public submitForumResponse = ({response, forumTopicId, answerId = '', responseId = ''}: ForumResponseData) => {
    const { submitForumResponse, course } = this.props;
    let topicData = course.forumTopics.find(record => record.id === forumTopicId);
    if(!topicData) {
      topicData = course.forumChQuestionTopics.find(record => record.id === forumTopicId);
    }
    const topic = topicData.topic;
    const answer = topicData.answers.find(data => data.id = answerId).answer;
    submitForumResponse({
      courseId: course.id,
      forumTopicId,
      answerId,
      response,
      responseId,
      topic,
      answer,
    });
  };

  public editForumResponse = ({response, responseId, forumTopicId, answerId}: ForumResponseData) => {
    const { editForumResponse, course } = this.props;

    editForumResponse({
      courseId: course.id,
      forumTopicId,
      answerId,
      response,
      responseId
    })
  };

  public deleteForumResponse = ({ responseId, forumTopicId, answerId }: ForumResponseData) => {
    const { deleteForumResponse, course } = this.props;

    deleteForumResponse({
      courseId: course.id,
      forumTopicId,
      answerId,
      responseId
    })
  };

  public addNewForum = ({ topic, answer} : {topic: string, answer: string }) => {
    const { initializeNewForumTopic } = this.props;

    initializeNewForumTopic({
      topic,
      answer
    })
  };

  public addForumAnswer = (forumTopicId: string, answer: string) => {
    const { submitForumAnswer, course } = this.props;
    const topic = course.forumTopics.find(topic => topic.id === forumTopicId).topic;
    submitForumAnswer({
      forumTopicId,
      answer,
      topic
    })
  };

  public render() {
    const {course, navigation, fontSize, chapterAnswerCollection, courseAnswerCount} = this.props;

    if(course && course.chapters && course.chapters.length > 0) {
      const largeSubtitle = course.subtitle.length > 40;
      return (
        <Container style={{backgroundColor: '#474646'}} >
          <Header style={[{backgroundColor: '#424242', marginTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0}]} hasTabs hasSubtitle>
            <StatusBar barStyle="light-content"/>
            <TouchableOpacity style={Platform.OS !== 'ios' ? {flex: .1, paddingLeft: 8, paddingTop: 8 } : { flex:.05, paddingLeft: 8, paddingTop: 8 }} onPress={() => navigation.navigate('Academy')} >
              <Icon name="arrow-back" style={{ color: '#ffe57f'}}/>
            </TouchableOpacity>
            <View style={[Platform.OS !== 'ios' ? {flex: .9, marginLeft: 16} : {flex:.95}, largeSubtitle ? {height: 150 } : {}]} >
              <Title style={{color: 'white'}}>{course.title}</Title>
              <Subtitle>
                <Text style={{ color: 'white', flex: 1 }} numberOfLines={2}>{course.subtitle}</Text>
              </Subtitle>
            </View>
          </Header>
          <Tabs tabBarUnderlineStyle={{backgroundColor: '#ffe57f'}} tabBarBackgroundColor={'#474646'} style={[{ backgroundColor: '#474646' }, largeSubtitle ? { marginTop: 16 } : {}]}>
            {course.description?.length > 0 && (
              CommonService.customTab('Overview',
              <OverviewTab
                fontSize={fontSize}
                description={course.description}
              />)
            )}
            {CommonService.customTab('Content',
              <ContentTab
                answerCountData={{chapterAnswerCollection, courseAnswerCount}}
                questionsCount={course.questionsCount}
                chapters={course.chapters}
                navigate={this.navigateToContent.bind(this)}
              />
            )}
            {CommonService.customTab('Forum',
              <CourseContext.Provider value={{
                handleForumResponseEdit: this.editForumResponse.bind(this),
                handleForumResponseDelete: this.deleteForumResponse.bind(this),
                handleForumResponse: this.submitForumResponse.bind(this),
                handleNewForum: this.addNewForum.bind(this),
                goToForumAnswersPage: this.goToForumAnswersPage.bind(this),
                loadForumTopicAnswers: this.fetchCourseForumAnswers.bind(this),
              }}>
                <ForumTab
                  forumTopics={course.forumTopics || []}
                  forumChQuestionTopics={course.forumChQuestionTopics || []}
                  fetchingForumAnswers={this.props.fetchingForumAnswers}
                  fetchingForumAnswersSuccess={this.props.fetchingForumAnswersSuccess}
                  loadForumData={this.props.fetchCourseForumApi.bind(this, course.id)}
                  handleNewForumAnswer={this.addForumAnswer.bind(this)}
                  fetchResponses={this.props.fetchResponsesApi}
                />
              </CourseContext.Provider>
            )}
            {CommonService.customTab('Updates', <UpdatesTab />)}
          </Tabs>
        </Container>
      )
    } else {
      return <Spinner size="large"/>
    }
  }
}

const mapStateToProps = (state: RootState, ownProps: CourseContainerProps) => {
  const course = ownProps.route?.params?.course ?? null;
  const id = course?.id ?? '';

  return {
    courseAnswerCount: getNumberOfAnswersByCourseId(state, id),
    chapterAnswerCollection: getChapterAnswerCollection(state, id),
    course: getCourseById(state, id),
    fetchingChapters: state.coursesData.fetchingChapters,
    fetchingChaptersSuccess: state.coursesData.fetchingChaptersSuccess,
    fetchingForumAnswers: state.coursesData.fetchingForumAnswers,
    fetchingForumAnswersSuccess: state.coursesData.fetchingForumAnswersSuccess,
    fontSize: state.userData.fontSize,
  }
};

export default connect(mapStateToProps, {
  fetchChaptersApi, fetchPagesApi, setCurrentChapter, setCurrentSection, editForumResponse, initializeNewForumTopic, setCurrentPageIndex,
  fetchCourseForumApi, fetchForumAnswersApi, submitForumResponse, fetchResponsesApi, deleteForumResponse, submitForumAnswer
})(CourseContainer);
