import { StyleSheet } from 'react-native';

export const courseStyles = StyleSheet.create({
  headerStyle: {
    flexDirection: 'row',
    flex: 1,
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center' ,
    backgroundColor: '#ffe57f'
  },
  contentContainer: {
    backgroundColor: '#e0e0e0',
    padding: 10,
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
});
