import React, { Component } from 'react';
import { Dimensions, Platform, StatusBar } from 'react-native';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import { Container, Icon, Fab, Tabs, } from 'native-base';
import { PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { ForumTopics, OrganizedForumAnswersByCourse, OrganizedForumResponseByCourse } from '../../models/user';
import { Course } from '../../models/course';
import CommonService from '../../common/common.service';
import { fetchForumAnswersApi, fetchUserTopicAnswerCount, recachePrivateChatMetadata } from '../../redux/actions';
import { TopicsTab } from './components/topics-tab.component';
import { PrivateTab } from './components/private-tab.component';
import { SortedPrivateChats } from '../../models/private-chat';
import { getPrivateChatsSortedByOtherUserId, getSortedForumAnswers, getSortedForumResponses } from '../../redux/selectors';
import { ResponsesTab } from './components/responses-tab.component';
import { AnswersTab } from './components/answers-tab.component';
import { ChatNavigationProps } from '../../navigation/navigation';

interface State {
  isLoading: boolean;
}

interface Props extends ChatNavigationProps<'Forum'> {
  courses: Array<Course>,
  forumTopicsCreated: ReadonlyArray<Readonly<ForumTopics>>;
  forumAnswers: OrganizedForumAnswersByCourse[];
  forumResponses: OrganizedForumResponseByCourse[];
  fetchForumAnswersApi: (forumTopicId: string) => void;
  fetchUserTopicAnswerCount: (courseId: string) => void;
  privateChats: Array<SortedPrivateChats>;
  recachePrivateChatMetadata: () => void;
}

class ForumPageContainer extends Component <Props, State> {

  public componentDidMount(): void {
    this.props.recachePrivateChatMetadata();
  }

  public render = (): React.ReactNode =>  {
    const { courses, forumResponses, privateChats, forumTopicsCreated } = this.props;
    const lgWeb = Platform.OS === 'web' && Dimensions.get('screen').width > 500;
    return (
      <Container style={[{ backgroundColor: '#474646'}, lgWeb && { height: '100%'}]}>
        <StatusBar barStyle="light-content"/>
          <Tabs tabBarUnderlineStyle={{backgroundColor: '#ffe57f'}} >
            {CommonService.customTab('Topics',
              <TopicsTab
                topics={forumTopicsCreated}
                courses={courses}
                fetchAnswerCounts={this.props.fetchUserTopicAnswerCount}
                navigation={this.props.navigation}
              />
            )}
            {CommonService.customTab('Answers',
              <AnswersTab
                answersByCourse={this.props.forumAnswers}
                navigation={this.props.navigation}
              />
            )}
            {CommonService.customTab('Responses',
              <ResponsesTab
                responsesByCourse={forumResponses}
                navigation={this.props.navigation}
              />
            )}
            {CommonService.customTab('Private',
              <PrivateTab
                privateChats={privateChats}
                navigation={this.props.navigation}
                fetchAnswerCounts={this.props.fetchUserTopicAnswerCount}
              />
            )}
          </Tabs>
          <Fab style={[{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center'  }, ]}
               onPress={this.props.navigation.toggleDrawer}
               position={Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? "bottomLeft" : "bottomRight"}
          >
            <Icon
              name="menu"
              style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0  }}
            />
          </Fab>
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: Props) => {
  return {
    courses: state.coursesData.courses,
    forumTopicsCreated: state.userData.forumTopicsCreated,
    forumAnswers: getSortedForumAnswers(state),
    forumResponses: getSortedForumResponses(state),
    privateChats: getPrivateChatsSortedByOtherUserId(state),
  }
};

export default connect(mapStateToProps, { fetchForumAnswersApi, fetchUserTopicAnswerCount, recachePrivateChatMetadata })(ForumPageContainer);
