import { Chapter, Section } from './course';

export class NewChapter implements Chapter {
  public id: number;
  public bibleQuote: Array<string>;
  public title: string;
  public subtitle: string;
  public bookQuote: string;
  public estimatedTime: number;
  public completionCount: number;
  public likes: number;
  public sections: Array<string>;
  public sectionsData: Array<Section> ;
  public questionsCount: number;

  constructor(
    id: number = 0,
    title: string = '',
    subtitle: string = '',
    bibleQuote: Array<string> = [],
    bookQuote: string = '',
    estimatedTime = 0,
    completionCount = 0,
    likes = 0,
    sections: Array<string> = [],
    sectionsData: Array<Section> = [],
    questionsCount = 0
  ) {
    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
    this.bibleQuote = bibleQuote;
    this.bookQuote = bookQuote;
    this.estimatedTime = estimatedTime;
    this.completionCount = completionCount;
    this.likes = likes;
    this.sections = sections;
    this.sectionsData = sectionsData;
    this.questionsCount = questionsCount;
  }
}