import { InputModal } from '../../common/components/input-modal-component';
import { Dimensions, Image, ScrollView, Text, View } from 'react-native';
import { Card, H2, H3, Icon } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { SubmitButton } from '../../common/components/submit-button';
import React, { FC, useEffect } from 'react';
import { fetchOtherUserData } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/redux.typescript';

type Props = {
  readonly userId: string;
  readonly userName: string;
  readonly onClose: () => null;
}
export const ProfileModal: FC<Props> = ({  userName, userId, onClose}) => {
  const otherUserProfile = useSelector((state: RootState) => state.userData.otherUserProfiles.byUserId?.[userId]);
  const dispatch = useDispatch();

  useEffect(() =>{
    if (userId) {
      dispatch(fetchOtherUserData(userId))
    }
  }, [userId]);

  const { profilePhotoUrl = '', aboutMe = '', testimony = '', enrollmentReason = '' } = otherUserProfile || {};

  return (
    <InputModal displayModal={!!userId}>
      <ScrollView style={{ height: Dimensions.get('screen').height * .75}}>
        <View style={{ flex: .25, justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
          {
            profilePhotoUrl ? <Image source={{ uri: profilePhotoUrl }} style={{ height: 148, width: 148, borderRadius: 74, borderColor: '#ffe57f', borderWidth: 4 }}/>
              : <Icon name="person-circle-outline" style={{ fontSize: 148, color: 'white' }}/>
          }
          <H2 style={{ color: 'white', marginTop: 16 }}>{userName}</H2>
        </View>
        {(!aboutMe && !testimony && !enrollmentReason) && (
          <View style={{ marginTop: 8, marginLeft: 12, justifyContent: 'center', alignItems: 'center' }}>
            <H3 style={{ color: ACTION_COLOR, marginTop: 16, textAlign: 'center' }}>-- This user has not yet shared their personal information --</H3>
          </View>
        )}
        <View style={{ marginTop: 8, marginLeft: 12 }}>
          {aboutMe && (
            <Card style={{ backgroundColor: '#ffe57f', marginVertical: 16, }}>
              <View style={{ borderWidth: 2, borderColor: 'black' }}>
                <View style={{ backgroundColor: PRIMARY_COLOR_ACCENT, paddingTop: 8 }}>
                  <H2 style={{ fontFamily: 'Roboto_medium', textAlign: 'center', paddingBottom: 8, textDecorationLine: 'underline' }}>About Me</H2>
                </View>
                <Text style={{ fontFamily: 'Roboto', textAlign: 'justify', padding: 16, fontSize: 18 }}>{aboutMe || 'Do you want to share about yourself?'}</Text>
              </View>
            </Card>
          )}
          {testimony && (
            <Card style={{ backgroundColor: '#ffe57f', marginVertical: 16, }}>
              <View style={{ borderWidth: 2, borderColor: 'black' }}>
                <View style={{ backgroundColor: PRIMARY_COLOR_ACCENT, paddingTop: 8 }}>
                  <H2 style={{ fontFamily: 'Roboto_medium', textAlign: 'center', paddingBottom: 8, textDecorationLine: 'underline' }}>Testimony</H2>
                </View>
                <Text style={{ fontFamily: 'Roboto', textAlign: 'justify', padding: 16, fontSize: 18 }}>{testimony || 'Do you want to add your testimony?'}</Text>
              </View>
            </Card>
          )}
          {enrollmentReason && (
            <Card style={{ backgroundColor: '#ffe57f', marginVertical: 16, }}>
              <View style={{ borderWidth: 2, borderColor: 'black' }}>
                <View style={{ backgroundColor: PRIMARY_COLOR_ACCENT, paddingTop: 8 }}>
                  <H2 style={{ fontFamily: 'Roboto_medium', textAlign: 'center', paddingBottom: 8, textDecorationLine: 'underline' }}>Enrollment Reason</H2>
                </View>
                <Text style={{ fontFamily: 'Roboto', textAlign: 'justify', padding: 16, fontSize: 18 }}>{enrollmentReason}</Text>
              </View>
            </Card>
          )}
        </View>
        <View style={{marginVertical: 16}}>
          <SubmitButton
            text="close"
            color={PRIMARY_COLOR_ACCENT}
            onPress={onClose}/>
        </View>
      </ScrollView>
    </InputModal>
  );
}