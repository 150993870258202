import React, { useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity, StatusBar, Platform, Alert, Linking } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { getAuth, GoogleAuthProvider, signInWithCredential, OAuthProvider, onAuthStateChanged } from 'firebase/auth';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Crypto from 'expo-crypto';

import { Button, Container, Content, H2, H3, Icon, Toast } from 'native-base'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import LoginForm from './components/login-form.component';
import loginStyles from './login.styles';
import { Spinner } from '../../../common/components/spinner/spinner.component';
import { AuthNavigationProps, DrawerNavigationProps } from '../../../navigation/navigation';
import * as Sentry from 'sentry-expo';
import { makeRedirectUri } from 'expo-auth-session';
import { connect } from 'react-redux';
import {
  initializeAccountDeletion,
} from '../../../redux/actions/index';

WebBrowser.maybeCompleteAuthSession();

interface Props extends AuthNavigationProps<'Login'> {
  readonly initializeAccountDeletion: () => void;
}
const DeleteAccountContainer: React.FunctionComponent<Props> = ({ navigation, initializeAccountDeletion:  deleteAccount }): JSX.Element => {
  const [isLoadingState, setIsLoadingState] = useState(false);
  const unsubscribe = useRef();

  const [googleAuthRequest, googleAuthExpoGoResponse, promptGoogleExpoGoLoginAsync] = Google.useIdTokenAuthRequest(
    {
      expoClientId: 'test',
      webClientId: '691010158959-b1dqp2u8ofqcvkancgoiu0tsk53go05d.apps.googleusercontent.com',
      clientId: '691010158959-b1dqp2u8ofqcvkancgoiu0tsk53go05d.apps.googleusercontent.com',
    },
  );

  const [request, googleAuthResponse, promptGoogleLoginAsync] = Google.useAuthRequest({
    expoClientId: '',
    iosClientId: '691010158959-vohqhn1v4ch01oaqsv2ejptps190001e.apps.googleusercontent.com',
    androidClientId: '691010158959-hgks2q6rm0v1ckskh0ra3nplgtc2rnpb.apps.googleusercontent.com',
    webClientId: '691010158959-b1dqp2u8ofqcvkancgoiu0tsk53go05d.apps.googleusercontent.com',
    redirectUri: makeRedirectUri(Platform.OS === 'web' ? { scheme: 'https://www.theologicalacademy.org', path: 'Auth/Login', useProxy: true } : { native: 'com.jktech.theologicalacademy:/oauth2redirect',  })
  });

  useEffect(() => {
    listenForAuthChange();
    return () => {
      if (unsubscribe?.current){
        unsubscribe?.current?.();
      }
    }
  }, []);

  useEffect(() => {
    if (googleAuthExpoGoResponse?.type) {
      handleGoogleAuthResponse();
    }
  }, [googleAuthExpoGoResponse]);

  React.useEffect(() => {
    if (googleAuthResponse?.type === 'success') {
      handleGoogleAuthResponse();
    }
  }, [googleAuthResponse]);

  function listenForAuthChange(): void {
    const auth = getAuth();

    unsubscribe.current = onAuthStateChanged(auth, (user) => {
      if (user) {
        deleteAccount();
      }
    });
  }

  function handleGoogleAuthResponse() {
    if (googleAuthExpoGoResponse?.type === 'success' || googleAuthResponse?.type === 'success') {
      let idToken;
      let accessToken = '';
      if (__DEV__) {
        idToken = googleAuthExpoGoResponse.params?.id_token;
      } else {
        idToken = googleAuthResponse?.authentication?.idToken;
        accessToken = googleAuthResponse?.authentication?.accessToken;
      }
      const auth = getAuth();
      const credential = GoogleAuthProvider.credential(idToken, accessToken);
      void loginWithAuth(auth, credential);
      setIsLoadingState(false);
    } else if (googleAuthExpoGoResponse?.type?.length && googleAuthExpoGoResponse.type !== 'cancel' ||
      googleAuthResponse?.type?.length && googleAuthResponse.type !== 'cancel') {
      setIsLoadingState(false);
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(new Error(`Google Auth Error: ${googleAuthExpoGoResponse?.type}`));
      Toast.show({
        duration: 5000,
        text: 'There was a problem logging in with Google. Please try a different login method.',
        type: 'danger'
      });
    } else {
      setIsLoadingState(false);
    }
  }

  async function loginWithAuth(auth, credential) {
    await signInWithCredential(auth, credential);
  }

  function initGoogleLogin() {
    setIsLoadingState(true);
    void (__DEV__ ? promptGoogleExpoGoLoginAsync : promptGoogleLoginAsync)();
  }

  async function initAppleLogin() {
    try {
      setIsLoadingState(true);
      const nonce = Math.random().toString(36).substring(2, 10);
      const hashedNonce = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce);
      const appleCredential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
        nonce: hashedNonce
      });
      const { identityToken } = appleCredential;
      const provider = new OAuthProvider('apple.com');
      const credential = provider.credential({
        idToken: identityToken!,
        rawNonce: nonce
      });
      const auth = getAuth();
      await loginWithAuth(auth,credential);
      setIsLoadingState(false);
    } catch (error) {
      setIsLoadingState(false);
      if (error.code === 'ERR_CANCELED') {
        // handle that the user canceled the sign-in flow
      } else {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(new Error(`Apple Auth Error: ${error}`));
        Toast.show({
          duration: 5000,
          text: 'There was a problem logging in with Apple. Please try a different login method.',
          type: 'danger'
        });
      }
    }
  }

  const {
    container, loginHeader, textContainer, textStyle, formContainer, textUnderlineStyle, forgotPasswordContainer, forgotPasswordText
  } = loginStyles;

  return (
    <Container style={container} testID='login-container'>
      {Platform.OS === 'ios' ? <StatusBar barStyle="dark-content" animated={true}/> : null}
      <View style={loginHeader} />
      <View style={{ justifyContent: 'center', marginVertical: 32, marginHorizontal: 8 }}>
        <H3 style={{ color: 'white', flex: 1, fontSize: 24, textAlign: 'center'  }}>{`Are you sure you wish to delete your account and all of your user data?\n\nIf you are sure, then enter your credentials.\n`}</H3>
        <H3 style={{ color: 'white', textAlign: 'center', fontWeight: 'bold', marginTop: 32  }}>Warning: This account cannot be undone.</H3>
      </View>
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        keyboardShouldPersistTaps="handled"
        enableAutomaticScroll={true}
        extraScrollHeight={0}
        extraHeight={140}
        keyboardOpeningTime={200}
      >
        <Content>
          <View style={formContainer}>
            <LoginForm
              navigation={navigation}
              setIsLoadingState={setIsLoadingState}
              deleteAccountPage
            />
          </View>
          <View style={textContainer}>
            <TouchableOpacity
              style={forgotPasswordContainer}
              onPress={() => navigation.navigate('PasswordRecovery')}
            >
              <Text style={forgotPasswordText}>Forgot Password?</Text>
            </TouchableOpacity>
            <Text style={textStyle}>Or</Text>
            <View style={{ flex: 1, marginTop: 8, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
              {Platform.OS === 'ios' && (
                <View style={{ flex: 1 }}>
                  <AppleAuthentication.AppleAuthenticationButton
                    buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                    buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                    cornerRadius={5}
                    style={{ flex: 1, height: 50, width: 224 }}
                    onPress={initAppleLogin}
                  />
                </View>
              )}
              <View style={{ flex: 1 }}>
                <Button
                  iconLeft
                  icon
                  onPress={initGoogleLogin}
                  style={{ flex: 1, justifyContent: 'center', marginTop: 16, width: 224, height: 48 }}
                >
                  <Icon name="logo-google" style={{ marginLeft: -4, fontSize: 16 }}/>
                  <Text style={{ marginLeft: 4,  color: 'white', fontSize: 20 }}>Sign in with Google</Text>
                </Button>
              </View>
            </View>
          </View>
        </Content>
      </KeyboardAwareScrollView>
      {isLoadingState && (
        <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Spinner size="large"/>
          </View>
        </View>
      )}
    </Container>
  )
};

export default connect(null, {  initializeAccountDeletion })(DeleteAccountContainer);

