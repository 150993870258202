import React, { FC } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import AcademiesContainer from '../features/academies/academies.container';
import { Text, View } from 'react-native';
import JourneyContainer from '../features/course/course.container';
import ContentContainer from '../features/content/content.container';
import SignUpPage from '../features/auth/signup/signup.page';
import ForumPage from '../features/forum/forum-page.container';
import PasswordRecoveryPage from '../features/auth/login/password-recovery.page';
import { getAuth } from 'firebase/auth';
import { LoginPage } from '../features/auth/login/login.container';
import { AnswersPage } from '../features/forum/components/answers.component';
import { ACTION_COLOR } from '../common/global.styles';
import { ChatPage } from '../features/forum/components/chat.component';
import { PrivateChatPage } from '../features/forum/components/private-chat.component';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/redux.typescript';
import { Course, ForumResponse } from '../models/course';
import { contentStyles } from '../features/content/content.styles';
import AppNavigator from './AppNavigator';
import DeleteAccountContainer from '../features/auth/login/delete-page.container';

export interface HomeNavigationProps<T extends keyof RootStackParamList> {
  readonly navigation: StackNavigationProp<RootStackParamList, T>;
  readonly route: RouteProp<RootStackParamList, T>;
}

export interface ContentNavigationProps<T extends keyof ContentStackParamList> {
  readonly navigation: StackNavigationProp<ContentStackParamList, T>;
  readonly route: RouteProp<ContentStackParamList, T>;
}

export interface DrawerNavigationProps<T extends keyof DrawerParamList> {
  readonly navigation: DrawerNavigationProp<DrawerParamList, T>;
  readonly route: RouteProp<DrawerParamList, T>;
}

export interface AuthNavigationProps<T extends keyof AuthStackParamList> {
  readonly navigation: StackNavigationProp<AuthStackParamList, T>;
  readonly route: RouteProp<AuthStackParamList, T>;
}

export interface ChatNavigationProps<T extends keyof ChatRoomStackParamList> {
  readonly navigation: StackNavigationProp<ChatRoomStackParamList, T>;
  readonly route: RouteProp<ChatRoomStackParamList, T>;
}

type RootStackParamList = {
  Auth: undefined;
  Main: undefined;
};

type AuthStackParamList = {
  DeleteAccountPage: undefined;
  Login: undefined;
  PasswordRecovery: undefined;
  SignUp: { authenticatedButNotRegistered?: boolean };
};

type ContentStackParamList = {
  Academy: undefined;
  Journey: { course: Course };
  Content: { course: Course; returnToPgAction?: boolean; header?: string };
  CourseAnswers: { question: string, topic: string, topicId: string, courseId: string, fromRootForumScreen: boolean, isChapterQuestion: boolean, questionPageId: string };
  CourseChatRoom: { topicId: string, answerId: string, courseId: string, isChapterQuestion: boolean, questionPageId: string };
  PrivateCourseChatRoom: { new: boolean, newChatRoomData: ForumResponse, chatRoomId: string, otherUserName: string };
};

type DrawerParamList = {
  Home: undefined;
  Academy: undefined;
  Forum: undefined;
  Progress: undefined;
  Notifications: undefined;
  About: undefined;
  Settings: undefined;
  Contact: undefined;
};

export type ChatRoomStackParamList = {
  Forum: undefined;
  Answers: undefined;
  ChatRoom: undefined;
  CourseChatRoom: undefined;
  PrivateChatRoom: undefined;
  Journey: undefined;
  Content: undefined;
};

const ROOT_STACK = createStackNavigator<RootStackParamList>();
const AUTH_STACK = createStackNavigator<AuthStackParamList>();
const CONTENT_STACK = createStackNavigator<ContentStackParamList>();
const CHAT_ROOM_STACK = createStackNavigator<ChatRoomStackParamList>();

export const ContentStack: FC<any> = (): JSX.Element => {
  return (
    <CONTENT_STACK.Navigator
      initialRouteName="Academy"
    >
      <CONTENT_STACK.Screen
        name="Academy"
        component={AcademiesContainer}
        options={{
          headerShown: false,
        }}
      />
      <CONTENT_STACK.Screen
        name="Journey"
        component={JourneyContainer}
        options={{
          headerShown: false,
        }}
      />
      <CONTENT_STACK.Screen
        name="Content"
        component={ContentContainer}
        options={({ route }) => {
          const header = route?.params?.header ?? '';
          return {
            headerStyle: { backgroundColor: '#424242'},
            headerRight: null,
            title: header,
            headerTitleStyle: contentStyles.headerStyle,
            // headerTitle: (
            //   <View style={contentStyles.headerStyle}>
            //     <Text style={{color: 'white', textAlign: 'center'}} numberOfLines={2}>{header}</Text>
            //   </View>
            // ),
            headerBackTitle: '',
            headerTintColor: '#ffe57f',
            headerRightContainerStyle: {flex: 0, width: 0},
          }
        }}
      />
      <CONTENT_STACK.Screen
        name="CourseAnswers"
        component={AnswersPage}
        options={({ route }) => {
          const question = route?.params?.question ?? '';
          const topic = route?.params?.topic ?? '';
          const extraProp = {
            headerTitle: ({ style }) => {
              return (
                <View style={{marginRight: -60, marginLeft: 24 }}>
                  <Text style={style} numberOfLines={2}>{question ? question : topic}</Text>
                </View>
              )
            },
          };
          return {
            ...extraProp,
            title: topic ? topic : 'Answer List',
            headerTitleStyle: { color: 'white' },
            headerStyle: { backgroundColor: '#424242' },
            headerTintColor: ACTION_COLOR,
            headerRight: null,
          }
        }}
      />
      <CONTENT_STACK.Screen
        name="CourseChatRoom"
        component={ChatPage}
        options={{
          title: 'Public Chat Room',
          headerTitleStyle: { color: 'white'},
          headerStyle: { backgroundColor: '#424242' },
          headerTintColor: ACTION_COLOR
        }}
      />
      <CONTENT_STACK.Screen
        name="PrivateCourseChatRoom"
        component={PrivateChatPage}
        options={{
          title: 'Private Live Chat Room',
          headerTitleStyle: { color: 'white'},
          headerStyle: { backgroundColor: '#424242' },
          headerTintColor: ACTION_COLOR
        }}
      />
    </CONTENT_STACK.Navigator>
  );
};

export const ChatRoomStack: FC<any> = (): JSX.Element => {
  return (
    <CHAT_ROOM_STACK.Navigator
      initialRouteName="Forum"
    >
      <CHAT_ROOM_STACK.Screen
        name="Forum"
        component={ForumPage}
        options={{
          headerTitle: 'My Theological Chat Rooms',
          headerTitleStyle: { color: 'white'},
          headerStyle: { backgroundColor: '#424242' },
        }}
      />
      <CHAT_ROOM_STACK.Screen
        name="Answers"
        component={AnswersPage}
        options={{
          title: 'Topic Answer List',
          headerTitleStyle: { color: 'white'},
          headerStyle: { backgroundColor: '#424242' },
          headerTintColor: ACTION_COLOR,
        }}
      />
      <CHAT_ROOM_STACK.Screen
        name="ChatRoom"
        component={ChatPage}
        options={{
          title: 'Answer Chat Room',
          headerTitleStyle: { color: 'white'},
          headerStyle: { backgroundColor: '#424242' },
          headerTintColor: ACTION_COLOR
        }}
      />
      <CHAT_ROOM_STACK.Screen
        name="PrivateChatRoom"
        component={PrivateChatPage}
        options={({ route }) => {
          const otherUser = route?.params?.otherUserName ?? '';
          return {
            title: otherUser ? `Private Chat with ${otherUser}` : 'Private Live Chat Room',
            headerTitleStyle: { color: 'white'},
            headerStyle: { backgroundColor: '#424242' },
            headerTintColor: ACTION_COLOR
          }
        }}
      />
      <CHAT_ROOM_STACK.Screen
        name="Journey"
        component={JourneyContainer}
      />
      <CHAT_ROOM_STACK.Screen
        name="Content"
        component={ContentContainer}
      />

    </CHAT_ROOM_STACK.Navigator>
  );
};


// initialRouteName: 'Home',
//   drawerBackgroundColor: '#424242',
//   contentOptions: {
// },
const AuthStack: FC<any> = (): JSX.Element => {
  return (
    <AUTH_STACK.Navigator>
      <AUTH_STACK.Screen
        name="Login"
        component={LoginPage}
        options={{
          headerStyle: { backgroundColor: '#ffe57f'},
          headerTitleStyle: {fontSize: 24, paddingBottom: 8, opacity: .90},
          title: 'Theological Academy',
        }}
      />
      <AUTH_STACK.Screen
        name="PasswordRecovery"
        component={PasswordRecoveryPage}
        options={{
          title: 'Reset Password',
          headerStyle: { backgroundColor: '#ffe57f' },
        }}
      />
      <AUTH_STACK.Screen
        name="SignUp"
        component={SignUpPage}
        options={{
          title: 'Please Sign Up',
          headerStyle: { backgroundColor: '#ffe57f' },
        }}
      />
      <AUTH_STACK.Screen
        name="DeleteAccountPage"
        component={DeleteAccountContainer}
        options={{
          title: 'Delete Account',
          headerStyle: { backgroundColor: '#ffe57f' },
        }}
      />
    </AUTH_STACK.Navigator>
  );
};

const RootStack: FC<any> = (): JSX.Element => {
  const currentUser = getAuth()?.currentUser;
  const loggedInMemory = useSelector((state: RootState) => state.userData?.loggedIn);
  const userId = useSelector((state: RootState) => state.userData?.id);
  const loggedIn = (userId && loggedInMemory && currentUser?.uid?.length);

  return (
    <ROOT_STACK.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      {loggedIn ? (
        <ROOT_STACK.Screen
          name="Main"
          component={AppNavigator}
        />
      ) : (
        <ROOT_STACK.Screen
          name="Auth"
          component={AuthStack}
        />
      )}
    </ROOT_STACK.Navigator>
  );
};

export default RootStack;