import React, { FC, MutableRefObject, useContext, useEffect, useRef } from 'react';
import { Content, Text } from 'native-base';
import { Alert, Dimensions, Platform, ScrollView, View } from 'react-native';
import { NavButton } from './nav-btn/nav-btn.component';
import { contentStyles} from '../content.styles';
import { Section } from '../../../models/course';
import ContentContext, { ContentContextProps } from '../content.context';
import * as Clipboard from 'expo-clipboard';

type Props = {
  readonly content: string;
  readonly currentPageIndex: number;
  readonly currentSectionId: number;
  readonly currentChapterId: number;
  readonly currentSection: Section;
  readonly numberOfChapters: number;
  readonly numberOfSections: number;
  readonly sectionNavigationHandler: (incrementation: number) => void;
  readonly chapterNavigationHandler: (incrementation: number) => void;
  readonly returnHome: () => void;
}

export const ContentTab: FC<Props> = ({ content, currentPageIndex, currentSectionId, numberOfChapters, returnHome,
        numberOfSections, currentChapterId, currentSection, sectionNavigationHandler, chapterNavigationHandler }: Props) : JSX.Element => {
  const { fontSize } =  useContext<ContentContextProps>(ContentContext);
  const contentRef: MutableRefObject<Content> = useRef(null);

  useEffect(() => {
    if(contentRef && contentRef.current && contentRef.current.scrollTo) {
      contentRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }
  }, [currentPageIndex]);

  async function onPress() {
    await Clipboard.setStringAsync(content);
    Alert.alert('Copied', 'This page was copied to your clipboard.')
  }

  const lgScreen = Platform.OS === 'web' && Dimensions.get('screen').width > 750;
  return (
    <ScrollView style={{margin: 0, padding: lgScreen ? 48 : 16, backgroundColor: '#474646'}} ref={contentRef}>
      <Text style={[contentStyles.text, { fontSize, lineHeight: fontSize + (fontSize/3) }]} onPress={onPress}>{content}</Text>
      <View style={contentStyles.buttonContainer}>
        <NavButton
          leftNav={true}
          currentPageIndex={currentPageIndex}
          currentSectionId={currentSectionId}
          currentChapterId={currentChapterId}
          sectionNavigation={sectionNavigationHandler}
          chapterNavigation={chapterNavigationHandler}
        />
        <NavButton
          leftNav={false}
          currentPageIndex={currentPageIndex}
          currentSectionId={currentSectionId}
          currentChapterId={currentChapterId}
          sectionNavigation={sectionNavigationHandler}
          chapterNavigation={chapterNavigationHandler}
          currentSectionLength={currentSection.pages.length}
          numberOfSections={numberOfSections}
          numberOfChapters={numberOfChapters}
          returnHome={returnHome}
        />
      </View>
    </ScrollView>
  )
};
