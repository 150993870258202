import { Dimensions, Platform, StyleSheet } from 'react-native';
import {ACTION_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_ACCENT} from '../../common/global.styles';

export default StyleSheet.create({
  container: {
    backgroundColor: '#303030',
  },
  contentContainer: {
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    flexWrap: 'wrap',
    marginHorizontal: 16,
    marginVertical: 32,
    marginTop: Platform.OS === 'web' ?  64 : 32,
    paddingBottom: 40
  },
  card: {
    backgroundColor: '#474646',
    flex: Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? 1 : .5,
    minWidth: Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? '35%' : '100%',
    marginBottom: 16,
  },
  cardItem: {
    backgroundColor: PRIMARY_COLOR
  },
  title: {
    color: 'rgba(0,0,0, .6)',
    paddingBottom: 8,
    textAlign: 'center',
    width: '100%',
    fontSize: 22
  },
  subtitle: {
    color: 'rgba(0,0,0, .6)',
    textAlign: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingVertical: 8,
  },
  image: {
    height: 200,
    // @ts-ignore
    width: null,
    flex: 1
  },
  footer: {
    backgroundColor: '#474646',
  },
  button: {
    flex: 1,
    backgroundColor: ACTION_COLOR,
  },
  fab: {
    justifyContent: 'center',
    backgroundColor: PRIMARY_COLOR_ACCENT
  },
  fabIcon: {
    fontSize: 30,
    paddingTop: Platform.OS === 'ios' ? 8 : 0
  }
});
