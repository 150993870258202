import React from 'react';
import { Platform, View } from 'react-native';
import { H1, Button } from 'native-base';

import { Spinner } from './spinner/spinner.component';

type Props = {
	onPress: () => void;
	text: string;
	loading?: boolean;
	disabled?: boolean;
	color?: string;
}

const SubmitButton: React.FunctionComponent<Props> = ({loading = false, disabled = false, onPress, text, color = '#ff4081'}: Props) => {
	if (loading) {
		return <Spinner size="small"/>
	} else {
		return (
            <View style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                marginLeft: 48,
                marginRight: 36,
                marginTop: 8,
                marginBottom: 16,
                backgroundColor: '#474646',
			}}>
				<Button full disabled={disabled}
						style={{
							backgroundColor: color,
							opacity: disabled ? .5 : 1,
							borderWidth: disabled ? 0 : 1,
							shadowColor: '#FFF',
							shadowOffset: {width: 1, height: 3},
							shadowOpacity: 0.25,
							shadowRadius: disabled ? 0 : 4,
							elevation: disabled ? 0 : 6,
							borderColor: '#FFF'
						}}
						onPress={onPress} >
					<View>
						<H1 style={[Platform.OS === 'web' && { paddingHorizontal: 8 }]}>
							{text.toUpperCase()}
						</H1>
					</View>
			</Button>
			</View>
		)
	}
};

export { SubmitButton };