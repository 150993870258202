import { Dimensions, Platform, StyleSheet } from 'react-native';


export const contentStyles = StyleSheet.create({
  questionText: {
    fontFamily: 'Roboto',
    fontSize: 24,
    lineHeight: 28,
    color: '#e2e2e2',
    textAlign: 'center'
  },
  text: {
    lineHeight: 28,
    fontFamily: 'Roboto',
    fontSize: 20,
    color: '#ffe57f',
    textAlign:'left',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 24,
    marginHorizontal: Dimensions.get('screen').width / 7,
    marginBottom: 48
  },
  submitContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24
  },
  headerStyle: Platform.select({
    ios: {
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      height: 70,
      marginRight: -32,
      paddingBottom: 8
    },
    android: {
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      height: 70,
      marginRight: 0,
      paddingBottom: 8
    }
  }),
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#474646'
  },
  fabIcon: Platform.select({
    ios: {
      fontSize: 50, paddingTop: 28
    },
    android: {
      fontSize: 34
    }
  }),
});

