import React, { useContext, useEffect, useState } from 'react';
import {
    LayoutAnimation, Platform, NativeModules, KeyboardAvoidingView, Dimensions
} from 'react-native';
import {
    Content, View, Text, List, ListItem,
    Fab, Body, Form, Button, Icon,
} from 'native-base';
import { InputModal } from '../../../../../../common/components/input-modal-component';
import { AnimatedInput } from '../../../../../../common/components/input.component';
import { ForumChapterQuestion, ForumTopic } from '../../../../../../models/course';
import { SubmitButton } from '../../../../../../common/components/submit-button';
import { ACTION_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../../../../common/global.styles';
import { ChatRoom } from '../chat-room/chat-room.component';
import { ForumList } from '../forum-list/forum-list.component';
const { UIManager } = NativeModules;

export type ForumTabProps = {
    fetchingForumAnswers: boolean,
    fetchingForumAnswersSuccess: boolean | null,
    loadForumData: () => void,
    forumTopics: Array<ForumTopic>,
    forumChQuestionTopics: Array<ForumChapterQuestion>,
    handleNewForumAnswer: (forumTopicId: string, answer: string) => any;
    fetchResponses: (forumTopicId: string, answerId: string) => void;
}

export type ForumState = {
    expandedSectionId: string,
    isLoading: boolean,
    forumOpened: boolean,
    openedForumPageId: string,
    openedForumTopicId: string,
    openChatRoom: boolean,
    openChatRoomId: string,
    response: string,
}

let previousFetchingForumAnswersProp = false;
const ForumTab: React.FunctionComponent<ForumTabProps> = ({ loadForumData, forumTopics, forumChQuestionTopics, handleNewForumAnswer,
                                                      fetchingForumAnswers, fetchingForumAnswersSuccess, fetchResponses }: ForumTabProps) => {
    if (Platform.OS === 'android') {
        UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    const [forumState, setForumState]: [ForumState, any] = useState({
        expandedSectionId: '',
        isLoading: false,
        forumOpened: false,
        openedForumPageId: '',
        openedForumTopicId: '',
        openChatRoom: false,
        openChatRoomId: '',
        response: ''
    });


    const [newAnswerState, setNewAnswerState]: [{ displayModal: boolean, answer: string }, any] = useState({
        displayModal: false,
        answer: ''
    });


    useEffect(() => {
        loadForumData();
    }, []);

    useEffect(()=> {
        if(previousFetchingForumAnswersProp && !fetchingForumAnswers) {
            setForumState({
                ...forumState,
                isLoading: false
            });
        }

        return () => {
            previousFetchingForumAnswersProp = fetchingForumAnswers
        }

    }, [fetchingForumAnswers, fetchingForumAnswersSuccess]);

    useEffect(() => {
        if(forumState.openChatRoom) {
            fetchResponses(forumState.openedForumTopicId, forumState.openChatRoomId);
        }
    }, [forumState.openChatRoom]);

    const backToForumList = () => {
        LayoutAnimation.linear();
        setForumState({
            ...forumState,
            forumOpened: false,
            openedForumPageId: '',
            openedForumTopicId: ''
        })
    };

    const renderNewAnswerModal = () => {
        return (
            <InputModal displayModal={newAnswerState.displayModal}>
                <Form>
                    <AnimatedInput
                        value = {newAnswerState.answer}
                        valid = {newAnswerState.answer.length > 100}
                        label="statement"
                        placeholder="Share thoughts on this topic..."
                        errMsg="The answer needs to be at least 100 characters"
                        textArea = {true}
                        numberOfLines={8}
                        onChange={(value) => setNewAnswerState({...newAnswerState, answer: value})}
                    />
                </Form>
                <View style={{marginTop: 16}}>
                    <SubmitButton loading={false}
                                  disabled={newAnswerState.answer.length < 100}
                                  onPress={() => {
                                      handleNewForumAnswer(forumState.openedForumTopicId, newAnswerState.answer);
                                      setNewAnswerState({answer: '', displayModal: false});
                                  }}
                                  text="submit"
                    />
                    <SubmitButton
                        text="cancel"
                        color={PRIMARY_COLOR_ACCENT}
                        onPress={() => setNewAnswerState({answer: '', displayModal: false})}/>
                </View>
            </InputModal>
        )
    };

    if(!forumState.forumOpened) {
        return (
            <ForumList
                chapterQuestions={forumChQuestionTopics}
                forumTopics={forumTopics}
                setForumState={setForumState}
                forumState={forumState}
                fetchingForumAnswersSuccess={fetchingForumAnswersSuccess}
            />
        );
    } else {
        let selection: ForumTopic | ForumChapterQuestion;
        let selectionFound = true;
        const selectedForumTopic: ForumTopic | undefined = forumTopics.find((topic) => topic.id === forumState.openedForumTopicId);
        const selectedForumChQuestion: ForumChapterQuestion | undefined= forumChQuestionTopics.find((topic) => topic.id === forumState.openedForumTopicId);

        if(selectedForumTopic) {
            selection = selectedForumTopic;
        } else if(selectedForumChQuestion) {
            selection = selectedForumChQuestion
        } else {
            selectionFound = false;
            selection = {
                id: '',
                topic: ''
            };
        }

        let answers;

        let chapterQuestionsForum = true;

        if(selectionFound && selection && selection.answers) {
            if(selection.id.includes('chapter_')) {
                answers = selection.answers.filter((answer) => {
                    return answer.pageId === forumState.openedForumPageId;
                });
            } else {
                chapterQuestionsForum = false;
                answers = selection.answers;
            }

        }

        if (answers) {
            return (
                <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={-70}
                                      enabled style={{flex: 1}} contentContainerStyle={{flex: 1, width: '100%', height: '100%' }}>
                    <View style={{flex: 1}}>
                        <Content style={[{paddingHorizontal: 8, marginLeft: 0, paddingTop: 8, paddingBottom: 32, backgroundColor: '#5f5f5f', }]}>
                            <List>
                                {answers.map((record, index) => {
                                    return (
                                        <ListItem noIndent key={index} style={{ backgroundColor: PRIMARY_COLOR, padding: 0, margin: 0, marginVertical: 8 }}>
                                            <Body>
                                                <Text style={{fontWeight: 'bold', marginBottom: 8, paddingHorizontal: 0, marginHorizontal: 0}}>{record.firstName} {record.lastName}</Text>
                                                <Text>"{record.answer}"</Text>
                                                { (!forumState.openChatRoom || record.id !== forumState.openChatRoomId) ?
                                                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', marginTop: 8}}>
                                                        <Button iconRight onPress={()=> {
                                                            LayoutAnimation.linear();
                                                            setForumState({ ...forumState, openChatRoom: true, openChatRoomId: record.id || ''})
                                                        }}>
                                                            <Text style={{fontSize: 14}}>Enter Chat Room</Text>
                                                            <Icon name="chatbubbles"/>
                                                        </Button>
                                                    </View> :
                                                    <ChatRoom
                                                        record={record}
                                                        isReplyThreadChat={false}
                                                        openedForumTopicId={forumState.openedForumTopicId}
                                                    />
                                                }
                                            </Body>
                                        </ListItem>
                                    )
                                })}
                            </List>

                        </Content>
                        <Fab
                            style={{backgroundColor: chapterQuestionsForum ? ACTION_COLOR : PRIMARY_COLOR_ACCENT}}
                            position={chapterQuestionsForum ? 'bottomRight' : 'bottomLeft'}
                            onPress={backToForumList}
                        >
                            <Icon name="arrow-back" style={{color: chapterQuestionsForum ? 'white' : 'black'}}/>
                        </Fab>
                        { !chapterQuestionsForum ?
                            <Fab
                                style={{backgroundColor: ACTION_COLOR}}
                                position={Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? "bottomLeft" : "bottomRight"}
                                onPress={() => setNewAnswerState({...newAnswerState, displayModal: true})}
                            >
                                <Icon name="add" style={Platform.OS === 'ios' ? {fontSize: 50, paddingTop: 28} :
                                    {fontSize: 34}}/>
                            </Fab> : null
                        }
                    </View>
                    {renderNewAnswerModal()}

                </KeyboardAvoidingView>
            )
        } else {
            return(
                <Content style={{padding: 16, paddingBottom: 32, backgroundColor: '#5f5f5f'}}>
                    <Text>No answers found</Text>
                </Content>
            )
        }
    }
};

export {
    ForumTab
}