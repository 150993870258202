import { Platform, StyleSheet } from 'react-native';
import {ACTION_COLOR} from '../../../../common/global.styles';

export default StyleSheet.create({
	button: {
		backgroundColor: ACTION_COLOR,
		opacity: 1,
		borderWidth: 1,
		borderColor: '#FFF',
		...Platform.select ({
			android: {
				elevation: 6,

			},
			ios: {
				shadowColor: '#FFF',
				shadowOffset: {width: .25, height: 2},
				shadowOpacity: 0.20,
				shadowRadius: 3,
			}
		}),
	},
	iconLeft: {
		paddingLeft: 16,
		paddingTop: 2,
	},
	iconRight: {
		paddingRight: 16,
		paddingTop: 2,
	},
	textLeft: {
		paddingLeft: 8,
		marginLeft: 0,
		fontSize: 15,
	},
	textRight: {
		paddingRight: 10,
		marginRight: 0,
		fontSize: 15,
	}
});