import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { spinnerStyles } from './spinner.styles';


type Props = {
    size: number | 'small' | 'large' | undefined;
    color?: string;
}

const Spinner: React.FunctionComponent<Props> = ({size, color}: Props) => {
    return(
        <View style={spinnerStyles.spinner} testID='Spinner'>
            <ActivityIndicator size={size || 'large'} color={ color || '#ffe57f'}/>
        </View>
    )
};

export { Spinner };