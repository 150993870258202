import { StyleSheet, Platform} from 'react-native';

const signUpStyles = StyleSheet.create({
  containerSignUp: {
    height: '100%',
    backgroundColor: '#303030',
    paddingTop: '5%'
  },
  errorTextStyle: {
    color: '#ff4081',
    fontSize: 13,
    marginTop: 5,
    paddingLeft: 8,
    alignSelf: 'center'
  },
  errorTextContainer: {
    paddingBottom: 4
  },
  contentStyle: {
    marginHorizontal: 16
  },
  cardStyle: {
    backgroundColor: '#474646',
    paddingRight: 16,
    marginBottom: 36
  },
  confirmContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginLeft: 5,
    marginBottom: 8,
    marginTop: 24,
    paddingBottom: 12,
    paddingLeft: 0
  },
  statusIconStyle: Platform.select({
    ios: {
      marginTop: 5,
      color: '#007aff'
    },
    android: {
      marginTop: 10,
      color: '#3F51B5'
    }
  }),
  checkboxContainer: {
    display: 'flex',
    flex: 1
  },
  confirmTextContainer: {
    display: 'flex',
    flex: 6
  },
  urlLink: {
    color: '#FF80AB',
    fontSize: 13
  }
});

export default signUpStyles;