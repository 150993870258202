import React, { Component } from 'react';
import { Dimensions, Platform, ScrollView, StatusBar, Text, TouchableOpacity, View } from 'react-native';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import { Button, Card, CardItem, CheckBox, Container, Content, Fab, Icon, Right, } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { fetchCourseForumApi, fetchNotifications, updateNotificationReceivedStatus } from '../../redux/actions';
import { Spinner } from '../../common/components/spinner/spinner.component';
import { Notification, NotificationType } from '../../models/notification';
import { DrawerNavigationProps } from '../../navigation/navigation';
import { CommonActions } from '@react-navigation/native';
import { isEqual } from 'lodash';
const { UserTopicAnswered, AnswerResponseReceived, PrivateChatStarted, PrivateChatResponseReceived } = NotificationType;

interface Props extends DrawerNavigationProps<'Notifications'> {
  readonly fetchNotifications: (fetchAll: boolean) => void;
  readonly fetchingNotifications: boolean;
  readonly fetchNotificationsSuccess: boolean | null;
  readonly updateNotificationReceivedStatus: (notificationIds: string[], status?: boolean) => void;
  readonly fetchCourseForumApi: (courseId: string) => void;
  readonly notifications: Notification[];
}

class NotificationsContainer extends Component <Props, { notificationsArchive: Map<string, boolean> }> {

  constructor(props: Props) {
    super(props);

    this.state = {
      notificationsArchive: new Map(),
    };

    this.handleNotificationSelection = this.handleNotificationSelection.bind(this);
    this.markNotificationsRead = this.markNotificationsRead.bind(this);
    this.renderNotifications = this.renderNotifications.bind(this);
  }

  public componentDidMount(): void {
    this.props.fetchNotifications(true);
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>): void {
    if ((prevProps.fetchingNotifications !== this.props.fetchingNotifications && this.props.fetchNotificationsSuccess) ||
      (!isEqual(this.props.notifications, prevProps.notifications))) {
      const notificationsArchive: Map<string, boolean> = new Map();
      this.props.notifications.forEach(notification => notificationsArchive.set(notification.id, false));
      this.setState({ notificationsArchive });
    }
  }

  private navigateToChat(route: any) {
    this.props.navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Forum',
            state: {
              routes: [
                { name: 'Forum' },
                route,
              ]
            }
          }
        ]
      })
    })
  }

  private handleNotificationSelection(notification: Notification): void {
    switch(notification.type){
      case UserTopicAnswered:
        this.props.fetchCourseForumApi(notification?.courseId);
        this.navigateToChat(
          { name: 'ChatRoom', params: {
              courseId: notification.courseId,
              topicId: notification.topicId,
              answerId: notification.answerId,
              isChapterQuestion: false,
            }
          },
        );
        break;
      case AnswerResponseReceived:
        this.props.fetchCourseForumApi(notification?.courseId);
        this.navigateToChat(
          { name: 'ChatRoom', params: {
              courseId: notification.courseId,
              topicId: notification.topicId,
              answerId: notification.answerId,
              isChapterQuestion: notification.topicId.includes('chapter'),
              questionPageId: notification?.questionPageId ?? '',
            }
          },
        );
        break;
      case PrivateChatStarted:
      case PrivateChatResponseReceived:
        this.navigateToChat(
          { name: 'PrivateChatRoom', params: {
              new: false,
              chatRoomId: notification.chatRoomId || notification.chatId,
            }
          },
        );
        break;
      default:
        return;
    }
  }

  private markNotificationsRead() {
    const notificationIds: string[] = [];
    this.state.notificationsArchive.forEach((value, key) => {
      if (value) {
        notificationIds.push(key);
      }
    })

    if (notificationIds?.length) {
      this.props.updateNotificationReceivedStatus(notificationIds)
    }
  }

  private renderNotifications(): JSX.Element | JSX.Element[] {

    const renderNotification = (notification: Notification): JSX.Element => {
      return (
        <CardItem
          key={notification.id}
          style={{ backgroundColor: '#ffe57f', paddingBottom: 16, paddingLeft: 16 }}
        >
          <TouchableOpacity
            style={{ flex: .7, flexDirection: 'column' }}
            onPress={this.handleNotificationSelection.bind(this, notification)}
          >
            <Text style={{fontWeight: 'bold', fontSize: 12, paddingBottom: 4}}>{notification.timestamp.toLocaleDateString()}</Text>
            <Text style={{fontSize: 13}}>{notification.notification}</Text>
          </TouchableOpacity>
          <Right style={{ flex: .25, marginRight: 8  }}>
            <CheckBox
              checked={this.state.notificationsArchive.get(notification.id)}
              color="#FFB300"
              onPress={() => {
                const notificationsArchive = this.state.notificationsArchive;
                const currentSetting = notificationsArchive.get(notification.id);
                notificationsArchive.set(notification.id, !currentSetting);
                this.setState({ notificationsArchive });
              }}
            />
          </Right>
        </CardItem>
      );
    };

    if (!this.props.notifications.length) {
      return (
        <CardItem style={{ backgroundColor: '#ffe57f', paddingBottom: 16, paddingLeft: 32 }}>
          <Text>You currently have no unread notifications.</Text>
        </CardItem>
      );
    }

    return this.props.notifications.map(renderNotification);
  }

  public render = (): React.ReactNode =>  {
    if(this.props.fetchingNotifications) {
      return <Spinner size="large" />
    }
    return (
      <Container style={{backgroundColor: '#474646'}}>
        <StatusBar barStyle="light-content" animated/>
        <Content
          style={[{margin: 0, paddingVertical: 32, paddingHorizontal: 16, backgroundColor: '#474646'}]}
          contentContainerStyle={{ flex: 1, alignItems: 'center' }}
        >
          <Card style={[{ backgroundColor: '#ffe57f', marginBottom: 32}, Platform.OS === 'web' && { maxHeight: '90%', width: '50%', minWidth: 350, marginTop: '5%' }]}>
            <ScrollView>
            <CardItem
              header
              bordered
              style={{ backgroundColor: PRIMARY_COLOR_ACCENT, borderColor: '#474646', justifyContent: 'space-between'}}
            >
              <View style={{ flexDirection: 'column' }}>
                <Text style={{fontWeight: 'bold'}}>Notifications</Text>
                <Text style={{ fontSize: 10, fontWeight: 'normal', paddingTop: 4 }}>(Press to Open)</Text>
              </View>
              <Button
                bordered
                style={{ backgroundColor: ACTION_COLOR, paddingHorizontal: 8, borderColor: 'white'}}
                onPress={this.markNotificationsRead}
              >
                <Text>Mark Read</Text>
              </Button>
            </CardItem>
            {this.renderNotifications()}
            </ScrollView>
          </Card>
          <Fab
            style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center'  }}
            onPress={this.props.navigation.toggleDrawer}
            position={Platform.OS === 'web' && Dimensions.get('screen').width > 500  ? "topLeft" : "bottomRight"}
          >
            <Icon
              name="menu"
              style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0  }}
            />
          </Fab>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: Props) => {
  return {
    notifications: state.userData.notifications,
    fetchingNotifications: state.userData.fetchingNotifications,
    fetchNotificationsSuccess: state.userData.fetchNotificationsSuccess,
  }
};

export default connect(mapStateToProps, { fetchNotifications, updateNotificationReceivedStatus, fetchCourseForumApi })(NotificationsContainer);
