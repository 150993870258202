import { Card, Container, Content, Form } from "native-base";
import React, { PropsWithChildren } from 'react';
import { Dimensions, Modal, Platform, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface Props extends PropsWithChildren<any> {
    displayModal: boolean;
}

export const InputModal: React.FunctionComponent<Props> = ({ displayModal, children }: Props) => {
    return(
        <Modal
            animationType="slide"
            transparent={false}
            visible={displayModal}
            onRequestClose={() => {}}
        >
            <Container style={styles.container}>
                <KeyboardAwareScrollView
                    enableOnAndroid={true}
                    enableAutomaticScroll={true}
                    extraScrollHeight={Platform.OS === 'ios' ? 25 : 50}
                    extraHeight={Platform.OS === 'ios' ? 25 : 50}
                    keyboardShouldPersistTaps="handled"
                    keyboardOpeningTime={1000}>
                    <Content style={[styles.content && Platform.OS === 'web' && { width: '50%', minWidth: 350 }]} disableKBDismissScroll={true}>
                        <Card style={styles.card}>
                            {children}
                        </Card>
                    </Content>
                </KeyboardAwareScrollView>
            </Container>
        </Modal>
    )
};

const styles = StyleSheet.create({
    container: {
        height: '100%',
        backgroundColor: '#303030',
        paddingTop: Dimensions.get('screen')?.height > 800 ? '15%' : '30%',
        alignItems: 'center',
    },
    content: {
        marginHorizontal: 16
    },
    card: {
        backgroundColor: '#474646',
        paddingRight: 16,
        marginBottom: 16
    },
});