import { Dispatch } from 'redux';
import { BaseForumAnswer, Chapter, Course, ForumAnswers, ForumChapterQuestion, ForumResponse, ForumResponseData, ForumTopic, Section } from '../../models/course';
import { createAsyncAction, createAction } from 'typesafe-actions';
import _ from 'lodash';
import { CoursesReducerState } from '../reducers/courses.reducer';
import { SignUpReducerState } from '../reducers/sign-up.reducer';
import { UserReducerState } from '../reducers/user.reducer';
import { recacheUserAnswers } from './user.actions';
import { AnswerObject } from '../../models/user';
import * as Sentry from 'sentry-expo';
import { collection, getDocs, getFirestore, query, where, addDoc, Timestamp, doc, updateDoc, deleteDoc, writeBatch, orderBy } from 'firebase/firestore';
import { firebaseApp } from '../../../App';
import { getAuth } from 'firebase/auth';
import { Platform } from 'react-native';

export const fetchCoursesApi = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchCoursesAsync.request());
    const db = getFirestore(firebaseApp);
    const coursesDBQuery = query(collection(db, 'courses'));

    getDocs(coursesDBQuery)
      .then(snapShot => {
        let courseList: Array<any> = [];

        snapShot.forEach(course => {
          courseList.push({
            ...course.data(),
            id: course.id
          })
        });

        dispatch(fetchCoursesAsync.success(courseList));
        dispatch(recacheUserAnswers());
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchCoursesAsync.failure())
      });
  }
};

const fetchCoursesAsync = createAsyncAction(
  'INIT_FETCH_COURSE_LIST',
  'FETCH_COURSE_LIST_SUCCESS',
  'FETCH_COURSE_LIST_FAILURE'
)<void, Array<Course>, void>();

export const fetchChaptersApi = (courseId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchChaptersAsync.request());
    const db = getFirestore(firebaseApp);
    const chaptersDBQuery = query(collection(db, 'courses', courseId, 'chapters'));

    getDocs(chaptersDBQuery)
      .then(snapShot => {
        let chapters: Array<any> = [];
        snapShot.forEach(chapter => {
          chapters.push({
            ...chapter.data(),
            id: chapter.id,
            sectionsData: chapter.data().sections?.map((section: Section) => {
              return { ...section };
            })
          });
        });

        chapters.forEach(chapter => chapter.id = parseInt(chapter.id, 10));
        chapters = _.orderBy(chapters, ['id', 'asc']);

        dispatch(fetchChaptersAsync.success({chapters, courseId}));
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchChaptersAsync.failure())
      });
  }
};

const fetchChaptersAsync = createAsyncAction(
  'INIT_FETCH_CHAPTERS',
  'FETCH_CHAPTERS_SUCCESS',
  'FETCH_CHAPTERS_FAILURE'
)<void, {chapters: Array<Chapter>, courseId: string }, void>();

export const fetchPagesApi = (courseId: string, chapterId: number, sectionIndex: number, sectionUid: string) => {
  return (dispatch: Dispatch, getState: any) => {
    dispatch(fetchPagesAsync.request());
    const db = getFirestore(firebaseApp);
    let chapterPagesQuery;

    if (sectionUid) {
      chapterPagesQuery = query(collection(db, 'courses', courseId, 'chapters', `${chapterId}`, 'pages'), where('sectionId', '==', sectionUid));
    } else {
      chapterPagesQuery = query(collection(db, 'courses', courseId, 'chapters', `${chapterId}`, 'pages'), where('sectionIndex', '==', sectionIndex));
    }

    getDocs(chapterPagesQuery)
      .then(snapShot => {
        let pages: Array<any> = [];
        const { userData } = getState();
        snapShot.forEach(page => {
          if(userData.answers.length > 0) {
            const answerFound = userData.answers.find((answer: AnswerObject) => answer.pageId === page.id);
            if (answerFound) {
              pages.push({
                ...page.data(),
                id: page.id,
                shareAnswer: answerFound.share,
                userAnswer: answerFound.answer,
                answerId: answerFound.answerId,
              });
            } else {
              pages.push({
                ...page.data(),
                id: page.id
              });
            }
          } else {
            pages.push({
              ...page.data(),
              id: page.id
            });
          }

        });
        dispatch(fetchPagesAsync.success({pages, courseId, chapterId, sectionIndex }));
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchPagesAsync.failure())
      });
  }
};

const fetchPagesAsync = createAsyncAction(
  'INIT_FETCH_CHAPTER_PAGES',
  'FETCH_CHAPTER_PAGES_SUCCESS',
  'FETCH_CHAPTER_PAGES_FAILURE'
)<void, {pages: Array<Chapter>, courseId: string, chapterId: number, sectionIndex: number }, void>();

const setCurrentCourse = createAction('SET_CURRENT_COURSE')<string>();
const setCurrentChapter = createAction('SET_CURRENT_CHAPTER')<number>();
const setCurrentSection = createAction('SET_CURRENT_SECTION')<number>();
const setCurrentPageIndex = createAction('SET_CURRENT_PAGE_INDEX')<number>();

export const fetchCourseForumApi = (courseId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchCourseForumAsync.request());
    // selector?
    const db = getFirestore(firebaseApp);
    const chaptersForumQuery = query(collection(db, 'courses', courseId, 'forum'));
    getDocs(chaptersForumQuery)
      .then(snapShot => {
        const forumTopics: Array<ForumTopic> = [];
        const forumChQuestionTopics: Array<ForumChapterQuestion> = [];
        if(snapShot.docs.length > 0){
          snapShot.forEach(forumTopic => {
            const {questions, topic} = forumTopic.data();
            if(questions) {
              forumChQuestionTopics.push({
                questions,
                topic,
                id: forumTopic.id
              });
            } else {
              forumTopics.push({
                topic,
                id: forumTopic.id
              })
            }

          });
        }
        dispatch(fetchCourseForumAsync.success({forumTopics, forumChQuestionTopics, courseId}));
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchCourseForumAsync.failure())
      });
  }
};

const fetchCourseForumAsync = createAsyncAction(
  'INIT_FETCH_CHAPTER_FORUM',
  'FETCH_CHAPTER_FORUM_SUCCESS',
  'FETCH_CHAPTER_FORUM_FAILURE'
)<void, {forumTopics: Array<ForumTopic>, forumChQuestionTopics: Array<ForumChapterQuestion>, courseId: string }, void>();

export const fetchForumAnswersApi = (courseID: string, forumTopicId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchForumAnswersAsync.request());
    // selector?
    const db = getFirestore(firebaseApp);
    const forumAnswersQuery = query(collection(db, 'courses', courseID, 'forum', forumTopicId, 'answers'), orderBy('timeStamp', 'desc'));

    getDocs(forumAnswersQuery)
      .then(snapShot => {
        const forumAnswers: Array<ForumAnswers> = [];
        if (snapShot.docs.length > 0){
          snapShot.forEach(forumAnswer => {
            const { answer, chapterId, courseId, firstName, lastName, pageId, userId, timeStamp, updateTimeStamp, profilePhotoUrl = '',  responseCount = 0 } = forumAnswer.data();
            forumAnswers.push({
              answer, chapterId, courseId, firstName, profilePhotoUrl,
              updateTimeStamp: updateTimeStamp?.toDate()?.toLocaleDateString() ?? '',
              lastName, pageId, userId, responseCount,
              id: forumAnswer.id, timeStamp: timeStamp.toDate().toLocaleDateString()
            })
          });
        }

        dispatch(fetchForumAnswersAsync.success({ forumAnswers, courseId: courseID, forumTopicId }));
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchForumAnswersAsync.failure())
      });
  }
};

const fetchForumAnswersAsync= createAsyncAction(
  'INIT_FETCH_FORUM_ANSWERS',
  'FETCH_FORUM_ANSWERS_SUCCESS',
  'FETCH_FORUM_ANSWERS_FAILURE'
)<void, {forumAnswers: Array<ForumAnswers>, courseId: string, forumTopicId: string }, void>();

const fetchResponsesApi = (forumTopicId: string, answerId: string, courseId?: string) => {
  return (dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ) => {
    dispatch(fetchResponsesAsync.request());

    const { coursesData } = getState();
    const db = getFirestore(firebaseApp);
    const forumResponsesQuery = query(collection(db, 'courses', courseId || coursesData.currentCourseId, 'forum', forumTopicId, 'answers', answerId, 'responses'));

    getDocs(forumResponsesQuery)
      .then(snapShot => {
        const responses: Array<ForumResponse> = [];
        if(snapShot.docs.length > 0){
          snapShot.forEach(response => {
            responses.push({
              ...response.data() as ForumResponse,
              id: response.id,
              timeStamp: response.data().timeStamp.toDate(),
            })
          });
        }

        dispatch(fetchResponsesAsync.success({responses, courseId: courseId || coursesData.currentCourseId, forumTopicId, answerId}));
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchResponsesAsync.failure())
      });
  }
};

const fetchResponsesAsync= createAsyncAction(
  'INIT_FETCH_FORUM_RESPONSES',
  'FETCH_FORUM_RESPONSES_SUCCESS',
  'FETCH_FORUM_RESPONSES_FAILURE'
)<void, {responses: Array<ForumResponse>, courseId: string, forumTopicId: string, answerId: string}, void>();

const submitForumResponse = ({ forumTopicId, response, answerId, responseId, topic, answer, courseId = '' } : ForumResponseData): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(submitForumResponseAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    try {
      const { coursesData } = getState();
      const db = getFirestore(firebaseApp);

      const { userData } = getState();
      const data: ForumResponse = {
        response: response || '',
        userId,
        profilePhotoUrl: userData.profilePhotoUrl,
        firstName: userData.firstName,
        lastName: userData.lastName,
        timeStamp: Timestamp.now(),
        answerId
      };

      if (responseId) {
        data.responseId = responseId;
      }
      const dataResponse = await addDoc(collection(db, 'courses', courseId || coursesData.currentCourseId, 'forum', forumTopicId, 'answers', answerId, 'responses'), data);

      data.id = dataResponse.id;
      dispatch(submitForumResponseAsync.success({ data, courseId: courseId || coursesData.currentCourseId, forumTopicId, answerId, response, topic, answer }));
    } catch(error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(submitForumResponseAsync.failure())
    }
  };
};

const submitForumResponseAsync = createAsyncAction(
  'INIT_SUBMIT_FORUM_RESPONSE',
  'SUBMIT_FORUM_RESPONSE_SUCCESSFUL',
  'SUBMIT_FORUM_RESPONSE_FAILED'
)<void, {data: ForumResponse, courseId: string, forumTopicId: string, answerId: string, response: string, topic: string, answer: string }, void>();

// Edit Forum Response
const editForumResponse = ({ forumTopicId, response, responseId, answerId} : ForumResponseData): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(editForumResponseAsync.request());
    const { coursesData } = getState();
    const db = getFirestore(firebaseApp);
    const responseDbRef = doc(db, 'courses', coursesData.currentCourseId, 'forum', forumTopicId, 'answers', answerId, 'responses', responseId);

    await updateDoc(responseDbRef, {
      response,
      updateTimeStamp: Timestamp.now(),
    })
      .catch((error)=> {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(editForumResponseAsync.failure())
      });

    dispatch(editForumResponseAsync.success({response: response || '', responseId, courseId: coursesData.currentCourseId, forumTopicId, answerId }));
  };
};

const editForumResponseAsync = createAsyncAction(
  'INIT_EDIT_FORUM_RESPONSE',
  'EDIT_FORUM_RESPONSE_SUCCESSFUL',
  'EDIT_FORUM_RESPONSE_FAILED'
)<void, {response: string, responseId: string, courseId: string, forumTopicId: string, answerId: string}, void>();

// Delete Forum Response
const deleteForumResponse = ({ forumTopicId, responseId, answerId} : {forumTopicId: string, responseId: string, answerId: string }): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(deleteForumResponseAsync.request());
    const { coursesData } = getState();
    const db = getFirestore(firebaseApp);
    const responseDbRef = doc(db, 'courses', coursesData.currentCourseId, 'forum', forumTopicId, 'answers', answerId, 'responses', responseId);

    await deleteDoc(responseDbRef)
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(deleteForumResponseAsync.failure())
      });

    dispatch(deleteForumResponseAsync.success({responseId, courseId: coursesData.currentCourseId, forumTopicId, answerId }));
  };
};

const deleteForumResponseAsync = createAsyncAction(
  'INIT_DELETE_FORUM_RESPONSE',
  'DELETE_FORUM_RESPONSE_SUCCESSFUL',
  'DELETE_FORUM_RESPONSE_FAILED'
)<void, { responseId: string, courseId: string, forumTopicId: string, answerId: string }, void>();

const initializeNewForumTopic = ({ topic, answer} : {topic: string, answer: string }): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(initForumTopicAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const { coursesData } = getState();
    const db = getFirestore(firebaseApp);
    const batch = writeBatch(db);
    const { userData } = getState();
    try {
      const forumDBRef = doc(collection(db, 'courses', coursesData.currentCourseId, 'forum'));
      const timeStamp = Timestamp.now();

      batch.set(forumDBRef, {
        topic,
        creator: `${userData.firstName} ${userData.lastName}`,
        creatorId: userId,
        timeStamp,
        answerCount: 0,
      });
      const data: BaseForumAnswer = {
        answer,
        userId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        timeStamp,
      };
      const answerDBRef = doc(collection(db, 'courses', coursesData.currentCourseId, 'forum', forumDBRef.id, 'answers'));

      batch.set(answerDBRef, data);

      await batch.commit();

      dispatch(initForumTopicAsync.success({
        courseId: coursesData.currentCourseId, topic,
        forumTopicId: forumDBRef.id, answer, answerId: answerDBRef.id, firstName: data.firstName,
        lastName: data.lastName, timeStamp: data.timeStamp, userId
      }));
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(initForumTopicAsync.failure());
    }
  };
};

const initForumTopicAsync = createAsyncAction(
  'INIT_FORUM_TOPIC',
  'INIT_FORUM_TOPIC_SUCCESSFUL',
  'INIT_FORUM_TOPIC_FAILED'
)<void, { courseId: string, topic: string, forumTopicId: string, answer: string,
  answerId: string, lastName: string, firstName: string, timeStamp: firebase.firestore.Timestamp, userId: string }, void>();

const submitForumAnswer = ({answer, forumTopicId, topic, courseId = '', chapterId = '', pageId = '' } : {answer: string, forumTopicId: string, topic: string }): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(submitForumAnswerAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const db = getFirestore(firebaseApp);
    const { coursesData } = getState();
    const { userData } = getState();

    const data: BaseForumAnswer = {
      answer,
      userId,
      firstName: userData.firstName,
      lastName: userData.lastName,
      timeStamp: Timestamp.now(),
      responseCount: 0,
    };
    if (pageId) {
      data.pageId = pageId;
      data.chapterId = chapterId;
      data.courseId = courseId;
    }
    try {
      const response = await addDoc(collection(db, 'courses', coursesData.currentCourseId, 'forum', forumTopicId, 'answers'), data);
      dispatch(submitForumAnswerAsync.success({courseId: coursesData.currentCourseId,
        forumTopicId, answer, answerId: response.id, firstName: data.firstName, topic,
        lastName: data.lastName, timeStamp: data.timeStamp, userId
      }));
    } catch (error ) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(deleteForumResponseAsync.failure())
    }
  };
};

const submitForumAnswerAsync = createAsyncAction(
  'SUBMIT_FORUM_ANSWER',
  'SUBMIT_FORUM_ANSWER_SUCCESSFUL',
  'SUBMIT_FORUM_ANSWER_FAILED'
)<void, { courseId: string, forumTopicId: string, answer: string, topic: string,
  answerId: string, lastName: string, firstName: string, timeStamp: firebase.firestore.Timestamp, userId: string }, void>();

export {
  fetchCoursesAsync,
  fetchChaptersAsync,
  fetchPagesAsync,
  setCurrentCourse,
  setCurrentChapter,
  setCurrentSection,
  setCurrentPageIndex,
  fetchCourseForumAsync,
  fetchForumAnswersAsync,
  submitForumResponse,
  submitForumResponseAsync,
  fetchResponsesApi,
  fetchResponsesAsync,
  editForumResponse,
  editForumResponseAsync,
  deleteForumResponse,
  deleteForumResponseAsync,
  initForumTopicAsync,
  initializeNewForumTopic,
  submitForumAnswer,
  submitForumAnswerAsync,
}
