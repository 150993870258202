import * as Updates from 'expo-updates';
import { Timestamp } from 'firebase/firestore';
import React, { Component, MutableRefObject } from 'react';
import {
  AppState, AppStateStatus, Dimensions, Image, Platform, ScrollView, StatusBar, Text, View, Alert, Linking, SafeAreaView
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RootState } from '../../redux/redux.typescript';
import { getCourseById } from '../../redux/selectors';
import { connect } from 'react-redux';
import { Course } from '../../models/course';
import { Content, Container, Card, CardItem, Icon, Right, Fab, H3, H1, Button, } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { addPushToken, fetchCourseForumApi, fetchNotifications, resetInitialNotification } from '../../redux/actions';
import { Spinner } from '../../common/components/spinner/spinner.component';
import { ExpoNotification, Notification, NotificationType } from '../../models/notification';
import * as WebBrowser from 'expo-web-browser';
import { DrawerNavigationProps } from '../../navigation/navigation';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicon from 'react-native-vector-icons/Ionicons';
import { CommonActions } from '@react-navigation/native';
import { registerForPushNotificationsAsync } from '../../common/services/notification.service';
import * as Notifications from 'expo-notifications';
import { NotificationResponse } from 'expo-notifications';
import * as TaskManager from 'expo-task-manager';

const { UserTopicAnswered, AnswerResponseReceived, PrivateChatStarted, PrivateChatResponseReceived, NewUserCreated } = NotificationType;

const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';

Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });

interface State {
  readonly pushToken: string;
  notificationData: ExpoNotification
}

interface Props extends DrawerNavigationProps<'Home'> {
  readonly course: Course;
  readonly currentChapterId: number;
  readonly currentSectionId: number;
  readonly currentPageIndex: number;
  readonly currentCourseId: string;
  readonly firstName: string;
  readonly currentExpoToken: string;
  readonly fetchNotifications: (fetchAllUnread: boolean, timestamp: string) => void;
  readonly addPushToken: (token: string) => void;
  readonly resetInitialNotification: () => void;
  readonly fetchingNotifications: boolean;
  readonly fetchNotificationsSuccess: boolean | null;
  readonly notifications: Notification[];
  readonly initialNotification: Notification;
  readonly fetchCourseForumApi: (courseId: string) => void;
}

class HomeContainer extends Component <Props, State> {
  private notificationListener  = React.createRef() as MutableRefObject<any>;
  private responseListener  = React.createRef() as MutableRefObject<any>;
  private activeAppStateSubscription;

  constructor(props: Props) {
    super(props);
    this.state = {
      pushToken: '',
      notificationData: null
    };

    this.activeAppStateSubscription = false;
    this.renderContinueWhereLeftOffBtn = this.renderContinueWhereLeftOffBtn.bind(this);
    this.handleNotificationSelection = this.handleNotificationSelection.bind(this);
    this.renderNotifications = this.renderNotifications.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const { initialNotification } = this.props;
    if (initialNotification) {
      this.handleNotificationSelection(initialNotification);
      this.props.resetInitialNotification();
    }

    const lastNotificationFetch = await AsyncStorage.getItem('@TheologicalAcademy:NotificationFetchTimestamp');
    let timestamp = null;
    if (lastNotificationFetch) {
      const parsed = JSON.parse(lastNotificationFetch);
      timestamp = Timestamp.fromDate(new Date(parsed));
    }

    this.props.fetchNotifications(false, timestamp);

    if (!this.activeAppStateSubscription && Platform.OS !== 'web') {
      this.activeAppStateSubscription = true;
      AppState.addEventListener('change', this.handleAppStateChange);
    }
    await this.registerForPushNotifications();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (this.props.currentExpoToken && !prevProps.currentExpoToken && !this.notificationListener.current) {
      this.initNotificationListener();
    }
  }

  private async registerForPushNotifications() {
    const { currentExpoToken } = this.props;
    if (!currentExpoToken) {
      const token = await registerForPushNotificationsAsync();
      if (token) {
        this.initNotificationListener();
        this.props.addPushToken(token);
        this.setState({ pushToken: token });
      }
    }
  }

  private initNotificationListener() {
    if (Platform.OS !== 'ios') {
      TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data, error, executionInfo }) => {});
      void Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);
    }

    this.notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      const notificationData  = notification.request.content.data as unknown;
      this.setState({ notificationData: notificationData as ExpoNotification });
    });

    this.responseListener.current = Notifications.addNotificationResponseReceivedListener((response: NotificationResponse) => {
      const notificationData = response.notification.request.content.data as unknown;
      this.handleNotificationSelection(notificationData as ExpoNotification);
    });
  }

  private handleAppStateChange = async (nextAppState: AppStateStatus): Promise<void> => {
    if (nextAppState === 'active' && !__DEV__) {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }
    }
  }

  private navigateToChat(route: any) {
    this.props.navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Forum',
            state: {
              routes: [
                { name: 'Forum' },
                route,
              ]
            }
          }
        ]
      })
    })
  }

  private handleNotificationSelection(notification: Notification | ExpoNotification): void {
    switch(notification.type as NotificationType){
      case UserTopicAnswered:
        this.props.fetchCourseForumApi(notification?.courseId);
        this.navigateToChat(
          { name: 'ChatRoom', params: {
              courseId: notification.courseId,
              topicId: notification.topicId,
              answerId: notification.answerId,
              isChapterQuestion: false,
            }
          },
        );
        break;

      case NewUserCreated:
        break;
      case AnswerResponseReceived:
        this.props.fetchCourseForumApi(notification?.courseId);
        this.navigateToChat(
          { name: 'ChatRoom', params: {
              courseId: notification.courseId,
              topicId: notification.topicId,
              answerId: notification.answerId,
              isChapterQuestion: notification.topicId.includes('chapter'),
              questionPageId: notification?.questionPageId ?? '',
            }
          },
        );
        break;
      case PrivateChatStarted:
      case PrivateChatResponseReceived:
        this.navigateToChat(
          { name: 'PrivateChatRoom', params: {
              new: false,
              chatRoomId: notification.chatRoomId || notification.chatId,
            }
          },
        );
        break;
      default:
        return;
    }
  }

  private async openAmazonLink(): Promise<void> {
    await WebBrowser.openBrowserAsync('https://www.amazon.com/J-K-Ward/e/B01EKL2HJ6?ref=sr_ntt_srch_lnk_1&qid=1580009705&sr=8-1');
  }

  private renderNotifications(): JSX.Element | JSX.Element[] {

    const renderNotification = (notification: Notification): JSX.Element => {
      return (
        <CardItem
          key={notification.id}
          button
          style={{ backgroundColor: '#ffe57f', paddingBottom: 16, paddingLeft: 32 }}
          onPress={this.handleNotificationSelection.bind(this, notification)}
        >
          <View style={{ flex: .9, flexDirection: 'column' }}>
            <Text style={{fontWeight: 'bold', fontSize: 12, paddingBottom: 4}}>{notification.timestamp.toLocaleDateString()}</Text>
            <Text style={{fontSize: 13}}>{notification.notification}</Text>
          </View>
          <Right style={{ flex: .1 }}>
            <Icon name="arrow-forward" style={{color: ACTION_COLOR}} />
          </Right>
        </CardItem>
      );
    };

    if(!this.props.notifications.length) {
      return (
        <CardItem style={{ backgroundColor: '#ffe57f', paddingBottom: 16, paddingLeft: 32 }}>
          <Text>You currently do not have any new notifications.</Text>
        </CardItem>
      );
    }

    return this.props.notifications.map(renderNotification);
  }

  private renderContinueWhereLeftOffBtn(): JSX.Element {
    const { currentPageIndex, currentChapterId, currentCourseId, course, currentSectionId } = this.props;

    const displayReturnToPageBtn = currentPageIndex >= 0 && currentChapterId >= 0 && currentCourseId && course && currentSectionId >= 0;

    if (!displayReturnToPageBtn) {
      return null;
    }

    return (
      <Card>
        <CardItem
          button
          style={{ backgroundColor: '#ffe57f',}}
          onPress={() => {
            this.props.navigation.dispatch({
              ...CommonActions.reset({
                index: 0,
                routes: [
                  {
                    name: 'Academy',
                    state: {
                      routes: [
                        { name: 'Journey', params: { course } },
                        { name: 'Content', params: { course, returnToPgAction: true }
                        }
                      ]
                    }
                  }
                ]
              })
            })
          }}
        >
          <Text style={{fontWeight: 'bold', paddingRight: 8 }}>Continue Where you Left Off</Text>
          <Right>
            <Icon name="arrow-forward" style={{color: ACTION_COLOR}} />
          </Right>
        </CardItem>
      </Card>
    )
  }

  private displayGameInformationalAlert() {
    Alert.alert('Gamified Theology', 'Our team has spent the last few years building an education-first and community-first mobile game engine. Think "parable maker" for the 21st century.\n\nOur first prototype is experimenting with translating the Course on Salvation found in this app into a 2D mobile RPG game.\n\nDo you want to be part of our journey to spread the light of God\'s truth in the often too dark world of video games? We are seeking out beta testers.\n\nIf so, then you can join our discord community and find out how you can join our fellowship of truth-seeking gamers.', [
      {
        text: 'Not Now',
        style: 'destructive',
      },
      {
        onPress: () => {
          void Linking.openURL('https://discord.gg/XPwrCjCFU3');
        },
        text: 'Join Discord',
        style: 'default'
      },
    ])
  }

  private joinDiscordServer() {
    void Linking.openURL('https://discord.gg/kjtFSMbD6S');
  }

  public render = (): React.ReactNode =>  {
    const { firstName } = this.props;

    if (this.props.fetchingNotifications) {
      return <Spinner size="large" />
    }

    return (
      <Container style={{backgroundColor: '#474646'}} >
        <StatusBar barStyle="light-content" animated/>
        <ScrollView
          style={{margin: 0, padding: 16, backgroundColor: '#474646'}}
          contentContainerStyle={{ flex: 1 }}
        >
          <SafeAreaView style={{ flex: .40, justifyContent: 'space-around', marginVertical: 12 }} >
            <View style={{flex: 1}}>
             <H1 style={{ color: '#ffe57f', textAlign: 'center', }}>Welcome back</H1>
             <H1 style={{ color: '#ffe57f', textAlign: 'center', marginTop: 8, fontWeight: 'bold' }}>{firstName}</H1>
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 8}}>
                <Image source={require('../../../assets/logo.png')} style={{ width: Dimensions.get('screen').height * .15, height: Dimensions.get('screen').height * .15  }}/>
              </View>
            </View>
           <H3 style={{ color: '#ffe57f', textAlign: 'center'}}>Ready to learn more about God?</H3>
          </SafeAreaView>
          <View style={{ flex: .45, alignItems: 'center' }} >
            <Card style={[ Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
              <ScrollView style={{ maxHeight: 172 }}>
                <CardItem header bordered  style={{ backgroundColor: '#ffe57f',  borderColor: PRIMARY_COLOR_ACCENT}}>
                  <Text style={{fontWeight: 'bold'}}>New Notifications</Text>
                </CardItem>
                {this.renderNotifications()}
              </ScrollView>
            </Card>
            {this.renderContinueWhereLeftOffBtn()}
            <View
              style={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}
            >
              <Text style={{ color: '#ffe57f', fontWeight: 'bold', fontSize: 20,  }}>Announcement: </Text>
              <Text style={{ color: '#ffe57f', fontSize: 18, marginTop: 8 }}>Want to Try Theology in Game Form?</Text>
              <View>
                <Button
                  transparent
                  hasText
                  onPress={this.displayGameInformationalAlert}
                >
                  <Text style={{ color: '#ffe57f', fontSize: 18, color: '#6087dc', textAlign: 'center' }}>Press to Learn about Eternity's Call</Text>
                </Button>
              </View>
            </View>
          </View>
          <View style={{ flex: .1, marginLeft: 8, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Button
              transparent
              hasText
              iconLeft
              onPress={this.joinDiscordServer}
            >
              <MaterialIcon
                name="discord"
                style={{ color: 'white', fontSize: 18, paddingRight: 8 }}
              />
              <Text style={{ color: '#ffe57f', fontStyle: 'italic', fontSize: 13, color: '#6087dc', }}>
                Join the Discord Server
              </Text>
            </Button>
            <Button
              transparent
              hasText
              iconLeft
              onPress={this.openAmazonLink}
            >
              <Ionicon
                name="logo-amazon"
                style={{ color: 'white', fontSize: 18, paddingRight: 8 }}
              />
              <Text style={{ color: '#ffe57f', fontStyle: 'italic', fontSize: 13, color: '#6087dc', }}>
                Visit Author's Amazon Page
              </Text>
            </Button>
          </View>
          {Platform.OS === 'web' && (
            <View style={{ marginTop: 32 }}>
              <Button
                style={{ paddingHorizontal: 8, minWidth: 125 }}
                hasText
                transparent
                iconRight
                onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.jktech.theologicalAcademy')}
              >
                <MaterialIcon
                  name="google-play"
                  style={{ color: 'white', fontSize: 18, paddingRight: 8 }}
                />
                <Text style={{ color: '#ffe57f', fontStyle: 'italic', fontSize: 13, color: '#FFF'  }}>
                  Google Play
                </Text>
              </Button>
              <Button
                style={{ paddingHorizontal: 8, marginTop: 12, minWidth: 125 }}
                transparent
                hasText
                iconLeft
                onPress={() => Linking.openURL('https://apps.apple.com/us/app/theological-academy/id1486054350')}
              >
                <MaterialIcon
                  name="apple"
                  style={{ color: 'white', fontSize: 18, paddingRight: 8 }}
                />
                <Text style={{ color: '#ffe57f', fontStyle: 'italic', fontSize: 13, color: '#FFF', }}>
                  App Store
                </Text>
              </Button>
            </View>
          )}
          <Fab style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center'  }}
               onPress={this.props.navigation.toggleDrawer}
               position={Platform.OS === 'web' && Dimensions.get('screen').width > 500  ? "topLeft" : "bottomRight"}
          >
            <Icon
              name="menu"
              style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0 }}
            />
          </Fab>
        </ScrollView>
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    currentChapterId: state.coursesData.currentChapterId,
    currentSectionId: state.coursesData.currentSectionId,
    currentPageIndex: state.coursesData.currentPageIndex,
    currentCourseId: state.coursesData.currentCourseId,
    course: getCourseById(state, state.coursesData.currentCourseId),
    firstName: state.userData.firstName,
    notifications: state.userData.notifications,
    initialNotification: state.userData.initialNotification,
    fetchingNotifications: state.userData.fetchingNotifications,
    fetchNotificationsSuccess: state.userData.fetchNotificationsSuccess,
    currentExpoToken: state.userData.currentExpoToken,
  }
};

export default connect(mapStateToProps, { fetchNotifications, addPushToken, fetchCourseForumApi, resetInitialNotification })(HomeContainer);
