import _ from 'lodash';
import { ActionType,  getType } from 'typesafe-actions';
import {
    editForumResponseAsync,
    fetchResponsesAsync,
    submitForumResponseAsync,
    deleteForumResponseAsync,
    logOutAsync,
    initForumTopicAsync, submitForumAnswerAsync,
} from '../actions';
import * as coursesActions from '../actions/courses.actions';

import { Course, ForumChapterQuestion, ForumTopic } from '../../models/course';
import { submitAnswerAsync } from '../actions';

export type CoursesReducerState = Readonly<{
    courses: Array<Course>;
    currentCourseId: string;
    currentChapterId: number;
    currentSectionId: number;
    currentPageIndex: number;
    // API
    fetchingCourses: boolean;
    fetchingCoursesSuccess: boolean | null;
    fetchingChapters: boolean;
    fetchingChaptersSuccess: boolean | null;
    fetchingPages: boolean;
    fetchingPagesSuccess: boolean | null;
    fetchingChapterForum: boolean;
    fetchingChapterForumSuccess: boolean | null;
    fetchingForumAnswers: boolean;
    fetchingForumAnswersSuccess: boolean | null;
    fetchingForumResponses: boolean;
    fetchingForumResponsesSuccess: boolean | null;
    submittingForumResponse: boolean;
    submittingForumResponseSuccess: boolean | null;
    editingForumResponse: boolean;
    editingForumResponseSuccess: boolean | null;
    deletingForumResponse: boolean;
    deletingForumResponseSuccess: boolean | null;
    initializingNewForum: boolean;
    initializingNewForumSuccess: boolean | null;
    submittingNewAnswer: boolean;
    submittingNewAnswerSuccess: boolean | null;
}>;

export const COURSE_INITIAL_STATE: CoursesReducerState = {
    courses: [],
    currentCourseId: '',
    currentChapterId: 0,
    currentSectionId: 0,
    currentPageIndex: 0,
    // API
    fetchingCourses: false,
    fetchingCoursesSuccess: null,
    fetchingChapters: false,
    fetchingChaptersSuccess: null,
    fetchingPages: false,
    fetchingPagesSuccess: null,
    fetchingChapterForum: false,
    fetchingChapterForumSuccess: null,
    fetchingForumAnswers: false,
    fetchingForumAnswersSuccess: null,
    fetchingForumResponses: false,
    fetchingForumResponsesSuccess: null,
    submittingForumResponse: false,
    submittingForumResponseSuccess: null,
    editingForumResponse: false,
    editingForumResponseSuccess: null,
    deletingForumResponse: false,
    deletingForumResponseSuccess: null,
    initializingNewForum: false,
    initializingNewForumSuccess:  null,
    submittingNewAnswer: false,
    submittingNewAnswerSuccess: null,
};

export type CoursesAction = ActionType<typeof coursesActions | typeof submitAnswerAsync | typeof logOutAsync>;

const { fetchCoursesAsync, fetchChaptersAsync, fetchPagesAsync, fetchCourseForumAsync,
    setCurrentCourse, setCurrentChapter, setCurrentSection, fetchForumAnswersAsync, setCurrentPageIndex } = coursesActions;

export default (state = COURSE_INITIAL_STATE, action: CoursesAction ): CoursesReducerState => {

    switch (action.type){
        case getType(logOutAsync.success):
            return COURSE_INITIAL_STATE;
        case getType(fetchCoursesAsync.request):
            return {
                ...state,
                fetchingCourses: true
            };
        case getType(fetchCoursesAsync.success):
            return {
                ...state,
                courses: action.payload,
                fetchingCourses: false,
                fetchingCoursesSuccess: true
            };
        case getType(fetchCoursesAsync.failure):
            return {
                ...state,
                fetchingCourses: false,
                fetchingCoursesSuccess: false
            };
        case getType(fetchChaptersAsync.request):
            return {
                ...state,
                fetchingChapters: true
            };
        case getType(fetchChaptersAsync.success):
            let { chapters, courseId } = action.payload;
            return {
                ...state,
                courses: state.courses?.map(course => {
                    return course.id === courseId ? {...course, chapters: chapters} : course;
                }),
                fetchingChapters: false,
                fetchingChaptersSuccess: true
            };
        case getType(fetchChaptersAsync.failure):
            return {
                ...state,
                fetchingChapters: false,
                fetchingChaptersSuccess: false
            };
        case getType(fetchPagesAsync.request):
            return {
                ...state,
                fetchingPages: true
            };
        case getType(fetchPagesAsync.success):
            ({ courseId } = action.payload);
            let { pages, chapterId, sectionIndex } = action.payload;
            let coursesCopy = [...state.courses];

            if(typeof sectionIndex === 'string'){
               sectionIndex = parseInt(sectionIndex, 10);
            }
            for(const course of coursesCopy){
                if(course.id === courseId) {
                    course.chapters.forEach((chapter) => {
                        if(chapter.id === chapterId) {
                            const pagesSorted: any = _.orderBy(pages, ['pageNumber', 'desc']);
                            chapter.sectionsData[sectionIndex].pages = pagesSorted;
                        }
                    });
                    break;
                }
            }

            return {
                ...state,
                courses: coursesCopy,
                fetchingPages: false,
                fetchingPagesSuccess: true
            };
        case getType(fetchPagesAsync.failure):
            return {
                ...state,
                courses: [...state.courses],
                fetchingPages: false,
                fetchingPagesSuccess: false
            };
        case getType(setCurrentCourse):
            return {
                ...state,
                currentCourseId: action.payload
            };
        case getType(setCurrentChapter):
            return {
                ...state,
                currentChapterId: action.payload
            };
        case getType(setCurrentSection):
            return {
                ...state,
                currentSectionId: action.payload
            };
        case getType(setCurrentPageIndex):
            const newIndex = action.payload === 1 || action.payload === -1 ? state.currentPageIndex + action.payload : action.payload;
            return {
                ...state,
                currentPageIndex: newIndex
            };
        case getType(submitAnswerAsync.success):
            let { answer, pageId, share, answerId } = action.payload.data;
            ({ courseId, chapterId} = action.payload.data);
            coursesCopy = [...state.courses];

            const courseIndex = _.findIndex(coursesCopy, course => course.id === courseId);
            const chapterIndex = _.findIndex(coursesCopy[courseIndex].chapters, chapter => chapter.id === chapterId);

            for(const section of coursesCopy[courseIndex].chapters[chapterIndex].sectionsData) {
                let found = false;

                for (const page of section?.pages ?? []){
                    if (page.id === pageId){
                        page.shareAnswer = share;
                        page.userAnswer = answer;
                        page.answerId = answerId;
                        found = true;
                        break;
                    }
                }

                if (found){
                    break;
                }
            }

            return {
                ...state,
                courses: coursesCopy,
            };
        case getType(fetchCourseForumAsync.request):
            return {
                ...state,
                fetchingChapterForum: true,
                fetchingChapterForumSuccess: null
            };
        case getType(fetchCourseForumAsync.success):
            ({courseId} = action.payload);
            let {forumTopics, forumChQuestionTopics } = action.payload;
            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {...course, forumTopics, forumChQuestionTopics} : course;
                }),
                fetchingChapterForum: false,
                fetchingChapterForumSuccess: true
            };
        case getType(fetchCourseForumAsync.failure):
            return {
                ...state,
                fetchingChapterForum: false,
                fetchingChapterForumSuccess: false
            };
        case getType(fetchForumAnswersAsync.request):
            return {
                ...state,
                fetchingForumAnswers: true,
                fetchingForumAnswersSuccess: null
            };
        case getType(fetchForumAnswersAsync.success):
            ({courseId} = action.payload);
            let { forumTopicId, forumAnswers } = action.payload;
            let isForumChQuestionTopic = forumTopicId.includes('chapter');

            const sortedForumAnswers = forumAnswers.sort((a: any, b: any) => a.timeStamp - b.timeStamp);
            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: !isForumChQuestionTopic ? course.forumTopics ? course.forumTopics.map(topic => {
                                return topic.id === forumTopicId ? {...topic, answers: sortedForumAnswers} : topic
                            }) : [] : [...course.forumTopics],
                            forumChQuestionTopics: isForumChQuestionTopic ? course.forumChQuestionTopics ? course.forumChQuestionTopics.map(topic => {
                                return topic.id === forumTopicId ? {...topic, answers: sortedForumAnswers} : topic
                            }) : [] : [...course.forumChQuestionTopics],
                        }
                    : course;
                }),
                fetchingForumAnswers: false,
                fetchingForumAnswersSuccess: true
            };
        case getType(fetchForumAnswersAsync.failure):
            return {
                ...state,
                fetchingForumAnswers: false,
                fetchingForumAnswersSuccess: false
            };
        case getType(fetchResponsesAsync.request):
            return {
                ...state,
                fetchingForumResponses: true,
                fetchingForumResponsesSuccess: null
            };
        case getType(fetchResponsesAsync.success):
            ({courseId, forumTopicId, answerId} = action.payload);
            let { responses } = action.payload;
            isForumChQuestionTopic = forumTopicId.includes('chapter');

            if(courseId)
            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: !isForumChQuestionTopic ? course.forumTopics ? course.forumTopics.map((topic: ForumTopic) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumTopics],
                            forumChQuestionTopics: isForumChQuestionTopic ? course.forumChQuestionTopics ? course.forumChQuestionTopics.map((topic: ForumChapterQuestion) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumChQuestionTopics]
                        }
                        : course;
                }),
                fetchingForumResponses: false,
                fetchingForumResponsesSuccess: true
            };
        case getType(fetchResponsesAsync.failure):
            return {
                ...state,
                fetchingForumResponses: false,
                fetchingForumResponsesSuccess: false
            };
        case getType(submitForumResponseAsync.request):
            return {
                ...state,
                submittingForumResponse: true,
                submittingForumResponseSuccess: null
            };
        case getType(submitForumResponseAsync.success):
            ({ courseId, forumTopicId, answerId } = action.payload);
            let { data } = action.payload;
            isForumChQuestionTopic = forumTopicId.includes('chapter');

            data.timeStamp = data.timeStamp.toDate();
            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: !isForumChQuestionTopic ? course.forumTopics ? course.forumTopics.map((topic: ForumTopic) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses: answer.responses ? [data, ...answer.responses] : [data]} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumTopics],
                            forumChQuestionTopics: isForumChQuestionTopic ? course.forumChQuestionTopics ? course.forumChQuestionTopics.map((topic: ForumChapterQuestion) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses: answer.responses ? [data, ...answer.responses,] : [data]} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumChQuestionTopics],
                        }
                        : course;
                }),
                submittingForumResponse: false,
                submittingForumResponseSuccess: true
            };
        case getType(submitForumResponseAsync.failure):
            return {
                ...state,
                submittingForumResponse: false,
                submittingForumResponseSuccess: false
            };
        case getType(editForumResponseAsync.request):
            return {
                ...state,
                editingForumResponse: true,
                editingForumResponseSuccess: null
            };
        case getType(editForumResponseAsync.success):
            ({courseId, forumTopicId, answerId} = action.payload);
            let {response, responseId} = action.payload;
            isForumChQuestionTopic = forumTopicId.includes('chapter');

            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: !isForumChQuestionTopic ? course.forumTopics ? course.forumTopics.map((topic: ForumTopic) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses: answer.responses ?
                                                answer.responses.map((record) => {
                                                    return record.id === responseId ? {...record, response: response} : record
                                                }) : []} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumTopics],
                            forumChQuestionTopics: isForumChQuestionTopic ? course.forumChQuestionTopics ? course.forumChQuestionTopics.map((topic: ForumChapterQuestion) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses: answer.responses ?
                                                answer.responses.map((record) => {
                                                    return record.id === responseId ? {...record, response: response} : record
                                                }) : []} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumChQuestionTopics],
                        }
                        : course;
                }),
                editingForumResponse: false,
                editingForumResponseSuccess: true
            };
        case getType(editForumResponseAsync.failure):
            return {
                ...state,
                editingForumResponse: false,
                editingForumResponseSuccess: false
            };
        case getType(deleteForumResponseAsync.request):
            return {
                ...state,
                deletingForumResponse: true,
                deletingForumResponseSuccess: null
            };
        case getType(deleteForumResponseAsync.success):
            ({courseId, forumTopicId, answerId, responseId} = action.payload);
            isForumChQuestionTopic = forumTopicId.includes('chapter');

            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: !isForumChQuestionTopic ? course.forumTopics ? course.forumTopics.map((topic: ForumTopic) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses: answer.responses ?
                                                answer.responses.filter((record) => {
                                                    return record.id !== responseId
                                                }) : []} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumTopics],
                            forumChQuestionTopics: isForumChQuestionTopic ? course.forumChQuestionTopics ? course.forumChQuestionTopics.map((topic: ForumChapterQuestion) => {
                                return topic.id === forumTopicId && topic.answers ? {
                                    ...topic, answers: topic.answers.map(answer => {
                                        return answer.id === answerId ? {...answer, responses: answer.responses ?
                                                answer.responses.filter((record) => {
                                                    return record.id !== responseId
                                                }) : []} : answer
                                    } )
                                } : topic
                            }) : [] : [...course.forumChQuestionTopics],
                        }
                        : course;
                }),
                deletingForumResponse: false,
                deletingForumResponseSuccess: true
            };
        case getType(deleteForumResponseAsync.failure):
            return {
                ...state,
                deletingForumResponse: false,
                deletingForumResponseSuccess: false
            };
        case getType(initForumTopicAsync.request):
            return {
                ...state,
                initializingNewForum: true,
                initializingNewForumSuccess: null
            };
        case getType(initForumTopicAsync.success):
            ({courseId, forumTopicId, answerId, answer} = action.payload);
            let { topic, firstName, lastName, timeStamp, userId } = action.payload;

            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: [
                                ...course.forumTopics,
                                {
                                    id: forumTopicId,
                                    topic,
                                    answers: [
                                        {
                                            answer,
                                            firstName,
                                            lastName,
                                            timeStamp,
                                            userId
                                        }
                                    ]
                                }
                            ]
                        } : course;
                }),
                initializingNewForum: false,
                initializingNewForumSuccess: true
            };
        case getType(initForumTopicAsync.failure):
            return {
                ...state,
                initializingNewForum: false,
                initializingNewForumSuccess: false
            };
        case getType(submitForumAnswerAsync.request):
            return {
                ...state,
                submittingNewAnswer: true,
                submittingNewAnswerSuccess: null
            };
        case getType(submitForumAnswerAsync.success):
            ({courseId, forumTopicId, answerId, answer, firstName,
                lastName, timeStamp, userId} = action.payload);

            return {
                ...state,
                courses: state.courses.map(course => {
                    return course.id === courseId ? {
                            ...course,
                            forumTopics: course.forumTopics.map((topic) => {
                                return topic.id === forumTopicId ? {...topic, answers: [...topic.answers || [],
                                        {
                                            answer,
                                            firstName,
                                            lastName,
                                            timeStamp,
                                            userId
                                        }
                                    ]
                                } : topic
                            })
                        } : course;
                }),
                submittingNewAnswer: false,
                submittingNewAnswerSuccess: true
            };
        case getType(submitForumAnswerAsync.failure):
            return {
                ...state,
                submittingNewAnswer: false,
                submittingNewAnswerSuccess: false
            };
        default:
            return state;
    }
}
