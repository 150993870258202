import './rn-polyfill-depricated-proptypes';
import React, {Component} from 'react';
import { ActivityIndicator, LogBox, Platform, View } from 'react-native';
import * as Notifications from 'expo-notifications';
import { NavigationContainer } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import createSentryMiddleware from 'redux-sentry-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, Store, Middleware } from 'redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import ReduxThunk from 'redux-thunk';
import { enableScreens } from 'react-native-screens';
import { getApps, initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import * as ExpoSplashScreen from 'expo-splash-screen';
import { Root } from 'native-base';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist/es/types';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import reducers from './src/redux/reducers';
import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants';
import { ErrorBoundary } from './src/common/components/error-boundary.container';
import RootStack, { linkingConfig } from './src/navigation/navigation';
import { NavigationService } from './src/common/services/navigation.service';
import { setInitialNotification } from './src/redux/actions';

require('firebase/firestore');

interface Props {}

interface StoreObject {
  storeObject: Store;
  persistor: Persistor;
}

export let store: Store;
export let firebaseApp;

Sentry.init({
  dsn: 'https://ee2b7932ec33423eb4813e5568ecc079@o185572.ingest.sentry.io/1806182',
  enableInExpoDevelopment: true,
  debug: true,
  release: Constants.manifest.revisionId,
  normalizeDepth: 3,
});
ExpoSplashScreen.preventAutoHideAsync();

export default class App extends Component<Props, { isReady: boolean }> {

  constructor(props: Props){
    super(props);
    if (Platform.OS !== 'web') {
      window = undefined
    }

    if (!process.env.JEST_WORKER_ID) {
      enableScreens();
    }

    const config = {
      apiKey: 'AIzaSyAvn3eweh5bWC5IfprweSGePsIrTvmmPkM',
      authDomain: 'theological-academy.firebaseapp.com',
      databaseURL: 'https://theological-academy.firebaseio.com',
      projectId: 'theological-academy',
      storageBucket: 'theological-academy.appspot.com',
      messagingSenderId: '691010158959'
    };

    this.state = {
      isReady: false
    };

    if (getApps().length === 0) {
      firebaseApp = initializeApp(config);
      initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });
    }

    LogBox.ignoreLogs(['Setting a timer']);
  }

  componentDidMount(): void {
    void this._cacheResourcesAsync();
  }

  componentDidCatch() {
    void ExpoSplashScreen.hideAsync();
  }

  _cacheResourcesAsync = async() => {
    await Asset.loadAsync(require('./assets/logo.png'));

    await Font.loadAsync({
      'Roboto': require('native-base/Fonts/Roboto.ttf'),
      'Roboto_medium': require('native-base/Fonts/Roboto_medium.ttf'),
      'Ionicons': require('native-base/Fonts/Ionicons.ttf'),
    });

    this.setState({
      isReady: true,
    }, () => {
      void ExpoSplashScreen.hideAsync();
    });
  };

  private initStore(): StoreObject {
    const sentryMiddleware = createSentryMiddleware(Platform.OS === 'web' ? Sentry.Browser : Sentry.Native);
    const middleWares: Middleware<any>[] = sentryMiddleware ? [ReduxThunk, sentryMiddleware] : [ReduxThunk];
    const composables: any = composeWithDevTools(applyMiddleware(...middleWares));
    const persistConfig = {
      key: 'root',
      storage: AsyncStorage,
      whitelist:['data'],
      blacklist: ['coursesData']
    };
    const persistedReducer = persistReducer(persistConfig, reducers);
    const storeObject = createStore(persistedReducer, {}, composables);
    store = storeObject;

    return {
      storeObject,
      persistor: persistStore(store)
    }
  }

  public render(): JSX.Element {
    const { storeObject, persistor } = this.initStore();

    if (!this.state.isReady) {
      return null;
    }

    return (
      <Provider store={storeObject}>
        <ErrorBoundary>
          <PersistGate
            loading={<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="large" color="blue" /></View>}
            persistor={persistor}
          >
            <Root style={{flex: 1}}>
              <SafeAreaProvider>
                <NavigationContainer
                  ref={NavigationService._navigator}
                  linking={{
                    config: {},
                    async getInitialURL() {
                      // Handle URL from expo push notifications
                      const response = await Notifications.getLastNotificationResponseAsync();
                      if (response?.notification?.request?.trigger?.remoteMessage?.data?.body) {
                        storeObject.dispatch(setInitialNotification(JSON.parse(response.notification.request.trigger?.remoteMessage?.data?.body)));
                      }
                      return '';
                    },
                  }}>
                  <RootStack />
                </NavigationContainer>
              </SafeAreaProvider>
            </Root>
          </PersistGate>
        </ErrorBoundary>
      </Provider>
    );
  }
}
