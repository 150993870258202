import * as Notifications from 'expo-notifications';
import { Alert, Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export async function registerForPushNotificationsAsync() {
  try {
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('general', {
        name: 'general',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    const {status: existingStatus} = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== 'granted') {
      const {status} = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== 'granted') {
      Alert.alert('Notifications', 'Enable push notifications if you want to receive them whenever someone communicates to you or posts on a forum you create');
      return;
    }
    const token = (await Notifications.getExpoPushTokenAsync({
      experienceId: '@wardjk87/theological_academy_mobile'
    })).data;
    return token;
  } catch (error) {
    (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
  }
}