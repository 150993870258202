import React, { Component } from 'react';
import { Dimensions, Platform, ScrollView, Text, View } from 'react-native';
import { Content, Container, Card, CardItem, Left, Icon, Accordion, List, ListItem, Fab } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import { DrawerNavigationProps } from '../../navigation/navigation';

interface State {
  readonly test: string;
}

interface Info {
  title: string,
  information: string [],
}

interface Props extends DrawerNavigationProps<'About'> {
  readonly fontSize: number;
}

const academyInfo: Info[] = [{
  title: 'Academies',
  information: [
    'Here you will find the content of this theological academy. More material will be added about every other week.',
    'Although some courses are related or part of a series, you do not have to do them in any order. You can choose whichever course interests you.',
    'Overview tab: \n\nHere you might find a course summary.',
    'Content tab: \n\nHere you can navigate the actual course material. You can read or listen along. \n \nThroughout the material, you will find thought provoking questions related to the content. Responding to these questions is how your progress is tracked. Consider these spiritual exercises. By them you will digest the content for the sake of spiritual and intellectual development. They can inspire soul-searching and a conversation with God. \n \nYou can also share your answers by tapping the "Make Public?" toggle, so others can be edified and even discuss with you.',
    'Forum tab: \n\nHere you will be able to read and chat about answers shared by other students. You can engage in forum topics related to this course or start your own.',
    'Updates tab:\n\nHere you will find any updates that have been made to the content, such as what was changed and when.',
  ]
}];

const forumInfo: Info[] = [{
  title: 'Forum',
  information: [
    'Here you can engage a community of other believers seeking to mature in the knowledge of God.',
    'This area is where you will find any of the possible conversations you could have with brothers and sisters in Christ.',
    'Topics tab: \n\nHere you will find topics that you have started. You will see how many statements have been submitted on a topic. From here you can also enter the chatroom on the topic.',
    'Answers tab: \n\nHere you will the answers you have shared. You will be able to track the conversations inspired by your answers. From here you can also enter a chatroom about your answer.',
    'Responses tab: \n\nHere you will be able to track the responses you have given to the answers others shared. You will be able to re-enter the chatroom, in which you first responded.',
    'Private chat tab:\n\nHere you will find any private conversations that exists between you and other students of Revelation. You can continue where you left off and read responses. Private chats can only be started from within a public forum that you have chosen to participate in.',
  ]
}];

const progressInfo: Info[] = [{
  title: 'Progress',
  information: [
    'Here you will see what courses you have completed.',
    'You can also track your progress on unfinished courses',
  ]
}];

const notificationInfo: Info[] = [{
  title: 'Notifications',
  information: [
    'Here you will be notified of any recent development that may interest you.',
    'You will be notified if anyone has responded to an answer you have shared.',
    'You will be notified if anyone had posted a statement on a topic you started.',
    'You will be notified if anyone wants to start a private chat with you based on one of your existing chats in a public forum.',
    'You will be notified if someone has responded to you as part of a private chat.',
    'Tapping notifications will often take you to the relevant part of the forum page.',
    'You can mark a notification as read by tapping the circle. This will make the notification disappear from this page in the future.',
  ]
}];

class AboutContainer extends Component <Props, State> {

  private renderHeader = (info: Info, expanded: boolean): JSX.Element => {
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            padding: 8,
            alignItems: 'center' ,
            backgroundColor: '#ffe57f',
            borderWidth: .75
          },
          expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null]}
      >
        <View style={{flex: .90}}>
          <Text style={{ fontWeight: expanded ? '600' : '400', fontSize: 18, paddingLeft: 8, paddingRight: 16, textAlign: 'left'}}>
            {info.title}
          </Text>
        </View>
        <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
          {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} style={{ fontSize: 20, color: ACTION_COLOR }} />
            : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} style={{ fontSize: 20, color: ACTION_COLOR }} />
          }
        </View>
      </View>
    )
  };

  private renderAboutInfo = (info: Info): JSX.Element => {
    return (
      <ScrollView style={{ backgroundColor: '#FFAB00', maxHeight: Dimensions.get('screen').height * .15}}>
        <List>
          {info.information.map((text: string, index: number) => {
            return (
              <ListItem
                style={{ borderColor: '#373737' }}
                key={index}>
                <Left>
                  <Text style={{ fontWeight: 'bold', color: '#595914', fontSize: this.props.fontSize }}>{text}</Text>
                </Left>
              </ListItem>
            )
          })}
        </List>
      </ScrollView>
    )
  };

  public render = (): React.ReactNode =>  {
    let width = 350;
    const screenWidth = Dimensions.get('screen')?.width;

    if (Platform.OS === 'web' && screenWidth > 1000) {
      width = 750;
    }
    return (
      <View style={{ flex: 1, justifyContent: 'center'}}>
        <ScrollView
          style={{margin: 0, padding: 16, backgroundColor: '#474646' }}
          contentContainerStyle={{  justifyContent: 'center', flex: 1, alignItems: 'center' }}
        >
          <View>
            <Card style={{ minWidth: 350, width, maxWidth: 1000 }}>
              <CardItem
                header
                bordered
                first
                style={{ backgroundColor: PRIMARY_COLOR_ACCENT,  borderColor: '#474646'}}
              >
                <Text style={{fontWeight: 'bold' }}>About Theological Academy</Text>
              </CardItem>
              <CardItem style={{ backgroundColor: '#ffe57f', marginTop: 5 }}>
                <Accordion
                  dataArray={academyInfo}
                  renderHeader={this.renderHeader}
                  renderContent={this.renderAboutInfo}
                  expanded={[]}
                />
              </CardItem>
              <CardItem style={{ backgroundColor: '#ffe57f' }}>
                <Accordion
                  dataArray={forumInfo}
                  renderHeader={this.renderHeader}
                  renderContent={this.renderAboutInfo}
                  expanded={[]}
                />
              </CardItem>
              <CardItem style={{ backgroundColor: '#ffe57f' }}>
                <Accordion
                  dataArray={progressInfo}
                  renderHeader={this.renderHeader}
                  renderContent={this.renderAboutInfo}
                  expanded={[]}
                />
              </CardItem>
              <CardItem style={{ backgroundColor: '#ffe57f' }}>
                <Accordion
                  dataArray={notificationInfo}
                  renderHeader={this.renderHeader}
                  renderContent={this.renderAboutInfo}
                  expanded={[]}
                />
              </CardItem>
            </Card>

          </View>
        </ScrollView>
        <Fab
          style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center'  }}
          onPress={this.props.navigation.toggleDrawer}
          position={Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? "topLeft" : "bottomRight"}
        >
          <Icon
            name="menu"
            style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0  }}
          />
        </Fab>
      </View>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    fontSize: state.userData.fontSize,
  }
};

export default connect(mapStateToProps, { })(AboutContainer);
