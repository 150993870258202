import React, { useEffect, useState } from 'react';
import { Content, View, Text, Form, Textarea, Switch, Label } from 'native-base';
import { contentStyles } from '../content.styles';
import { SubmitButton } from '../../../common/components/submit-button';
import { PRIMARY_COLOR } from '../../../common/global.styles';
import * as Clipboard from 'expo-clipboard';
import { Alert, Platform } from 'react-native';

type Props = {
  readonly alreadyAnswered: boolean;
  readonly userAnswer: string;
  readonly question: string;
  readonly submitHandler: (answer: string, share: boolean, resubmission: boolean) => void;
  readonly shareAnswer?: boolean;
}

const QuestionTab: React.FunctionComponent<Props> =
  ({question, submitHandler, userAnswer = '', alreadyAnswered, shareAnswer = false }: Props): JSX.Element => {
    const [answerState, setAnswerState] = useState(userAnswer);
    const [shareState, setShareState] = useState(shareAnswer);

    useEffect(() => {
      setAnswerState(userAnswer);
    }, [userAnswer]);

    const formattedQuestion = question.replace(/\\n/g, '\n');

    const submit = () => {
      submitHandler(answerState, shareState, false);
    };

    const resubmit = () => {
      submitHandler(answerState, shareState, true);
    };

    async function onPress() {
      await Clipboard.setStringAsync(formattedQuestion);
      Alert.alert('Copied', 'This question was copied to your clipboard.')
    }

    return (
      <Content style={{margin: 0, padding: 16, backgroundColor: '#474646'}} >
        <Text style={contentStyles.questionText} onPress={onPress}>{formattedQuestion}</Text>
        <View style={{ flex: 1, alignItems: 'center'}}>
          <Form style={[{marginTop: 24},  Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
            <Textarea
              rowSpan={7}
              placeholderTextColor={'#ffe57f'}
              style={{color: '#ffe57f', fontSize: 18, borderWidth: .5, borderColor: PRIMARY_COLOR }}
              placeholder="Answer (minimum 100 characters)"
              value={answerState}
              onChangeText={setAnswerState}
            />
            <View style={contentStyles.submitContainer}>
              <SubmitButton
                loading={false}
                disabled={answerState.length < 100}
                text={alreadyAnswered ? 'Resubmit' : 'Submit'}
                onPress={alreadyAnswered ? resubmit : submit}
              />
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 15,
                marginHorizontal: 36
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginRight: 16,
                  paddingLeft: 7
                }}
              >
                <Label style={{ color: '#e2e2e2' }}>Make Public?</Label>
              </View>
              <View>
                <Switch
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    marginLeft: 16,
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                  value={shareState}
                  onValueChange={setShareState}
                  trackColor={{
                    false: '#303030',
                    true: '#FFAB00'
                  }}
                />
              </View>
            </View>
          </Form>

        </View>
      </Content>
    );
  };

export { QuestionTab }
