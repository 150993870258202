import React from 'react';
import { ForumResponseData } from '../../models/course';

export type CourseContextProps = {
  readonly handleForumResponseEdit: (data: ForumResponseData) => void;
  readonly handleForumResponseDelete: (data: ForumResponseData) => void;
  readonly handleForumResponse: (data: ForumResponseData) => void;
  readonly handleNewForum: ({ topic, answer} : {topic: string, answer: string }) => void;
  readonly loadForumTopicAnswers: (forumTopicId: string) => void;
  readonly goToForumAnswersPage: (forumTopicId: string, isChapterQuestion: boolean, questionPageId?: string) => void;
};

const CourseContext = React.createContext<CourseContextProps>({} as CourseContextProps);

export { CourseContext as default }
