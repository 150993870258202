import React, { Component, ErrorInfo } from 'react';
import * as Sentry from 'sentry-expo';
import { Platform } from 'react-native';

export class ErrorBoundary extends Component<{}, { hasError: boolean, eventId: any}> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }
  public static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).withScope((scope) => {
      scope.setExtras(info);
      const eventId = (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      this.setState({ eventId, hasError: true });
    });
  }

  public render(): React.ReactNode {
    // TODO::: Use later for Error Feedback Reporting
    // if (this.state.hasError) {
    //   // render fallback UI
    //   return (
    //     <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    //       <Text>Report feedback</Text>
    //     </View>
    //   );
    // }

    // when there's not an error, render children untouched
    return this.props.children;  }
}
