import React from 'react';
import { Content, Accordion, View, Text, List, ListItem, Left, Right} from 'native-base';
import Icon from 'react-native-vector-icons/Ionicons';
import { Platform } from 'react-native';
import Slider from '@react-native-community/slider';
import { Chapter } from '../../../models/course';
import { courseStyles } from '../course.styles';
import { ACTION_COLOR } from '../../../common/global.styles';

type Props = {
  readonly navigate: (chapterId: number, sectionIndex: number) => void,
  readonly questionsCount: number;
  readonly chapters: Array<Chapter>,
  readonly answerCountData: {chapterAnswerCollection: Array<{id: number, answerCount: number}>, courseAnswerCount: number};
};

const ContentTab: React.FunctionComponent<Props> = ( { navigate, chapters, questionsCount, answerCountData }: Props ): JSX.Element => {
  const renderHeader = (item: Chapter, expanded: boolean): JSX.Element => {
    return (
      <View style={[courseStyles.headerStyle, expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null, ]}>
        <View style={{flex:.95}}>
          <Text
            style={{ fontWeight: '600', fontSize: expanded ? 16 : 14, paddingRight: 16, textAlign: expanded ? 'center' : 'left'}}
          >
            {' '}{item.title}
          </Text>
          { expanded ?
            <View style={{marginTop: 4}}>
              <Text style={{textAlign: 'center', fontSize: 14}}>{item.subtitle}</Text>
              <View style={{paddingTop: 8, paddingBottom: 8}}>
                  <Text style={{textAlign: 'right', fontSize: 13, fontStyle: 'italic', lineHeight: 20}}>
                    {!Array.isArray(item) ? item.bibleQuote : item.bibleQuote[0]}
                  </Text>
              </View>
              <Text style={{textAlign: 'right', fontSize: 12, lineHeight: 16}}>
                ({answerCountData.chapterAnswerCollection[item.id -1].answerCount}/{item.questionsCount}) Questions Answered
              </Text>
            </View>
            : null }
          </View>
          <View style={{flex:.05, justifyContent: 'flex-end', paddingLeft: 8}}>
              {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} size={20} color="#f50057" />
                  : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} size={20} color="#f50057" />
              }
        </View>
      </View>
    );
  };

  const renderContent = (item: Chapter): JSX.Element => {
    return (
      <View style={courseStyles.contentContainer}>
        <List>
          {item.sections.map((section: { id: string, title: string }, index: number) => {
            return (
              <ListItem
                key={index}
                onPress={() => navigate(item.id, index)}
              >
                <Left>
                  <Text>{index+1}. {section?.title}</Text>
                </Left>
                <Right>
                  <Icon name={Platform.OS === 'ios' ? 'ios-arrow-forward' : 'md-arrow-forward'} size={18} />
                </Right>
              </ListItem>
            );
          })}
        </List>
      </View>
    );
  };

  return (
    <Content padder style={{backgroundColor: '#5f5f5f'}} contentContainerStyle={{ alignItems: 'center'}}>
      {!!(questionsCount) ? (
        <View style={[{flexDirection: 'row', flex: 1, },Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
          <View style={{ flex: .7 }}>
            <Slider
              style={{width: '100%', marginBottom: 8}}
              minimumTrackTintColor={ACTION_COLOR}
              minimumValue={0} value={answerCountData.courseAnswerCount}
              maximumValue={questionsCount}
              disabled={true}
              thumbTintColor="#ffe57f"
            />
          </View>
          <View style={{ flex: .3, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Text style={{fontSize: 11, color: '#ffe57f'}}>
              {(answerCountData.courseAnswerCount / questionsCount * 100).toFixed(1)}% Complete (Based on Answers)
            </Text>
          </View>
        </View>
      ) : (
        <View style={{flexDirection: 'row', flex: 1, marginVertical: 8 }}>
          <Text style={{fontSize: 12, color: '#ffe57f'}}>
            Total Questions Answered: {answerCountData?.courseAnswerCount ?? 0}
          </Text>
        </View>
      )}
      <View style={[ Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
        <Accordion
          dataArray={chapters}
          renderHeader={renderHeader}
          renderContent={renderContent}
          expanded={[]}
        />
      </View>

    </Content>
  );
};

export { ContentTab }

