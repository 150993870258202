import { StyleSheet } from 'react-native';

export const PRIMARY_COLOR: string = '#ffe57f';
export const PRIMARY_COLOR_ACCENT: string = '#FFAB00';

export const ACTION_COLOR: string = '#ff4081';

export const globalStyles = StyleSheet.create({
    center: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});