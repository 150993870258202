import React, {Fragment} from 'react';
import { Platform } from 'react-native';
import { Left, Button, Text, Right } from 'native-base';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch } from 'react-redux';
import { setCurrentPageIndex } from '../../../../redux/actions';
import navBtnStyles from './nav-btn.styles';
import VoiceService from '../../../../common/services/voice.service';
const { button: btnStyle, iconLeft, iconRight, textLeft, textRight } = navBtnStyles;

type Props = {
	leftNav: boolean;
	currentPageIndex: number;
	currentSectionId: number;
	currentChapterId: number;
	sectionNavigation: (incrementation: number) => void;
	chapterNavigation: (incrementation: number) => void;
	currentSectionLength?: number;
	numberOfSections?: number;
	numberOfChapters?: number;
	returnHome?: () => void;
}

export const NavButton: React.FunctionComponent<Props> = ({ leftNav, currentPageIndex, currentChapterId, currentSectionId, numberOfSections, numberOfChapters,
	                                                              currentSectionLength, sectionNavigation, chapterNavigation, returnHome}: Props): JSX.Element | null =>  {
	const isCurrentPageTheFirst: boolean = (currentPageIndex === 0);
	const isCurrentPageTheLast: boolean = currentPageIndex === currentSectionLength - 1;
	const dispatch = useDispatch();

	let buttonHandler, buttonText, buttonInterior;
	const increment = leftNav ? -1 : 1;

	if ((leftNav && !isCurrentPageTheFirst) || (!leftNav && !isCurrentPageTheLast)) {
		buttonHandler = () => dispatch(setCurrentPageIndex(increment));
		buttonText = leftNav ? 'Back' : 'Next';
	} else {
		const isThereAPreviousSection: boolean = currentSectionId > 1;
		const isThereAnotherSection: boolean = currentSectionId < (numberOfSections - 1);

		if ((leftNav && isThereAPreviousSection) || (!leftNav && isThereAnotherSection)) {
			buttonHandler = () => sectionNavigation(increment);
			buttonText = leftNav ? 'Prev Sec.' : 'Next Sec.';
		} else {
			const isThereAPreviousChapter: boolean = currentChapterId > 1;
			const isThereAnotherChapter: boolean = currentChapterId < (numberOfChapters - 1);
			if ((leftNav && isThereAPreviousChapter) || (!leftNav && isThereAnotherChapter)){
				buttonHandler = () => chapterNavigation(increment);
				buttonText = leftNav ? 'Prev Ch.' : 'Next Ch.';
			} else {
				if (!leftNav) {
					buttonText = 'Finished';
					buttonHandler = () => returnHome();
				}
			}
		}
	}

	if (!leftNav) {
		VoiceService.autoContinueHandler = buttonHandler;
	}

	if (!buttonText) {
		return null;
	}

	if (leftNav) {
		buttonInterior = (
			<Fragment>
				<Icon name={Platform.OS === 'ios' ? 'ios-arrow-back' : 'md-arrow-back'}
				      size={18}
				      style={iconLeft}
				/>
				<Text style={textLeft}>{buttonText}</Text>
			</Fragment>
		);
	} else {
		buttonInterior = (
			<Fragment>
				<Text style={textRight}>{buttonText}</Text>
				<Icon name={Platform.OS === 'ios' ? 'ios-arrow-forward' : 'md-arrow-forward'}
				      size={18}
				      style={iconRight}
				/>
			</Fragment>
		);
	}

	const button = (
		<Button iconLeft={leftNav}
		        iconRight={!leftNav}
		        full
		        style={[
			        btnStyle,
			        { marginLeft: leftNav ? 0 : 8 },
		        ]}
		        onPress={buttonHandler}>
		{buttonInterior}
		</Button>
	);


	if(buttonHandler && buttonText) {
		if(leftNav) {
			return <Left style={{marginRight: 8}}>{button}</Left>;
		}
		return <Right style={{marginLeft: 8}}>{button}</Right>;
	} else {
		return null;
	}
};