import React, { FC } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/Ionicons';
import Ionicon from 'react-native-vector-icons/Ionicons';
import { Platform } from 'react-native';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { ChatRoomStack, ContentStack } from './navigation';
import HomeContainer from '../features/home/home.container';
import ProgressPage from '../features/progress/progress.container';
import NotificationsPage from '../features/notifications/notifications.container';
import ProfileContainer from '../features/profile/profile.container';
import AboutContainer from '../features/about/about.container';
import ContactContainer from '../features/contact/contact.container';
import SettingsContainer from '../features/settings/settings.container';

const Drawer = createDrawerNavigator();

const AppNavigator: FC<any> = (): JSX.Element => {
    return (
      <Drawer.Navigator
        initialRouteName="Home"
        screenOptions={{
          drawerActiveBackgroundColor: 'grey',
          drawerActiveTintColor: 'white',
          drawerInactiveTintColor: 'white',
          drawerStyle: { backgroundColor: '#424242'},
          headerShown: false,
        }}
      >
        <Drawer.Screen
          name="Home"
          component={HomeContainer}
          options={{
            headerShown: false,
            headerTitle: 'Home',
            drawerLabel: 'Home',
            drawerIcon: () => <Icon name="home" size={24} style={{ color: '#ffe57f' }} />
          }}
        />
        <Drawer.Screen
          name="Academy"
          component={ContentStack}
          options={{
            drawerLabel: 'Academies',
            drawerIcon: () => <Icon name={Platform.OS === 'ios' ? 'ios-boat' : 'md-boat'} size={24} color="#ffe57f" />
          }}
        />
        <Drawer.Screen
          name="Forum"
          component={ChatRoomStack}
          options={{
            drawerLabel: 'Forum',
            drawerIcon: () => <Icon name={Platform.OS === 'ios' ? 'ios-chatbubbles' : 'md-chatbubbles'} size={24} color="#ffe57f" />
          }}
        />
        <Drawer.Screen
          name="Progress"
          component={ProgressPage}
          options={{
            headerTitle: 'Progress',
            drawerLabel: 'Progress',
            drawerIcon: () => <MaterialIcon name="format-list-checks" size={24} style={{ color: '#ffe57f' }} />
          }}
        />
        <Drawer.Screen
          name="Notifications"
          component={NotificationsPage}
          options={{
            headerTitle: 'Notifications',
            drawerLabel: 'Notifications',
            drawerIcon: () => <Icon name="notifications" size={24} style={{ color: '#ffe57f' }} />
          }}
        />
        <Drawer.Screen
          name="Profile"
          component={ProfileContainer}
          options={{
            headerTitle: 'Profile',
            drawerLabel: 'Profile',
            drawerIcon: () => <Icon name="person-circle" size={24} style={{ color: '#ffe57f' }} />
          }}
        />
        <Drawer.Screen
          name="About"
          component={AboutContainer}
          options={{
            headerTitle: 'About Theological Academy',
            drawerLabel: 'About',
            drawerIcon: () => <Ionicon name={`${Platform.OS === 'ios' ? 'ios-' : 'md-'}information-circle`} size={24} style={{ color: '#ffe57f' }} />
          }}
        />
        <Drawer.Screen
          name="Contact"
          component={ContactContainer}
          options={{
            headerTitle: 'Contact',
            drawerLabel: 'Contact',
            drawerIcon: () => <MaterialIcon name="card-account-mail" size={24} style={{ color: '#ffe57f' }} />
          }}
        />
        <Drawer.Screen
          name="Settings"
          component={SettingsContainer}
          options={{
            headerTitle: 'Settings',
            drawerLabel: 'Settings',
            drawerIcon: () => <Icon name="settings" size={24} style={{ color: '#ffe57f' }} />
          }}
        />
      </Drawer.Navigator>
    )
  };

  export default AppNavigator;
