import React, { Component } from 'react';
import { Card, Form, Toast } from 'native-base'
import { View, Keyboard, Image, Platform, NativeModules, LayoutAnimation, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import {  SubmitButton } from '../../../../common/components/submit-button';
import SignUpService from '../../signup/signup.service';
import loginStyles from '../login.styles';
import { initializeLogin, recacheUserData, restoreUserSession } from '../../../../redux/actions';
import { Auth } from '../../../../models/user';
import signUpStyles from '../../signup/signup.styles';
import { RootState } from '../../../../redux/redux.typescript';
import { MatInput } from '../../../../common/components/mat-input/mat-input.component';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthNavigationProps } from '../../../../navigation/navigation';

const { UIManager } = NativeModules;

interface Props extends AuthNavigationProps<'Login'> {
	readonly recacheUserData: () => void;
	readonly setIsLoadingState: (value: boolean) => void;
  readonly initializeLogin: (data: Auth) => void;
  readonly loginProcessCompletedSuccessfully: boolean | null;
  readonly loginProcessing: boolean;
  readonly restoreUserSession: (userData: any) => void;
  readonly restoringUserSession: boolean;
  readonly authenticatedButNotRegistered: boolean;
  readonly userSessionRestored: boolean | null;
	readonly deleteAccountPage?: boolean;
}

type State = {
  readonly cachedLogin: boolean;
	readonly checkingForLoginPersistence: boolean;
  readonly email: string;
  readonly hasError: boolean;
  readonly loading: boolean;
  readonly makingInitialLoginAttempt: boolean;
  readonly password: string;
  readonly passwordHidden: boolean;
  readonly userLoggedIn: boolean;
  readonly validEmail: boolean | null;
  readonly validPassword: boolean | null;
}

class LoginForm extends Component<Props, State>{
	private unsubscribe: any;
	public readonly state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
		checkingForLoginPersistence: !props?.deleteAccountPage,
		makingInitialLoginAttempt: false,
		userLoggedIn: false,
      validEmail: null,
			passwordHidden: true,
      validPassword: null,
      cachedLogin: false,
      email: '',
      hasError: false,
      loading: false,
      password: '',
    };

    if (Platform.OS === 'android') {
      UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    this.makeLoginAttempt = this.makeLoginAttempt.bind(this);
  }

  public componentDidMount (){
		setTimeout(() => {
			if(!this.props?.deleteAccountPage) {
				this.checkForLoginPersistence();
			}
		}, 2000);
  }

  public componentWillUnmount() {
		this?.unsubscribe?.();
	}

  public async componentDidUpdate(prevProps: Props, prevState: State){
		if (!this.props.loginProcessing && prevProps.loginProcessing ){
			this.setState({ loading: false});

    		if (this.props.loginProcessCompletedSuccessfully) {
				await this.onLoginSuccess();
			}
		}

		if (!prevProps.authenticatedButNotRegistered && this.props.authenticatedButNotRegistered) {
			this.props.navigation.navigate('SignUp', { authenticatedButNotRegistered: true })
		}

		if(this.state.userLoggedIn) {
			this.checkForLoginPersistenceResults(prevProps);
		}
	}

	private checkForLoginPersistence = () => {
    const auth = getAuth();
		if (!auth.currentUser) {
      this.setState({
          checkingForLoginPersistence: false
      }, () => {
				this.props.setIsLoadingState(false)
			});
		}

		this.unsubscribe = onAuthStateChanged(auth, (user) => {
			this.setState({
				checkingForLoginPersistence: true
			});
			if (user && !this.state.makingInitialLoginAttempt && (user.emailVerified || user.providerData?.[0]?.providerId?.includes('apple.com'))) {
				const authorizeUser = () => {
					this.props.recacheUserData();
					this.props.restoreUserSession(user);
				};
				this.setState({
					checkingForLoginPersistence: false,
					userLoggedIn: true
				}, authorizeUser);
			} else {
				this.props.setIsLoadingState(false);
				this.setState({
					checkingForLoginPersistence: false
				})
			}

			if (user && !user?.emailVerified) {
				Toast.show({
					duration: 5000,
					text: 'Please verify your email to proceed. You may need to check your spam folder.',
					type: 'danger'
				});
			}
		});
  };

	private checkForLoginPersistenceResults(prevProps: Props) {
		if (prevProps.restoringUserSession && !this.props.restoringUserSession) {
			if (this.props.userSessionRestored) {
				this?.unsubscribe?.();
			} else {
				this.setState({
					userLoggedIn: false
				})
			}
		}
	}

  private makeLoginAttempt(): void {
    const { email, password } = this.state;
		const emailTrimmed = email.trim();

    Keyboard.dismiss();

    const validEmail = SignUpService.validateEmail(email);
    const validPassword = SignUpService.validatePassword(password);
		LayoutAnimation.easeInEaseOut();

    if (!validPassword && !validEmail) {
      this.setState({
		    validEmail: false,
		    validPassword: false
	    });
      return;
    } else {
      if (!validPassword) {
		    this.setState({
			    validPassword: false,
			    validEmail: true
		    });
		    return;
	    } else if (!validEmail) {
		    this.setState({
			    validPassword: true,
			    validEmail: false,
		    });
		    return;
	    }
    }

		this.setState({
			validPassword: true,
			validEmail: true,
			loading: true,
			makingInitialLoginAttempt: true
		}, () => {
			this?.unsubscribe?.();
			this.props.initializeLogin({email: emailTrimmed, password});
		});
  };

  private onLoginSuccess = async () =>{
		this.setState({
			email: '',
			password: '',
			loading: false
		});
  };

  public render(): JSX.Element | null {
    const { validEmail, validPassword, email, password, passwordHidden } = this.state;
		const logoSize = Platform.OS === 'web' ? 250 : Dimensions.get('screen').height * .25;
		const screenWidth = Dimensions.get('screen')?.width;
		let maxWidth = 300;

		if (Platform.OS === 'web' && screenWidth > 1000) {
			maxWidth = 750;
		}
		return (
			<View style={[signUpStyles.contentStyle, Platform.OS === 'web' && { justifyContent: 'center', flex: 1, alignItems: 'center' }]}>
				<View style={Platform.OS !== 'web' ? {flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'center'}: { flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
					<Image source={require('../../../../../assets/logo.png')} style={{ width: logoSize, height: logoSize }}/>
				</View>
				<Card style={[signUpStyles.cardStyle, { marginBottom: 16 }, Platform.OS === 'web' && {  maxWidth }]}>
					<Form style={[loginStyles.form, Platform.OS === 'web' && {}]}>
						<MatInput
							label="Email"
							onChange={val => this.setState({email: val})}
							value={email}
							keyword="email"
							validation={validEmail}
							extraProps={{
								keyboardType: 'email-address',
							}}
						/>
						<MatInput
							label="Password"
							onChange={val => this.setState({password: val})}
							value={password}
							keyword="password"
							validation={validPassword}
							icon={password?.length ? (passwordHidden ? 'eye' : 'eye-off') : null}
							iconAction={() => this.setState(prevState => ({ passwordHidden: !prevState.passwordHidden}))}
							extraProps={{
								secureTextEntry: passwordHidden,
							}}
						/>
					</Form>
				</Card>
				<SubmitButton
					text={this.props?.deleteAccountPage ? 'DELETE' : "Login"}
					onPress={this.makeLoginAttempt}
					loading={this.state.loading}
					disabled={email.length < 3 && password.length < 6 }
				/>
			</View>
    );
  };
}

const mapStateToProps = (state: RootState) => {
	const { loginProcessing, loginProcessCompletedSuccessfully,
		restoringUserSession, userSessionRestored, authenticatedButNotRegistered } = state.userData;


	return {
		loginProcessing, loginProcessCompletedSuccessfully, restoringUserSession, userSessionRestored, authenticatedButNotRegistered
	}
};

export default connect(mapStateToProps, { initializeLogin, restoreUserSession, recacheUserData })(LoginForm);