import { Accordion, Left, List, ListItem, Right, Spinner, Text, View } from "native-base";
import React, { Fragment, useContext } from 'react';
import { Platform, StyleSheet } from 'react-native';
import Ionicon from 'react-native-vector-icons/Ionicons';
import { ForumAnswers, ForumChapterQuestion, ForumQuestion, ForumTopic } from '../../../../../models/course';
import { courseStyles } from '../../../course.styles';
import { ForumState } from './forum-tab/forum-tab.component';
import CourseContext, { CourseContextProps } from "../../../course.context";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/redux.typescript';
import { getAnswerCountForChapter } from '../../../../../redux/selectors';
import { useRoute } from '@react-navigation/native';

type Props = {
  topic: ForumChapterQuestion,
  index: number,
  chapterQuestions: Array<ForumChapterQuestion>,
  fetchingForumAnswersSuccess: boolean | null,
  forumState: ForumState,
  setForumState: ({expandedSectionId, isLoading, forumOpened, openedForumPageId,
                    openedForumTopicId, openChatRoom, openChatRoomId, response}: ForumState) => void
}

export const ForumQuestionRow: React.FunctionComponent<Props> =
  ({ topic, index, chapterQuestions, fetchingForumAnswersSuccess, setForumState, forumState }: Props) => {
    const { goToForumAnswersPage, loadForumTopicAnswers } = useContext<CourseContextProps>(CourseContext);
    //   const courseId = route.params?.courseId ?? '';
    //   const topicId = route.params?.topicId ?? '';
    //   const questionPageId = route.params?.questionPageId ?? '';
    //   const isChapterQuestion = route.params?.isChapterQuestion ?? false;
    const route = useRoute();
    const courseId = route?.params?.course?.id ?? '';
    const pageAnswerMap = useSelector((state: RootState) => getAnswerCountForChapter(state, courseId, topic.id, true));
    let open = (fetchingForumAnswersSuccess && forumState.expandedSectionId === topic.id);

    const renderIcon = (direction: 'up' | 'down') => <Ionicon name={Platform.OS === 'ios' ? `ios-arrow-${direction}` : `md-arrow-${direction}`} size={20} color="#f50057" />

    const renderHeader = (topicData: {id: string, title: string, questions: Array<ForumQuestion>}, expanded: boolean): JSX.Element => {
      return (
        <View style={courseStyles.headerStyle}>
          <View style={{flex:.95}}>
            <Text style={styles.headerTitle}>Chapter: {topicData.id[8]}: {topicData.title}</Text>
          </View>
          <View style={styles.headerIconContainer}>
            { expanded ? renderIcon('up') : renderIcon('down') }
          </View>
        </View>

      )
    };

    const renderContent = (topicData: {id: string, title: string, questions: Array<ForumQuestion>}): JSX.Element => {

      return (
        <View style={courseStyles.contentContainer}>
          <List>
            {topicData.questions.map((question: ForumQuestion, index: number) => {
              if(question) {
                const answerCount = pageAnswerMap?.get(question.pageId) || 0;
                return (
                  <ListItem
                    key={index}
                    onPress={() => goToForumAnswersPage(topicData.id, true, question.pageId)}
                  >
                    <Left style={{ flexDirection: 'column'}}>
                      <Text>{question.question}</Text>
                      <View>
                        <Text style={{ fontSize: 12, fontWeight: 'bold', paddingTop: 4, }}>({answerCount} Shared { answerCount === 1 ? 'Answer' : 'Answers'})</Text>
                      </View>
                    </Left>
                    <Right>
                      <Ionicon name={Platform.OS === 'ios' ? 'ios-arrow-forward' : 'md-arrow-forward'} size={18} />
                    </Right>
                  </ListItem>
                )
              }
            })}
          </List>
        </View>
      );
    };

    const expandForumSection = (forumId: string) => {
      loadForumTopicAnswers(forumId, true);
      setForumState({
        ...forumState,
        expandedSectionId: forumId,
        isLoading: true,
      });
    };

    if(open){
      const selectedForumTopic =  chapterQuestions.find((topic) => topic.id === forumState.expandedSectionId);

      let topicQuestionsWithAnswers: Array<ForumQuestion> = [];
      if(selectedForumTopic && selectedForumTopic.answers) {
        const answerPageIds = selectedForumTopic.answers.map(answer => answer.pageId);
        topicQuestionsWithAnswers = selectedForumTopic.questions.filter(question => answerPageIds.includes(question.pageId));
      }

      if(topicQuestionsWithAnswers.length > 0 ) {
        return (
          <Fragment key={topic.id}>
            <Accordion
              dataArray={[{id: topic.id, title: topic.topic, questions: topicQuestionsWithAnswers}]}
              renderHeader={renderHeader}
              expanded={[0]}
              renderContent={renderContent}
            />
          </Fragment>
        )
      }
    }

    return(
      <ListItem noIndent
                first
                key={index}
                onPress={() => expandForumSection(topic.id)}
                style={{backgroundColor: '#ffe57f'}}
      >
        {!forumState.isLoading ?
          <Text>Chapter {topic.id[8]}: {topic.topic}</Text>
          : <Spinner size='small'/>}
      </ListItem>
    )
  };

const styles = StyleSheet.create({
  headerTitle: {
    fontWeight: "600",
    fontSize: 14,
    paddingRight: 16
  },
  headerIconContainer: {
    flex: .05,
    justifyContent: 'flex-end',
    paddingLeft: 8
  }
});