import { createAction, createAsyncAction } from 'typesafe-actions';
import { AnswerObject, Auth, CourseProgress, ForumTopics } from '../../models/user';
import { Dispatch } from 'redux';
import { UserForumAnswer, UserForumResponse } from '../../models/course';
import { CoursesReducerState } from '../reducers/courses.reducer';
import { SignUpReducerState } from '../reducers/sign-up.reducer';
import { UserReducerState } from '../reducers/user.reducer';
import { ChatMsg, PrivateChat } from '../../models/private-chat';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Toast } from 'native-base';
import { fetchCoursesApi } from './courses.actions';
import * as Sentry from 'sentry-expo';
import { getAuth, deleteUser, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import {
  arrayUnion, writeBatch, collection, DocumentChange, onSnapshot, orderBy, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, addDoc, Timestamp, updateDoc, where, arrayRemove,
} from 'firebase/firestore';
import { sendEmailValidation } from './sign-up.actions';
import { firebaseApp } from '../../../App';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Notification as PushNotification } from '../../models/notification';
import { Platform } from 'react-native';

const adjustTextToSpeechRate = createAction('ADJUST_TEXT_TO_SPEECH_RATE')<number>();
const adjustTextToSpeechPitch = createAction('ADJUST_TEXT_TO_SPEECH_PITCH')<number>();
const adjustTextToSpeechVoice = createAction('ADJUST_TEXT_TO_SPEECH_VOICE')<string>();
const toggleTextToSpeechAutoContinue = createAction('TOGGLE_TEXT_TO_SPEECH_AUTO_CONTINUE')<void>();
const adjustFontSize = createAction('ADJUST_FONT_SIZE')<boolean>();
const needsToRegister = createAction('NEEDS_TO_REGISTER')<void>();
const setInitialNotification = createAction('SET_INITIAL_NOTIFICATION')<PushNotification>();
const resetInitialNotification = createAction('RESET_INITIAL_NOTIFICATION')<void>();

const initializeLogin = ({ email, password }: Auth) => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState}): Promise<void> => {

    dispatch(loginAsync.request());
    const auth = getAuth();
    try {
      const data = await signInWithEmailAndPassword(auth, email, password);
      if (data && data?.user?.uid) {
        if (!data.user.emailVerified) {
          Toast.show({
            duration: 5000,
            text: 'Please verify your email to proceed.',
            type: 'danger'
          });
          dispatch(sendEmailValidation(data.user));
          dispatch(loginAsync.failure());
        } else {
          dispatch(loginAsync.success());
          dispatch(recacheUserData());

          const { coursesData } = getState();
          if (coursesData.courses.length === 0 ) {
            dispatch(fetchCoursesApi());
          }
        }
      } else {
        dispatch(loginAsync.failure());
      }
    } catch (error) {
      Toast.show({
        duration: 5000,
        text: error && error.message ? error.message : 'There was an error logging in. Restart the app and try again.',
        type: 'danger'
      });
      dispatch(loginAsync.failure());
    }
  };
};

const loginAsync = createAsyncAction(
  'INITIALIZE_LOGIN',
  'LOGIN_SUCCESSFUL',
  'LOGIN_FAILED'
)<void, void, void>();

const initializeLogout = () => {
  return async(dispatch: Dispatch, getState: () => {userData: UserReducerState}): Promise<void> => {
  
    dispatch(logOutAsync.request());
    try {
      const { id, currentExpoToken } = getState().userData;
      if (currentExpoToken) {
        const db = getFirestore(firebaseApp);
        const userDataRef = doc(db, "users", id);
        await updateDoc(userDataRef, {expoTokens: arrayRemove(currentExpoToken)});
      }

      const auth = getAuth();
      await auth.signOut();
      dispatch(logOutAsync.success());

    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(logOutAsync.failure());
    }
  };
};

const logOutAsync = createAsyncAction(
  'INIT_LOGOUT',
  'LOGOUT_SUCCESSFUL',
  'LOGOUT_FAILED'
)<void, void, void>();

const removePushToken = (token: string): any => {
  return async(dispatch: Dispatch, getState: () => {userData: UserReducerState}): Promise<void> => {

    dispatch(removePushTokenAsync.request());
    try {
      const db = getFirestore(firebaseApp);
      const userId = getState().userData?.id;
      const userDataRef = doc(db, 'users', userId);
      await updateDoc(userDataRef, {expoTokens: arrayRemove(token)});
      dispatch(removePushTokenAsync.success())
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(removePushTokenAsync.failure());
    }
  };
};

const removePushTokenAsync = createAsyncAction(
  'REMOVE_PUSH_TOKEN_ATTEMPT',
  'REMOVE_PUSH_TOKEN_SUCCESSFUL',
  'REMOVE_PUSH_TOKEN_FAILED'
)<void, void, void>();

const addPushToken = (token: string): any => {
  return async(dispatch: Dispatch, getState: () => {userData: UserReducerState}): Promise<void> => {

    dispatch(addPushTokenAsync.request());
    try {
      const db = getFirestore(firebaseApp);
      const userId = getState().userData?.id;
      const userDataRef = doc(db, 'users', userId);
      
      await updateDoc(userDataRef, {expoTokens: arrayUnion(token)});
      dispatch(addPushTokenAsync.success(token))
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(addPushTokenAsync.failure());
    }
  };
};

const addPushTokenAsync = createAsyncAction(
  'ADD_PUSH_TOKEN_ATTEMPT',
  'ADD_PUSH_TOKEN_SUCCESSFUL',
  'ADD_PUSH_TOKEN_FAILED'
)<void, string, void>();


const initializeAccountDeletion = () => {
  return async(dispatch: Dispatch): Promise<void> => {

    dispatch(deleteAccountAsync.request());
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      await deleteUser(user);
      dispatch(deleteAccountAsync.success());
      Toast.show({
        duration: 5000,
        text: 'Account successfully Deleted.',
        type: 'success'
      });
    } catch (error) {
      Toast.show({
        duration: 5000,
        text: error && error.message ? error.message : 'There was an error deleting your account. Restart the app and try again.',
        type: 'danger'
      });
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(deleteAccountAsync.failure());
    }
  };
};

const deleteAccountAsync = createAsyncAction(
  'DELETE_ACCOUNT',
  'DELETE_ACCOUNT_SUCCESSFUL',
  'DELETE_ACCOUNT_FAILED'
)<void, void, void>();

export const testAsync = 'test';

const restoreUserSession = createAction('RESTORE_USER_SESSION')<any>();

const recacheUserData = (): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    const userId = getAuth()?.currentUser?.uid;
    if (userId) {
      let email;
      let firstName;
      let lastName;
      let profilePhotoUrl;
      let enrollmentReason;
      let aboutMe;
      let testimony;

      dispatch(recacheUserDataAsync.request());

      try {
        const db = getFirestore(firebaseApp);
        const userDbRef = doc(db, 'users', userId);
        const userDbProfileRef = doc(db, 'users', userId, 'public', 'profile');
        const userProgressQuery = query(collection(db, 'users', userId, 'courseProgress'));
        const userForumTopicsQuery = query(collection(db, 'users', userId, 'forumTopicsCreated'));
        const userForumAnswersQuery = query(collection(db, 'users', userId, 'forumAnswers'));
        const userForumResponsesQuery = query(collection(db, 'users', userId, 'forumResponses'));
        const userChatsAnswersQuery = query(collection(db, 'users', userId, 'privateChats'));

        const [ userDataSnapshot, userProfileSnapshot, courseProgressSnapshot, topicsSnapshot, answersSnapshot, responsesSnapshot, chatsSnapshot] = await Promise.all([
          getDoc(userDbRef),
          getDoc(userDbProfileRef),
          getDocs(userProgressQuery),
          getDocs(userForumTopicsQuery),
          getDocs(userForumAnswersQuery),
          getDocs(userForumResponsesQuery),
          getDocs(userChatsAnswersQuery),
        ]);

        const courseProgress = [];
        const forumResponses = [];
        const forumTopicsCreated = [];
        const forumAnswers = [];
        const privateChats = [];

        if (userDataSnapshot?.exists()) {
          ({ email = '', firstName = '', lastName = '', testimony = '', enrollmentReason = '' } = userDataSnapshot?.data());
        } else {
          // Authenticated But No User Data. Needs to Register
          dispatch(needsToRegister());
          return;
        }

        if (userProfileSnapshot?.exists()) {
          ({ testimony = '', aboutMe = '', enrollmentReason = '', profilePhotoUrl } = userDataSnapshot?.data());
        } else {
          await setDoc(userDbProfileRef, {
            aboutMe: '',
            profilePhotoUrl: '',
            testimony: '',
            enrollmentReason,
          });
        }

        if (courseProgressSnapshot && !courseProgressSnapshot.empty) {
          courseProgressSnapshot.forEach((course) => {
            courseProgress.push({
              ...course.data(),
              courseId: course.id
            });
          });
        }

        if (topicsSnapshot && !topicsSnapshot.empty) {
          topicsSnapshot.forEach((topic) => {
            forumTopicsCreated.push({
              ...topic.data(),
              topicId: topic.id
            });
          });
        }

        if (answersSnapshot && !answersSnapshot.empty) {
          answersSnapshot.forEach((answer) => {
            forumAnswers.push({
              ...answer.data(),
              answerId: answer.id,
              answer: answer.data().answer.trimRight(),
              pageId: answer.data().pageId,
            });
          });
        }

        if (responsesSnapshot && !responsesSnapshot.empty) {
          responsesSnapshot.forEach((response) => {
            forumResponses.push({
              ...response.data(),
              responseId: response.id,
              answer: response.data().answer.trimRight(),
              response: response.data().response.trimRight(),
            });
          });
        }

        if (chatsSnapshot && !chatsSnapshot.empty) {
          chatsSnapshot.forEach((chat) => {
            privateChats.push({
              ...chat.data(),
              startDate: chat.data().startDate.toDate(),
            });
          });
        }
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).setUser({ email, id: userId, username: `${firstName} ${lastName}`});
        dispatch(recacheUserDataAsync.success({ email, testimony, aboutMe, enrollmentReason, profilePhotoUrl, id: userId, firstName, lastName, courseProgress, forumTopicsCreated, forumAnswers, forumResponses, privateChats }))
      } catch (error) {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(recacheUserDataAsync.failure());
      }
    } else {
      dispatch(recacheUserDataAsync.failure());
    }
  }
};

const recacheUserDataAsync = createAsyncAction(
  'INITIALIZE_USER_DATA_RECACHE',
  'USER_DATA_RECACHE_SUCCESSFUL',
  'USER_DATA_RECACHE_FAILED'
)<void, { email: string, testimony: string, aboutMe: string, enrollmentReason: string, profilePhotoUrl: string, firstName: string, id: string, lastName: string, courseProgress: Array<CourseProgress>, forumTopicsCreated: Array<ForumTopics>,
  forumAnswers: Array<UserForumAnswer>, forumResponses: Array<UserForumResponse>, privateChats: Array<PrivateChat> }, void>();

const fetchOtherUserData = (otherUserId: string ): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    let profilePhotoUrl;
    let enrollmentReason;
    let aboutMe;
    let testimony;

    dispatch(fetchOtherUserDataAsync.request());

    try {
      const db = getFirestore(firebaseApp);
      const userProfileDbRef = doc(db, 'users', otherUserId, 'public', 'profile');
      const userProfileSnapshot = await getDoc(userProfileDbRef);

      if (userProfileSnapshot?.exists()) {
        ({  testimony = '', aboutMe = '', enrollmentReason = '', profilePhotoUrl } = userProfileSnapshot?.data());
      }
      dispatch(fetchOtherUserDataAsync.success({ testimony, aboutMe, enrollmentReason, profilePhotoUrl, otherUserId }))
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(fetchOtherUserDataAsync.failure());
    }
  }
};

const fetchOtherUserDataAsync = createAsyncAction(
  'FETCH_OTHER_USER_DATA',
  'FETCH_OTHER_USER_DATA_SUCCESSFUL',
  'FETCH_OTHER_USER_DATA_FAILED',
)<void, { testimony: string, aboutMe: string, enrollmentReason: string, profilePhotoUrl: string, otherUserId: string }, void >();

// Re-cache Answers
const recacheUserAnswers = (): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    const userId = getAuth()?.currentUser?.uid;

    if (userId) {
      try {
        dispatch(recacheAnswersAsync.request());
        const answerList: Array<AnswerObject> = [];
        const db = getFirestore(firebaseApp);
        const answersQuery = query(collection(db, 'users', userId, 'answers'));
        
        const answersSnapshot = await getDocs(answersQuery)
          .catch(() => {
            dispatch(recacheAnswersAsync.failure())
          });
        if (answersSnapshot) {
          if (!answersSnapshot.empty) {
            answersSnapshot.forEach((record: any) => answerList.push({
              ...record.data(),
              answerId: record.id
            }));
          }
          dispatch(recacheAnswersAsync.success(answerList))
        } else {
          dispatch(recacheAnswersAsync.failure())
        }
      } catch(error) {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(recacheAnswersAsync.failure())
      }
    }
  };
};

const recacheAnswersAsync = createAsyncAction(
  'INITIALIZE_ANSWERS_RECACHE',
  'ANSWERS_RECACHE_SUCCESSFUL',
  'ANSWERS_RECACHE_FAILED'
)<void, Array<AnswerObject>, void>();

// Recover Password
const initializePasswordRecovery = (email: string) => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(passwordRecoveryAsync.request(email));
    const auth = getAuth();

    await sendPasswordResetEmail(auth, email)
      .then(() => dispatch(passwordRecoveryAsync.success()))
      .catch((error)=> {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        Toast.show({
          duration: 5000,
          text: error && error.message ? error.message : 'There was an error sending the recovery email. Restart the app and try again.',
          type: 'danger'
        });
        dispatch(passwordRecoveryAsync.failure());
      });
  };
};

const passwordRecoveryAsync = createAsyncAction(
  'INITIALIZE_PASSWORD_RECOVERY',
  'PASSWORD_RECOVERY_SUCCESSFUL',
  'PASSWORD_RECOVERY_FAILED'
)<string, void, void>();

// Submit Answer

const submitAnswer = ({answer, share, courseId, chapterId, pageId, answerId, topicId, topic, final = false} : AnswerObject): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState}): Promise<void> => {
    dispatch(submitAnswerAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const db = getFirestore(firebaseApp);
    let userAnswerDBRef;

    if (answerId) {
      userAnswerDBRef = doc(db, 'users', userId, 'answers', answerId);
    } else {
      userAnswerDBRef = collection(db, 'users', userId, 'answers');
    }

    const data: AnswerObject = {
      answer,
      share,
      courseId,
      chapterId,
      pageId,
      timeStamp: Timestamp.now(),
      responseCount: 0,
    };
    try {
      let record;
      if (answerId) {
        await updateDoc(userAnswerDBRef, {
          answer,
          share,
          updateTimeStamp: Timestamp.now(),
        });
      } else {
        record = await addDoc(userAnswerDBRef, data);
      }

      data.answerId = answerId ? answerId : record.id;
      let completed = false;

      if (final) {
        const userProgressDbRef = doc(db, 'users', userId, 'courseProgress', courseId);
        const { userData, coursesData } = getState();
        const updatedChaptersCompleted = (userData.progress.find(course => course.courseId === courseId)?.chaptersCompleted?.length ?? 0) + 1;
        const totalChapters = coursesData.courses.find(course => course.id === courseId)?.chapters?.length ?? 0;
        completed = updatedChaptersCompleted === totalChapters;

        await updateDoc(userProgressDbRef, {
          chaptersCompleted: arrayUnion(chapterId),
          completed
        });
      }

      data.topic = topic;
      data.topicId = topicId;
      Toast.show({
        duration: 5000,
        text: 'Answer uploaded successfully.',
        type: 'success'
      });
      dispatch(submitAnswerAsync.success({data, new: !answerId, final, completed }));
    } catch (error) {
      Toast.show({
        duration: 5000,
        text: error && error.message ? error.message : 'There was an error adding the answer.',
        type: 'danger'
      });
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(submitAnswerAsync.failure())
    }
  }

};

const submitAnswerAsync = createAsyncAction(
  'SUBMIT_ANSWER',
  'SUBMIT_ANSWER_SUCCESSFUL',
  'SUBMIT_ANSWER_FAILED'
)<void, { data: AnswerObject, new: boolean, final: boolean, completed: boolean }, void>();

const updateNavigationHistory = createAction(
  'EMAIL_CHANGED',
  (currentRoute: string, prevRoute: string) => ({
    currentRoute,
    prevRoute,
    timeStamp: new Date()
  }),
)();

const fetchUserTopicAnswerCount = (courseId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchUserTopicAnswerCountAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const db = getFirestore(firebaseApp);
    const chapterForumQuery = query(collection(db, 'courses', courseId, 'forum'), where('creatorId', '==', userId));

    getDocs(chapterForumQuery)
      .then(snapShot => {
        const answerCountForTopics: {id: string, answerCount: number}[] = [];
        if(snapShot.docs.length > 0){
          if(snapShot.docs.length > 0){
            snapShot.forEach(forumTopic => {
              const { answerCount } = forumTopic.data();
              answerCountForTopics.push({
                answerCount,
                id: forumTopic.id
              })
            });
          }
        }

        dispatch(fetchUserTopicAnswerCountAsync.success(answerCountForTopics));
      })
      .catch((error) => {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchUserTopicAnswerCountAsync.failure())
      });
  }
};

const fetchUserTopicAnswerCountAsync = createAsyncAction(
  'INIT_FETCH_USER_FORUM_TOPICS_ANSWER_COUNT',
  'FETCH_USER_FORUM_TOPICS_ANSWER_COUNT_SUCCESS',
  'FETCH_USER_FORUM_TOPICS_ANSWER_COUNT_FAILURE'
)<void, Array<{id: string, answerCount: number}>, void>();

const initPrivateChat = (otherUserId: string, otherUserName: string, otherUserCommentId: string, originalComment: string, response: string): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(initPrivateChatAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const db = getFirestore(firebaseApp);
    try {
      const timeStamp = Timestamp.now();
      const { userData } = getState();
      const docBDocRef = collection(db, 'privateChats');

      const newChatroomRef = await addDoc(docBDocRef, {
        users: [otherUserId, userId],
        originalPostId: otherUserCommentId,
        originalComment,
        originalCommenter: otherUserName,
        originalCommenterId: otherUserId,
        otherParticipantName: `${userData?.firstName} ${userData?.lastName}`,
        startDate: timeStamp,
        msgCount: 2,
      });
      const chatId = newChatroomRef.id;
      const chatMsgDbRef = collection(db, 'privateChats', chatId, 'messages');

      const messageDbRef = await addDoc(chatMsgDbRef, {
        msg: response,
        timeStamp,
        userId
      });


      dispatch(initPrivateChatAsync.success({
        chatStarter: `${userData.firstName} ${userData.lastName}`,
        chatRoomId: newChatroomRef.id,
        messages: [{id: messageDbRef.id, msg: response, timeStamp: timeStamp.toDate(), userId}],
        msgCount: 2,
        originalComment,
        originalCommenter: otherUserName,
        originalCommenterId: otherUserId,
        otherParticipantName: `${userData?.firstName} ${userData?.lastName}`,
        otherUserId,
        startDate: timeStamp.toDate()
      }));
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(initPrivateChatAsync.failure())
    }
  };
};

const initPrivateChatAsync = createAsyncAction(
  'INIT_PRIVATE_CHAT',
  'INIT_PRIVATE_CHAT_SUCCESSFUL',
  'INIT_PRIVATE_CHAT_FAILED'
)<void, PrivateChat, void>();


const submitPrivateChatResponse = (chatRoomId: string, msg: string): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(submitPrivateChatResponseAsync.request());

    try {
      const userId = getAuth()?.currentUser?.uid;
      const timeStamp = Timestamp.now();
      const db = getFirestore(firebaseApp);

      const privateChatDbRef: any = collection(db, 'privateChats', chatRoomId, 'messages');
      const data: ChatMsg = {
        userId,
        timeStamp,
        msg,
      };

      const chatData = await addDoc(privateChatDbRef, data)
      dispatch(submitPrivateChatResponseAsync.success());
      // now handled by real time data listener
      // dispatch(submitPrivateChatResponseAsync.success({
      //   msg,
      //   timeStamp: timeStamp.toDate(),
      //   chatRoomId,
      //   id: chatData.id,
      //   userId
      // }));
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(submitPrivateChatResponseAsync.failure())
    }
  };
};

const submitPrivateChatResponseAsync = createAsyncAction(
  'INIT_SUBMIT_PRIVATE_CHAT_RESPONSE',
  'SUBMIT_PRIVATE_CHAT_RESPONSE_SUCCESSFUL',
  'SUBMIT_PRIVATE_CHAT_RESPONSE_FAILED'
)<void, void, void>();

// Recache Private Chat MetaData
const recachePrivateChatMetadata = (): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    const userId = getAuth()?.currentUser?.uid;

    if (userId) {
      dispatch(recachePrivateChatMetadataAsync.request());
      const db = getFirestore(firebaseApp);
      const privateChatsData: Array<PrivateChat> = [];
      const privateChatsQuery = query(collection(db, 'users', userId, 'privateChats'));

      try {
        const privateChatsSnapshot = await getDocs(privateChatsQuery);
        if (privateChatsSnapshot) {
          if (!privateChatsSnapshot.empty) {
            privateChatsSnapshot.forEach((record: any) => privateChatsData.push({...record.data(), id: record.id, startDate: record.data().startDate.toDate()}));
          }
          dispatch(recachePrivateChatMetadataAsync.success({privateChatsData}));
        } else {
          dispatch(recachePrivateChatMetadataAsync.failure())
        }
      } catch (error){
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(recachePrivateChatMetadataAsync.failure())
      }

    }
  };
};

const recachePrivateChatMetadataAsync = createAsyncAction(
  'INITIALIZE_PRIVATE_CHAT_METADATA_RECACHE',
  'PRIVATE_CHAT_RECACHE_METADATA_SUCCESSFUL',
  'PRIVATE_CHAT_RECACHE_METADATA_FAILED'
)<void, {privateChatsData: Array<PrivateChat>}, void>();

// Re-cache Private Chats
export let unsubscribeFromChatroom;

const addPrivateChatMsg = createAction('ADD_PRIVATE_CHAT')<{ chatRoomId: string, msg: ChatMsg }>();
const updatePrivateChatMsg = createAction('UPDATE_PRIVATE_CHAT')<{ chatRoomId: string, msg: ChatMsg }>();
const deletePrivateChatMsg = createAction('DELETE_PRIVATE_CHAT')<{ chatRoomId: string, msgId: string }>();

const recachePrivateChatMessagesAndListenForUpdates = (chatRoomId: string): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    const userId = getAuth()?.currentUser?.uid;

    if (userId) {
      dispatch(recachePrivateChatMessagesAndListenForUpdatesAsync.request());
      const initialChatMessages: Array<ChatMsg> = [];
      const db = getFirestore(firebaseApp);
      try {
        const privateChatsQuery = query(collection(db, 'privateChats', chatRoomId, 'messages'), orderBy('timeStamp', 'asc'));
        let initialized = false;
        unsubscribeFromChatroom = onSnapshot(privateChatsQuery, (snapshot) => {
          snapshot.docChanges().forEach((change: DocumentChange<ChatMsg>) => {
            if (change.type === 'added') {
              const data: ChatMsg = {
                ...change.doc.data(),
                id: change.doc.id,
                timeStamp: change.doc.data().timeStamp?.toDate(),
                updateTimeStamp: change.doc.data().updateTimeStamp?.toDate(),
              };

              if (!initialized) {
                initialChatMessages.push(data);
              } else {
                dispatch(addPrivateChatMsg({
                  chatRoomId,
                  msg: data
                }));
              }
            }
            if (change.type === 'modified') {
              dispatch(updatePrivateChatMsg({
                chatRoomId,
                msg: {
                  ...change.doc.data(),
                  id: change.doc.id,
                  timeStamp: change.doc.data().timeStamp?.toDate(),
                  updateTimeStamp: change.doc.data().updateTimeStamp?.toDate()
                }
              }));
            }
            if (change.type === 'removed') {
              dispatch(deletePrivateChatMsg({
                chatRoomId,
                msgId: change.doc.id,
              }));
            }
          });

          if (!initialized) {
            initialized = true;
            dispatch(recachePrivateChatMessagesAndListenForUpdatesAsync.success({ chatMessages: initialChatMessages, chatRoomId }));
          }
        });
      } catch (error){
        dispatch(recachePrivateChatMessagesAndListenForUpdatesAsync.failure());
      }
    }
  };
};

const recachePrivateChatMessagesAndListenForUpdatesAsync = createAsyncAction(
  'INITIALIZE_PRIVATE_CHAT_RECACHE_AND_LISTEN_',
  'PRIVATE_CHAT_RECACHE_AND_LISTEN_SUCCESSFUL',
  'PRIVATE_CHAT_RECACHE_AND_LISTEN__FAILED'
)<void, {chatMessages: Array<ChatMsg>, chatRoomId: string}, void>();

const editPrivateChatMessage = (chatRoomId: string, msgId: string, msg: string): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(editPrivateChatMsgAsync.request());
    try {
      const db = getFirestore(firebaseApp);
      const privateChatDbRef = doc(db, 'privateChats', chatRoomId, 'messages', msgId);

      await updateDoc(privateChatDbRef, {
        msg,
        updateTimeStamp: Timestamp.now(),
      });

      dispatch(editPrivateChatMsgAsync.success({ chatRoomId, msgId, msg }));
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(editPrivateChatMsgAsync.failure())
    }
  };
};

const editPrivateChatMsgAsync = createAsyncAction(
  'INIT_EDIT_PRIVATE_CHAT_MSG',
  'EDIT_PRIVATE_CHAT_MSG_SUCCESSFUL',
  'EDIT_PRIVATE_CHAT_MSG_FAILED'
)<void, {chatRoomId: string, msgId: string, msg: string }, void>();

const deletePrivateChatMessage = (chatRoomId: string, msgId: string): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(deletePrivateChatMsgAsync.request());

    const db = getFirestore(firebaseApp);
    const privateChatDbRef = doc(db, 'privateChats', chatRoomId, 'messages', msgId);
    try {
      await deleteDoc(privateChatDbRef);
      dispatch(deletePrivateChatMsgAsync.success({ chatRoomId, msgId }));
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(deletePrivateChatMsgAsync.failure())
    }
  };
};

const deletePrivateChatMsgAsync = createAsyncAction(
  'INIT_DELETE_PRIVATE_CHAT_MSG',
  'DELETE_PRIVATE_CHAT_MSG_SUCCESSFUL',
  'DELETE_PRIVATE_CHAT_MSG_FAILED'
)<void, {chatRoomId: string, msgId: string }, void>();


// Fetch Notifications
const fetchNotifications = (fetchAllUnread: boolean, timestamp: Timestamp = null ): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    const userId = getAuth()?.currentUser?.uid;
    const defaultDate = new Date();
    defaultDate.setFullYear(2018);
    const defaultTimeStamp = Timestamp.fromDate(defaultDate);
    const queryTimestamp = fetchAllUnread ? defaultTimeStamp : timestamp;

    if (userId) {
      dispatch(recacheAnswersAsync.request());
      const notifications: Array<Notification> = [];
      const db = getFirestore(firebaseApp);
      const notificationsQuery = query(collection(db, 'users', userId, 'notifications'),
        where('received', '==', false), where('timestamp', '>', queryTimestamp || defaultTimeStamp),
        orderBy('timestamp', 'desc'));

      try {
        const notificationsSnapshot = await getDocs(notificationsQuery);
        if (notificationsSnapshot) {
          if (!notificationsSnapshot.empty) {
            notificationsSnapshot.forEach((record: any) => notifications.push({
              ...record.data(),
              id: record.id,
              timestamp: record.data().timestamp.toDate()
            }));
          }

          if (!fetchAllUnread) {
            const newTimestamp = Timestamp.now();
            await AsyncStorage.setItem('@TheologicalAcademy:NotificationFetchTimestamp', JSON.stringify(newTimestamp.toDate()));
          }

          dispatch(fetchNotificationsAsync.success(notifications))
        } else {
          dispatch(fetchNotificationsAsync.failure())
        }
      } catch (error) {
        (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
        dispatch(fetchNotificationsAsync.failure())
      }
    }
  };
};

const fetchNotificationsAsync = createAsyncAction(
  'INIT_FETCH_NOTIFICATIONS',
  'FETCH_NOTIFICATIONS_SUCCESSFUL',
  'FETCH_NOTIFICATIONS_FAILED'
)<void, Array<Notification>, void>();


// Update Notification Received Status
const updateNotificationReceivedStatus = (notificationIds: string[], status = true ): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(updateNotificationReceivedStatusAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const db = getFirestore(firebaseApp);
    const batch = writeBatch(db);

    try {
      notificationIds.forEach(id => {
        const notificationDBRef = doc(db, 'users', userId, 'notifications', id);
        batch.update(notificationDBRef, {
          received: status
        });
      });


      await batch.commit();

      Toast.show({
        duration: 5000,
        text: 'Notifications successfully marked read.',
        type: 'success'
      });
      dispatch(updateNotificationReceivedStatusAsync.success(notificationIds));
    } catch(error) {
      Toast.show({
        duration: 5000,
        text: error && error.message ? error.message : 'There was an error marking notifications as read.',
        type: 'danger'
      });

      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(updateNotificationReceivedStatusAsync.failure());
    }
  };
};

const updateNotificationReceivedStatusAsync = createAsyncAction(
  'INIT_UPDATE_NOTIFICATION_RECEIVED_STATUS',
  'UPDATE_NOTIFICATION_RECEIVED_STATUS_SUCCESSFUL',
  'UPDATE_NOTIFICATION_RECEIVED_STATUS_FAILED'
)<void, string[], void>();

const sendMsgToAuthor = (type: string, msg: string): any => {
  return async(dispatch: Dispatch, getState: () => {coursesData: CoursesReducerState, signUpData: SignUpReducerState, userData: UserReducerState} ): Promise<void> => {
    dispatch(sendMsgToAuthorAsync.request());
    const userId = getAuth()?.currentUser?.uid;
    const { userData } = getState();
    const { firstName, lastName, email } = userData;
    try {
      const db = getFirestore(firebaseApp);

      const dBRef = collection(db, 'msgToAuthor');

      const data: any = {
        lastName,
        firstName,
        userId,
        email,
        type,
        msg,
        timeStamp: Timestamp.now(),
      };

      await addDoc(dBRef, data);
      dispatch(sendMsgToAuthorAsync.success());
    } catch (error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(sendMsgToAuthorAsync.failure())
    }
  };
};

const sendMsgToAuthorAsync = createAsyncAction(
  'SEND_MSG_TO_AUTHOR',
  'SEND_MSG_TO_AUTHOR_SUCCESSFUL',
  'SEND_MSG_TO_AUTHOR_FAILED'
)<void, void, void>();

const updateProfileImage = (imageUri: string): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(updateProfileImageAsync.request());

    try {
      const storage = getStorage();
      const userId = getAuth()?.currentUser?.uid;
      const storageRef = ref(storage, `profileImages/${userId}`);
      const localPhoto = await fetch(imageUri);
      const imgBlob = await localPhoto.blob();

      const snapshot = await uploadBytes(storageRef, imgBlob);
      const storageLocation = snapshot.ref;
      const profilePhotoUrl = await getDownloadURL(storageLocation);

      const db = getFirestore(firebaseApp);
      const userDbRef = doc(db, 'users', userId, 'public', 'profile');
      await updateDoc(userDbRef, {
        profilePhotoUrl,
      });

      dispatch(updateProfileImageAsync.success(profilePhotoUrl));

      Toast.show({
        duration: 5000,
        text: 'Profile photo uploaded successfully.',
        type: 'success'
      });
    } catch(error) {
      Toast.show({
        duration: 5000,
        text: error && error.message ? error.message : 'There was an error uploading the image.',
        type: 'danger'
      });

      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(updateProfileImageAsync.failure());
    }
  };
};

const updateProfileImageAsync = createAsyncAction(
  'UPDATE_PROFILE_IMAGE_ASYNC',
  'UPDATE_PROFILE_IMAGE_ASYNC_SUCCESSFUL',
  'UPDATE_PROFILE_IMAGE_ASYNC_FAILED'
)<void, string, void>();

const updateProfileInfo = (aboutMe: string, testimony: string, enrollmentReason: string): any => {
  return async(dispatch: Dispatch): Promise<void> => {
    dispatch(updateProfileInfoAsync.request());

    try {
      const userId = getAuth()?.currentUser?.uid;
      const db = getFirestore(firebaseApp);
      const userProfileDbRef = doc(db, 'users', userId, 'public', 'profile');

      await updateDoc(userProfileDbRef, {
        aboutMe,
        testimony,
        enrollmentReason,
      });

      dispatch(updateProfileInfoAsync.success({ aboutMe, testimony, enrollmentReason }));

      Toast.show({
        duration: 5000,
        text: 'Profile uploaded successfully.',
        type: 'success'
      });
    } catch(error) {
      Toast.show({
        duration: 5000,
        text: error && error.message ? error.message : 'There was an error updating your profile.',
        type: 'danger'
      });

      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(updateProfileInfoAsync.failure());
    }
  };
};

const updateProfileInfoAsync = createAsyncAction(
  'UPDATE_PROFILE_INFO_ASYNC',
  'UPDATE_PROFILE_INFO_ASYNC_SUCCESSFUL',
  'UPDATE_PROFILE_INFO_ASYNC_FAILED'
)<void, { aboutMe: string, testimony: string, enrollmentReason: string }, void>();

export {
  recacheUserDataAsync,
  recacheUserData,
  adjustTextToSpeechPitch,
  adjustTextToSpeechRate,
  adjustTextToSpeechVoice,
  adjustFontSize,
  recacheUserAnswers,
  recacheAnswersAsync,
  submitAnswer,
  initializeLogin,
  initializePasswordRecovery,
  loginAsync,
  restoreUserSession,
  passwordRecoveryAsync,
  submitAnswerAsync,
  updateNavigationHistory,
  initializeLogout,
  logOutAsync,
  fetchUserTopicAnswerCountAsync,
  fetchUserTopicAnswerCount,
  initPrivateChat,
  initPrivateChatAsync,
  submitPrivateChatResponse,
  submitPrivateChatResponseAsync,
  recachePrivateChatMessagesAndListenForUpdates,
  recachePrivateChatMessagesAndListenForUpdatesAsync,
  editPrivateChatMessage,
  editPrivateChatMsgAsync,
  deletePrivateChatMessage,
  deletePrivateChatMsgAsync,
  recachePrivateChatMetadata,
  recachePrivateChatMetadataAsync,
  fetchNotifications,
  fetchNotificationsAsync,
  updateNotificationReceivedStatus,
  updateNotificationReceivedStatusAsync,
  sendMsgToAuthor,
  sendMsgToAuthorAsync,
  deleteAccountAsync,
  initializeAccountDeletion,
  needsToRegister,
  toggleTextToSpeechAutoContinue,
  updateProfileImageAsync,
  updateProfileImage,
  updateProfileInfo,
  updateProfileInfoAsync,
  addPrivateChatMsg,
  updatePrivateChatMsg,
  deletePrivateChatMsg,
  addPushToken,
  addPushTokenAsync,
  removePushToken,
  removePushTokenAsync,
  fetchOtherUserDataAsync,
  fetchOtherUserData,
  setInitialNotification,
  resetInitialNotification,
}

