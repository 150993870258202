import { View, Text, Content, List, ListItem, Spinner, Fab, Icon, Form } from "native-base";
import React, { useContext, useState } from 'react';
import {  Platform, StyleSheet } from 'react-native';
import { InputModal } from '../../../../../../common/components/input-modal-component';
import { AnimatedInput } from '../../../../../../common/components/input.component';
import { SubmitButton } from '../../../../../../common/components/submit-button';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../../../../../common/global.styles';
import { ForumChapterQuestion, ForumTopic } from '../../../../../../models/course';
import CourseContext, { CourseContextProps } from '../../../../course.context';
import { ForumQuestionRow } from '../forum-question-row.component';
import { ForumState } from '../forum-tab/forum-tab.component';
import { contentStyles } from '../../../../../content/content.styles';


type Props = {
    chapterQuestions: Array<ForumChapterQuestion>,
    forumTopics: Array<ForumTopic>,
    forumState: ForumState;
    setForumState: ({expandedSectionId, isLoading, forumOpened, openedForumPageId,
                        openedForumTopicId, openChatRoom, openChatRoomId, response}: ForumState) => void;
    fetchingForumAnswersSuccess: boolean | null;
};

export const ForumList: React.FunctionComponent<Props> =
    ({ chapterQuestions, forumTopics, setForumState, forumState, fetchingForumAnswersSuccess }: Props ) => {

    const [newForumState, setNewForumState] = useState({
        displayModal: false,
        topic: '',
        answer: ''
    });

    const { handleNewForum, goToForumAnswersPage } = useContext<CourseContextProps>(CourseContext);

    const expandTopicAnswers = (id: string): void => {
      goToForumAnswersPage(id, false);
    };

    const renderNewForumModal = () => {
        const { displayModal, topic, answer } = newForumState;
        return (
            <InputModal displayModal={displayModal} >
                <Form>
                    <AnimatedInput
                        value={topic}
                        valid={topic.length > 3}
                        errMsg='The topic needs to be longer'
                        label='What will this forum be about?'
                        textArea = {true}
                        onChange={(value) => setNewForumState({...newForumState, topic: value})}
                    />
                    <AnimatedInput
                        value = {answer}
                        valid = {answer.length > 100}
                        errMsg='The answer needs to be at least 100 characters'
                        label='Share thoughts on this topic...'
                        textArea = {true}
                        numberOfLines={8}
                        onChange={(value) => setNewForumState({...newForumState, answer: value})}
                    />
                </Form>
                <View style={{marginTop: 16}}>
                    <SubmitButton
                      loading={false}
                      disabled={topic.length < 2 || answer.length < 100}
                      onPress={() => {
                          handleNewForum({ topic, answer });
                          setNewForumState({answer: '', topic: '', displayModal: false});
                      }}
                      text='create'
                    />
                    <SubmitButton
                        text='cancel'
                        color={PRIMARY_COLOR_ACCENT}
                        onPress={() => setNewForumState({answer: '', topic: '', displayModal: false})}
                    />
                </View>
            </InputModal>
        )
    };

    const renderChapterQuestions = (): JSX.Element | null => {
        if (chapterQuestions.length === 0) {
            return null;
        }

        const chapterQuestionList = chapterQuestions.map((topic, index) => {
           return (
               <ForumQuestionRow
                   topic={topic}
                   index={index}
                   chapterQuestions={chapterQuestions}
                   fetchingForumAnswersSuccess={fetchingForumAnswersSuccess}
                   setForumState={setForumState}
                   forumState={forumState}
                   key={index}
               />
           )
        });

        return (
            <List>
                <ListItem itemDivider style={styles.listItem}>
                    <Text style={{fontWeight: 'bold'}}>Chapter Questions</Text>
                </ListItem>
                {chapterQuestionList}
            </List>
        )
    };

    const renderForumTopics = (): JSX.Element | null => {
        if (!forumTopics?.length) {
            return null;
        }
        const forumTopicList = forumTopics.map(topic => {
            return (
                <ListItem noIndent
                          key={topic.id}
                          onPress={() => expandTopicAnswers(topic.id)}
                          style={{backgroundColor: '#ffe57f'}}
                >
                    { !forumState.isLoading ?
                        <Text>{topic.topic}</Text> :
                        <Spinner size='small'/> }
                </ListItem>
            )
        });

        return (
            <List>
                <ListItem itemDivider style={styles.listItem}>
                    <Text style={{fontWeight: 'bold'}}>Other Topics</Text>
                </ListItem>
                {forumTopicList}
            </List>
        )
    };

    const renderNoTopicsMsg = (): JSX.Element | null => {
            if(forumTopics.length === 0 || chapterQuestions.length === 0) {
                return (
                    <Content style={styles.noTopicsContainer}>
                        <Text style={ contentStyles.text }>No Topics Found</Text>
                    </Content>
                )
            }

            return null;
        };

    return(
        <View style={{flex: 1,}}>
            <Content style={[styles.content, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
                {renderChapterQuestions()}
                {renderForumTopics()}
                {renderNoTopicsMsg()}
              <View style={{ height: 48 }}/>
            </Content>
            <Fab
                style={{backgroundColor: ACTION_COLOR}}
                position='bottomRight'
                onPress={() => setNewForumState({...newForumState, displayModal: true})}
            >
                <Icon name='add'
                      style={styles.fabIcon}
                />
            </Fab>
            {renderNewForumModal()}
        </View>
    )
};

const styles = StyleSheet.create({
    content: {
        padding: 16,
        paddingBottom: 32,
        backgroundColor: '#5f5f5f'
    },
    listItem: {
        backgroundColor: '#FFC400'
    },
    fabIcon: Platform.select({
        ios: {
            fontSize: 50, paddingTop: 28
        },
        android: {
            fontSize: 34
        }
    }),
    noTopicsContainer: {
        padding: 16,
        paddingBottom: 32,
        backgroundColor: '#5f5f5f'
    }
});