import React, { FC, useEffect, useState } from 'react';
import { KeyboardAvoidingView, LayoutAnimation, Platform, Text, View } from 'react-native';
import { Accordion, Body, Button, CardItem, Content, Fab, H1, Icon, List, ListItem, Right } from 'native-base';
import { ForumTopics } from '../../../models/user';
import { ACTION_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../common/global.styles';
import { Course } from '../../../models/course';
import { NavigationScreenProp } from "react-navigation";
import { useDispatch } from 'react-redux';
import { fetchCourseForumApi } from '../../../redux/actions';

interface SortedForumTopics {
  id: string;
  title: string;
  topics: ForumTopics[]
}

type Props = {
  topics: ReadonlyArray<Readonly<ForumTopics>>;
  courses: ReadonlyArray<Readonly<Course>>,
  fetchAnswerCounts: (courseId: string) => void;
  navigation: NavigationScreenProp<any, any>;
}

export const TopicsTab: FC<Props> = ({ topics, courses, fetchAnswerCounts, navigation }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const uniqueChapterIds = Array.from(new Set(topics.map(topic => topic.courseId)));
  const topicsSortedByChapter: SortedForumTopics[] = uniqueChapterIds.map(id => {
    return {
      id,
      title: courses.find(course => course.id === id).title,
      topics: topics.filter(record => record.courseId === id),
    }
  });

  function renderTopicsHeader(item: SortedForumTopics, expanded: boolean): JSX.Element {
    return (
      <View style={[{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        padding: 8,
        alignItems: 'center' ,
        backgroundColor: '#FFC400',
      }, expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null]}>
        <View style={{flex: .90}}>
          { expanded ? null : <Text style={{ fontWeight: expanded ? '600' : '400', fontSize: 16, paddingLeft: 8, paddingRight: 16, textAlign: 'left', lineHeight: 20 }}>{item.topics.length} Posted Topics in:</Text>}
          <Text style={{ fontWeight: expanded ? '800' : '600', fontSize: 16, paddingLeft: 8, paddingRight: 16, textAlign: 'left', lineHeight: 20 }}>"{item.title}"</Text>
        </View>
        <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
          {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} style={{ fontSize: 20, color: ACTION_COLOR }} />
            : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} style={{ fontSize: 20, color: ACTION_COLOR }} />
          }
        </View>
      </View>
    )
  }

  function renderTopicsContent (item: SortedForumTopics): JSX.Element {
    return (
      <View style={{ backgroundColor: '#ffe57f'}}>
        <List>
          {item.topics.map((record: ForumTopics) => {
            if(record) {
              return (
                <ListItem
                  onPress={navigation.navigate.bind(this, 'Answers', { topicId: record.topicId, courseId: record.courseId, fromRootForumScreen: true })}
                  key={record.topicId}>
                  <Body>
                    <Text style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>"{record.topic}"</Text>
                    <Text style={{ fontSize: 16, textAlign: 'center' }}>{record.answerCount || 0} Answers</Text>
                  </Body>
                  <Right>
                    {record.answerCount && record.answerCount > 0 ? <Icon name="arrow-forward" style={{color: ACTION_COLOR}} />: null}
                  </Right>
                </ListItem>
              )
            }
          })}
        </List>
      </View>
    )
  }

  if (!topicsSortedByChapter?.length) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#474646'}}>
        <H1 style={{ color: PRIMARY_COLOR, padding: 24, textAlign: 'center'}}>{`You have not created a course discussion topic.\n\nYou can start a new discussion by creating a topic on a course forum.`}</H1>
      </View>
    );
  }

  return (
    <View style={[{flex: 1, margin: 0, padding: 16, backgroundColor: '#474646', alignItems: 'center'}]}>
      <CardItem style={[{ backgroundColor: '#5f5f5f', marginBottom: -10 }, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
        <Accordion
          dataArray={topicsSortedByChapter}
          renderHeader={renderTopicsHeader}
          renderContent={renderTopicsContent}
          expanded={[]}
          onAccordionOpen={(item: SortedForumTopics) => {
            dispatch(fetchCourseForumApi(item.id));
            fetchAnswerCounts(item.id)
          }}
        />
      </CardItem>
    </View>
  )
};
