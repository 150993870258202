import { IMessage } from 'react-native-gifted-chat';
import { Timestamp } from 'firebase/firestore';

export class Course {

    constructor(
        public id: string = '',
        public title: string = '',
        public subtitle: string = '',
        public description: string = '',
        public numberOfEnrollments: number = 0,
        public likes: number  = 0,
        public category: string  = '',
        public publisherEmail: string = '',
        public publisherFirstName: string = '',
        public publisherLastName: string = '',
        public mainCategory: string = '',
        public published: Date = new Date(),
        public questionsCount: number = 0,
        public tags: Array<string> = [],
        public imageUrl: string = '',
        public updates: Array<Update> = [],
        public chapters: Array<Chapter> = [],
        public forumTopics: Array<ForumTopic> = [],
        public forumChQuestionTopics: Array<ForumChapterQuestion> = [],
    ) {
    }
}

export interface Update {
    id: string;
    date: Date;
    description: string;
    updaterFirstName: string;
    updaterLastName: string;
}

export interface Chapter {
    id: number;
    title: string;
    subtitle: string;
    bibleQuote: Array<string>;
    bookQuote: string;
    estimatedTime: number;
    completionCount: number;
    likes: number;
    sections: Array<string | { id: string, title: string}>;
    sectionsData: Array<Section>;
    questionsCount: number;
}

export interface Section {
    title: string;
    id: string;
    likes: number;
    pages: [
        {
            id: string;
            question: string;
            note: string;
            content: string;
            pageNumber: number;
            sectionIndex: number;
            userAnswer?: string;
            answerId?: string;
            shareAnswer?: boolean;
        }
    ]
}

export interface ForumTopic {
    id: string;
    topic: string;
    answers?: Array<ForumAnswers >
}

export interface ForumChapterQuestion extends ForumTopic{
    questions: Array<ForumQuestion>,
}

export interface ForumQuestion {
    pageId: string;
    pageNumber: number;
    question: string;
    sectionIndex: number;
}

export interface BaseForumAnswer {
    answer: string;
    firstName: string;
    lastName: string;
    userId: string;  
    timeStamp: Timestamp;
    updateTimeStamp?: Timestamp;
    responseCount?: number;
    profilePhotoUrl?: string;
}

export interface ForumAnswers extends BaseForumAnswer {
    pageId?: string;
    chapterId?: number;
    courseId?: string;
    id?: string;
    responses?: Array<ForumResponse | IMessage>;
}

export interface ForumResponse {
    response: string;
    firstName: string;
    lastName: string;
    userId: string;
    profilePhotoUrl?: string;
    timeStamp: Timestamp;
    answerId?: string;
    responseId?: string;
    id?: string;
}

export interface ForumResponseData {
    forumTopicId: string;
    responseId: string;
    answerId: string;
    response?: string;
    courseId?: string | undefined;
    topic?: string;
    answer?: string;
}

export interface UserForumAnswer {
    courseId: string,
    topicId: string,
    topic: string,
    answer: string,
    answerId: string,
    pageId?: string,
}

export interface UserForumResponse {
    courseId: string,
    topicId: string,
    answerId: string,
    response: string,
    answer: string,
    topic: string,
    responseId: string;
    pageId?: string
}

