import { ActionType,  getType } from 'typesafe-actions';
import { AnswerObject, CourseProgress, ForumTopics } from '../../models/user';
import {
  signUpSuccess,
  adjustTextToSpeechPitch,
  adjustTextToSpeechRate,
  adjustTextToSpeechVoice,
  loginAsync,
  passwordRecoveryAsync,
  recacheAnswersAsync,
  restoreUserSession,
  submitAnswerAsync,
  updateNavigationHistory,
  adjustFontSize,
  recacheUserDataAsync,
  initForumTopicAsync,
  submitForumAnswerAsync,
  submitForumResponseAsync,
  logOutAsync,
  fetchUserTopicAnswerCountAsync,
  initPrivateChatAsync,
  fetchOtherUserDataAsync,
  submitPrivateChatResponseAsync,
  recachePrivateChatMessagesAndListenForUpdatesAsync,
  addPrivateChatMsg,
  updatePrivateChatMsg,
  deletePrivateChatMsg,
  editPrivateChatMsgAsync,
  deletePrivateChatMsgAsync,
  recachePrivateChatMetadataAsync,
  fetchNotificationsAsync,
  sendMsgToAuthorAsync,
  deleteAccountAsync,
  needsToRegister,
  toggleTextToSpeechAutoContinue,
  updateProfileImageAsync,
  updateProfileInfoAsync,
  addPushTokenAsync,
  removePushTokenAsync,
  updateNotificationReceivedStatusAsync, setInitialNotification, resetInitialNotification,
} from '../actions/';

import { TextToSpeechOptions } from '../../common/services/voice.service';
import { Platform } from 'react-native';
import { UserForumAnswer, UserForumResponse } from '../../models/course';
import { PrivateChat } from '../../models/private-chat';
import { Notification } from '../../models/notification';
import {  uniq } from 'lodash';

export interface PersonalInfo {
  aboutMe: string;
  enrollmentReason: string;
  testimony: string;
  profilePhotoUrl: string;
  updatingProfile: boolean;
}
export interface OtherUserProfileCollection {
  allUserIds: string[];
  byUserId: { [otherUserId: string]: PersonalInfo };
}

export interface UserReducerState extends PersonalInfo {
  readonly id: string;
  readonly newUser: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly updatingProfile: boolean;
  readonly profilePhotoStorageLocation: string;
  readonly gender: string;
  readonly fontSize: number;
  readonly textToSpeechOptions: TextToSpeechOptions,
  readonly restoringUserSession: boolean;
  readonly userSessionRestored: boolean | null;
  readonly loginProcessing: boolean;
  readonly loginAttemptProcessing: boolean;
  readonly loggedIn: boolean| null;
  readonly persistingAuthentication: boolean;
  readonly persistAuthenticationSuccessful: boolean | null;
  readonly cachingUserDataProcessing: boolean;
  readonly userDataCached: boolean | null;
  readonly loginProcessCompletedSuccessfully: boolean | null;
  readonly passwordRecoveryProcessing: boolean;
  readonly passwordRecoverySuccessful: boolean | null;
  readonly loggingOut: boolean;
  readonly loggedOut: boolean | null;
  readonly navigationSessionHistory: ReadonlyArray<Readonly<{
  readonly currentRoute: string;
  readonly prevRoute: string;
  readonly timeStamp: Date;
  readonly }>>,
  readonly submittingAnswer: boolean;
  readonly submitAnswerSuccessful: boolean | null;
  readonly recachingAnswers: boolean;
  readonly recachingAnswersSuccess: boolean | null;
  readonly recachingUserData: boolean;
  readonly recachingUserDataSuccess: boolean | null;
  readonly fetchingUserTopicsAnswerCount: boolean;
  readonly fetchUserTopicsAnswerCountSuccess: boolean | null;
  readonly fetchingNotifications: boolean;
  readonly fetchNotificationsSuccess: boolean | null;
  readonly sendingMsgToAuthor: boolean;
  readonly sentMsgToAuthorSuccessfully: boolean | null;
  readonly answers: ReadonlyArray<Readonly<AnswerObject>>;
  readonly progress: ReadonlyArray<Readonly<CourseProgress>>
  readonly forumTopicsCreated: ReadonlyArray<Readonly<ForumTopics>>;
  readonly forumAnswers: ReadonlyArray<Readonly<UserForumAnswer>>;
  readonly forumResponses: ReadonlyArray<Readonly<UserForumResponse>>;
  readonly notifications: ReadonlyArray<Readonly<Notification>>
  readonly privateChats: ReadonlyArray<Readonly<PrivateChat>>
  readonly newChatRoomId: string;
  readonly authenticatedButNotRegistered: boolean;
  readonly otherUserProfiles: OtherUserProfileCollection;
  readonly expoTokens: string[];
  readonly currentExpoToken: string;
  readonly initialNotification: Notification;
}

export const USER_INITIAL_STATE: UserReducerState = {
  id: '',
  newUser: false,
  firstName: '',
  lastName: '',
  email: '',
  profilePhotoUrl: '',
  aboutMe: '',
  testimony: '',
  updatingProfile: false,
  profilePhotoStorageLocation: '',
  gender: '',
  enrollmentReason: '',
  fontSize: 20,
  textToSpeechOptions: {
    autoContinue: false,
    rate: Platform.OS === 'android' ? .974 : 1,
    pitch: Platform.OS === 'android' ? .8 : 1,
  },
  authenticatedButNotRegistered: false,
  restoringUserSession: false,
  userSessionRestored: null,
  loginProcessing: false,
  loginAttemptProcessing: false,
  loggedIn: null,
  persistingAuthentication: false,
  persistAuthenticationSuccessful: null,
  cachingUserDataProcessing: false,
  userDataCached: null,
  loginProcessCompletedSuccessfully: null,
  passwordRecoveryProcessing: false,
  passwordRecoverySuccessful: null,
  loggingOut: false,
  loggedOut: null,
  navigationSessionHistory: [],
  submittingAnswer: false,
  submitAnswerSuccessful: null,
  recachingAnswers: false,
  recachingAnswersSuccess: null,
  recachingUserData: false,
  recachingUserDataSuccess: null,
  fetchingUserTopicsAnswerCount: false,
  fetchUserTopicsAnswerCountSuccess: null,
  fetchingNotifications: false,
  fetchNotificationsSuccess: null,
  sendingMsgToAuthor: false,
  sentMsgToAuthorSuccessfully: null,
  answers: [],
  progress: [],
  forumTopicsCreated: [],
  forumAnswers: [],
  forumResponses: [],
  privateChats: [],
  newChatRoomId: '',
  notifications: [],
  expoTokens: [],
  currentExpoToken: null,
  otherUserProfiles: {
    allUserIds: [],
    byUserId: {},
  },
  initialNotification: undefined,
};

export type UserAction = ActionType<typeof passwordRecoveryAsync | typeof logOutAsync | typeof restoreUserSession | typeof loginAsync | typeof submitAnswerAsync |
  typeof restoreUserSession | typeof signUpSuccess | typeof updateNavigationHistory | typeof recacheAnswersAsync | typeof adjustTextToSpeechPitch
  | typeof adjustTextToSpeechRate | typeof adjustTextToSpeechVoice | typeof adjustFontSize | typeof recacheUserDataAsync | typeof initForumTopicAsync
  | typeof submitForumAnswerAsync | typeof submitForumResponseAsync | typeof fetchUserTopicAnswerCountAsync | typeof initPrivateChatAsync | typeof submitPrivateChatResponseAsync
  | typeof recachePrivateChatMessagesAndListenForUpdatesAsync | typeof addPrivateChatMsg | typeof updatePrivateChatMsg | typeof deletePrivateChatMsg | typeof editPrivateChatMsgAsync | typeof deletePrivateChatMsgAsync | typeof recachePrivateChatMetadataAsync
  |typeof updateProfileInfoAsync | typeof updateProfileImageAsync | typeof sendMsgToAuthorAsync | typeof deleteAccountAsync | typeof needsToRegister | typeof toggleTextToSpeechAutoContinue | typeof addPushTokenAsync | typeof removePushTokenAsync
  | typeof fetchNotificationsAsync | typeof fetchOtherUserDataAsync | typeof updateNotificationReceivedStatusAsync | typeof setInitialNotification | typeof resetInitialNotification>;


export default (state = USER_INITIAL_STATE, action: UserAction): UserReducerState => {
  switch (action.type){
    case getType(passwordRecoveryAsync.request):
      return {
        ...state,
        passwordRecoveryProcessing: true,
      };
    case getType(passwordRecoveryAsync.success):
      return {
        ...state,
        passwordRecoveryProcessing: false,
        passwordRecoverySuccessful: true
      };
    case getType(passwordRecoveryAsync.failure):
      return {
        ...state,
        passwordRecoveryProcessing: false,
        passwordRecoverySuccessful: false
      };
    // Persist User Session
    case getType(restoreUserSession):
      return {
        ...state,
        restoringUserSession: true,
        loggedIn: true,
        userSessionRestored: false
      };
    case getType(recacheUserDataAsync.request):
      return {
        ...state,
        recachingUserData: true,
        recachingUserDataSuccess: false
      };
    case getType(recacheUserDataAsync.success): {
      let { email, lastName, firstName, id, profilePhotoUrl, enrollmentReason, aboutMe, testimony } = action.payload;
      const {courseProgress, forumTopicsCreated, forumAnswers, forumResponses, privateChats} = action.payload;
      return {
        ...state,
        id,
        profilePhotoUrl,
        firstName,
        lastName,
        email,
        forumTopicsCreated,
        forumResponses,
        forumAnswers,
        privateChats,
        enrollmentReason,
        aboutMe,
        testimony,
        progress: courseProgress,
        recachingUserData: false,
        recachingUserDataSuccess: true,
      };
    }
    case getType(updateProfileInfoAsync.request): {
      return {
        ...state,
        updatingProfile: true,
      }
    }
    case getType(updateProfileInfoAsync.failure): {
      return {
        ...state,
        updatingProfile: false,
      }
    }
    case getType(updateProfileInfoAsync.success): {
      const {  enrollmentReason, aboutMe, testimony } = action.payload;
      return {
        ...state,
        enrollmentReason,
        aboutMe,
        testimony,
        updatingProfile: false,
      };
    }
    case getType(recacheUserDataAsync.failure):
      return {
        ...state,
        recachingUserData: false,
        recachingUserDataSuccess: false
      };
    case getType(initForumTopicAsync.success):
      return {
        ...state,
        forumTopicsCreated: [...state.forumTopicsCreated, {
          courseId: action.payload.courseId, topicId: action.payload.forumTopicId, topic: action.payload.topic
        }]
      };
    case getType(fetchUserTopicAnswerCountAsync.request):
      return {
        ...state,
        fetchingUserTopicsAnswerCount: true,
        fetchUserTopicsAnswerCountSuccess: false,
      };
    case getType(fetchUserTopicAnswerCountAsync.success):
      const forumTopicsCopy = [...state.forumTopicsCreated];
      const updatedForumTopics = forumTopicsCopy.map(topic => {
        const matchingRecord = action.payload.find(record => record.id === topic.topicId);
        return {...topic, answerCount: matchingRecord ? matchingRecord.answerCount : 0 }
      });

      return {
        ...state,
        forumTopicsCreated: updatedForumTopics,
        fetchingUserTopicsAnswerCount: false,
        fetchUserTopicsAnswerCountSuccess: true,
      };
    case getType(fetchUserTopicAnswerCountAsync.failure):
      return {
        ...state,
        fetchingUserTopicsAnswerCount: false,
        fetchUserTopicsAnswerCountSuccess: false,
      };
    case getType(submitForumAnswerAsync.success):
      return {
        ...state,
        forumAnswers: [...state.forumAnswers, {
          courseId: action.payload.courseId, topicId: action.payload.forumTopicId, topic: action.payload.topic, answer: action.payload.answer, answerId: action.payload.answerId
        }]
      };
    case getType(submitForumResponseAsync.success):
      return {
        ...state,
        forumResponses: [...state.forumResponses, {
          courseId: action.payload.courseId, topicId: action.payload.forumTopicId, topic: action.payload.topic,
          answerId: action.payload.answerId, response: action.payload.response, answer: action.payload.answer,
        }]
      };
    // case USER_SESSION_RESTORED_SUCCESS:
    // 	return {
    // 		...state,
    // 		restoringUserSession: false,
    // 		userSessionRestored: true
    // 	};
    // case USER_SESSION_RESTORED_FAILURE:
    // 	return {
    // 		...state,
    // 		restoringUserSession: false,
    // 		userSessionRestored: false
    // 	};
    // login
    case getType(addPushTokenAsync.success):
      const expoTokens = [...state.expoTokens];
      return {
        ...state,
        currentExpoToken: action.payload,
        expoTokens: [...expoTokens, action.payload],
      };
    case getType(removePushTokenAsync.success): 
      const expoTokensArray = [...state.expoTokens];
      return {
        ...state,
        expoTokens: expoTokensArray.filter(token => token !== state.currentExpoToken),
        currentExpoToken: '',
      };
    case getType(loginAsync.request):
      return {
        ...state,
        loginAttemptProcessing: true,
        loginProcessCompletedSuccessfully: null,
        loginProcessing: true
      };
    case getType(loginAsync.success):
      return {
        ...state,
        loginAttemptProcessing: false,
        loggedIn: true,
        loginProcessing: false,
        loginProcessCompletedSuccessfully: true,
      };
    case getType(loginAsync.failure):
      return {
        ...state,
        loginAttemptProcessing: false,
        loginProcessing: false,
        loggedIn: false,
      };
    case getType(logOutAsync.request):
      return {
        ...state,
        loggingOut: true,
        loggedOut: false,
      };
    case getType(logOutAsync.success):
      return {
        ...USER_INITIAL_STATE,
        loggedIn: false,
        loggingOut: false,
        loggedOut: true,
      };
    case getType(logOutAsync.failure):
      return {
        ...state,
        loggingOut: false,
        loggedOut: false,
      };
    // case LOGOUT_SUCCESSFUL:
    //     return {
    // 	...state,
    // 	loggingOut: false,
    // 	loggedOut: true
    // };
    // case LOGOUT_FAILED:
    //     return {
    //         ...state,
    //         loggingOut: false,
    // 	loggedOut: false
    //     };
    // case INITIALIZE_CACHE_USER_DATA:
    //     return {
    //         ...state,
    //         cachingUserDataProcessing: true
    //     };
    case getType(signUpSuccess): {
      const {email, firstName, lastName} = action.payload;
      const {userId} = action.payload;
      return {
        ...state,
        email,
        id: userId,
        firstName,
        lastName,
        newUser: true
      };
    }
    // case CACHE_USER_DATA_SUCCESSFUL:
    //     ({email, firstName, lastName, id} = action.payload);
    //     let {
    //         city,  zipCode, profilePhotoUrl,
    //         profilePhotoStorageLocation, photoLocalPath, vipIdList,
    //         relationshipStatus, workOrSchoolStatus, educationLevel,
    //         school, workplace, occupation, children, pets, birthday, gender,
    //         favoriteSpot1, favoriteSpot2, favoriteSpot3, interests
    //     } = action.payload;
    //     return {...state,
    //         city, email, firstName, lastName, zipCode, profilePhotoUrl,
    //         profilePhotoStorageLocation, photoLocalPath, vipIdList,
    //         relationshipStatus, workOrSchoolStatus, educationLevel,
    //         school, workplace, occupation, children, pets, birthday, gender, id,
    //         favoriteSpot1, favoriteSpot2, favoriteSpot3, interests,
    //         userDataCached: true, cachingUserDataProcessing: false,
    //         loginProcessing: false, loginProcessCompletedSuccessfully: true};
    // case CACHE_USER_DATA_FAILED:
    //     return {
    //         ...state,
    //         cachingUserDataProcessing: false,
    //         userDataCached: false,
    // 	loginProcessing: false
    //     };
    case getType(submitAnswerAsync.request):
      return {
        ...state,
        submittingAnswer: true
      };
    case getType(submitAnswerAsync.success):
      const { answer, pageId, courseId, chapterId, } = action.payload.data;
      const answersCopy = [...state.answers];
      let progressCopy = [...state.progress];

      if(!action.payload.new) {
        answersCopy.map(record => pageId === record.pageId ? {...record, answer} : record);
      } else {
        answersCopy.push(action.payload.data)
      }

      if (action.payload.final) {
        const courseProgressExists = progressCopy.some((course) => course.courseId === courseId );
        if (courseProgressExists) {
          progressCopy = progressCopy.map(course => course.courseId === courseId ? {
            ...course,
            chaptersCompleted: [...course.chaptersCompleted, chapterId],
            completed: action.payload.completed,
          } : course)
        } else {
          progressCopy.push({
            completed: false,
            chaptersCompleted: [chapterId],
            courseId,
          });
        }
      }

      return {
        ...state,
        submittingAnswer: false,
        submitAnswerSuccessful: true,
        answers: answersCopy,
        progress: progressCopy,
        forumAnswers: [...state.forumAnswers, {
          courseId: action.payload.data.courseId,
          topicId: action.payload.data.topicId,
          topic: action.payload.data.topic,
          answer: action.payload.data.answer,
          answerId: action.payload.data.answerId,
          pageId: action.payload.data.pageId,
        }],
      };
    case getType(submitAnswerAsync.failure):
      return {
        ...state,
        submittingAnswer: false,
        submitAnswerSuccessful: false
      };
    // Track Navigation
    case getType(updateNavigationHistory):
      return {
        ...state,
        navigationSessionHistory: [...state.navigationSessionHistory, action.payload]
      };
    // re-cache answers
    case getType(recacheAnswersAsync.request):
      return {
        ...state,
        recachingAnswers: true,
        recachingAnswersSuccess: null
      };
    case getType(recacheAnswersAsync.success):
      return {
        ...state,
        recachingAnswers: false,
        recachingAnswersSuccess: true,
        answers: action.payload
      };
    case getType(recacheAnswersAsync.failure):
      return {
        ...state,
        recachingAnswers: false,
        recachingAnswersSuccess: false
      };
    case getType(adjustTextToSpeechRate):
      return {
        ...state,
        textToSpeechOptions: {
          ...state.textToSpeechOptions,
          rate: action.payload,
        }
      };
    case getType(adjustTextToSpeechPitch):
      return {
        ...state,
        textToSpeechOptions: {
          ...state.textToSpeechOptions,
          pitch: action.payload,
        }
      };
    case getType(adjustTextToSpeechVoice):
      return {
        ...state,
        textToSpeechOptions: {
          ...state.textToSpeechOptions,
          voice: action.payload,
        }
      };
    case getType(toggleTextToSpeechAutoContinue):
      return {
        ...state,
        textToSpeechOptions: {
          ...state.textToSpeechOptions,
          autoContinue: !state.textToSpeechOptions?.autoContinue,
        }
      };
    case getType(adjustFontSize):
      return {
        ...state,
        fontSize: action.payload ? state.fontSize + 1 : state.fontSize - 1,
      };
    case getType(needsToRegister):
      return {
        ...state,
        authenticatedButNotRegistered: true,
      };
    case getType(initPrivateChatAsync.request):
      return {
        ...state,
        newChatRoomId: null,
      };
    case getType(initPrivateChatAsync.success):
      return {
        ...state,
        privateChats: [...state.privateChats, action.payload],
        newChatRoomId: action.payload.chatRoomId,
      };
    // case getType(submitPrivateChatResponseAsync.success):
    //   let { msg, chatRoomId, timeStamp } = action.payload;
    //   return {
    //     ...state,
    //     privateChats: state.privateChats.map(chat => chat.chatRoomId === chatRoomId ?
    //       {...chat, msgCount: chat.msgCount + 1, messages: [ { msg, timeStamp, userId: action.payload.userId, id: action.payload.id}, ...chat.messages] } : chat),
    //   };
    case getType(recachePrivateChatMessagesAndListenForUpdatesAsync.success): {
      let privateChatsCopy = [...state.privateChats];
      privateChatsCopy = state.privateChats.map(chat => chat.chatRoomId === action.payload.chatRoomId ?
        {...chat, messages: action.payload.chatMessages} : chat);
      return {
        ...state,
        privateChats: privateChatsCopy,
      };
    }
    case getType(addPrivateChatMsg): {
      let privateChatsCopy = [...state.privateChats];
      privateChatsCopy = state.privateChats.map(chat => chat.chatRoomId === action.payload.chatRoomId ?
        {...chat, messages: [...chat.messages, action.payload.msg]} : chat);
      return {
        ...state,
        privateChats: privateChatsCopy,
      };
    }
    case getType(updatePrivateChatMsg): {
      let privateChatsCopy = [...state.privateChats];
      const update = action.payload.msg;
      privateChatsCopy = state.privateChats.map(chat => chat.chatRoomId === action.payload.chatRoomId ?
        {...chat, messages: chat.messages.map(message => message.id === update.id ? update : message)} : chat);
      return {
        ...state,
        privateChats: privateChatsCopy,
      };
    }
    case getType(deletePrivateChatMsg): {
      let privateChatsCopy = [...state.privateChats];
      const deletedId = action.payload.msgId;
      privateChatsCopy = state.privateChats.map(chat => chat.chatRoomId === action.payload.chatRoomId ?
        {...chat, messages: chat.messages.filter(message => message.id !== deletedId)} : chat);
      return {
        ...state,
        privateChats: privateChatsCopy,
      };
    }
    case getType(deletePrivateChatMsgAsync.success):
      let { msgId, chatRoomId: roomId } = action.payload;
      return {
        ...state,
        privateChats: state.privateChats.map(chat => chat.chatRoomId === roomId ?
          {...chat, msgCount: chat.msgCount - 1, messages: chat.messages.filter(msg => msg.id !== msgId) } : chat),
      };
    case getType(editPrivateChatMsgAsync.success):
      ({ msgId, chatRoomId, msg } = action.payload);
      return {
        ...state,
        privateChats: state.privateChats.map(chat => chat.chatRoomId === chatRoomId ?
          {...chat, messages: chat.messages.map(message => message.id === msgId ? {...message, msg} : message)} : chat),
      };
    case getType(recachePrivateChatMetadataAsync.success): {
      const {privateChatsData} = action.payload;
      const privateChatsCopy = [...state.privateChats];
      const newPrivateChatsRecord: PrivateChat[] = [];

      privateChatsData.forEach((privateChatMetadata) => {
        const matchingRecord = privateChatsCopy.find(record => record.chatRoomId === privateChatMetadata.chatRoomId);
        if (matchingRecord) {
          newPrivateChatsRecord.push({...matchingRecord, msgCount: privateChatMetadata.msgCount});
        } else {
          newPrivateChatsRecord.push({...privateChatMetadata, messages: []});
        }
      });

      return {
        ...state,
        privateChats: newPrivateChatsRecord,
      };
    }
    case getType(fetchNotificationsAsync.request):
      return {
        ...state,
        fetchingNotifications: true,
        fetchNotificationsSuccess: null,
      };
    case getType(fetchNotificationsAsync.success):
      return {
        ...state,
        notifications: action.payload,
        fetchingNotifications: false,
        fetchNotificationsSuccess: true,
      };
    case getType(fetchNotificationsAsync.failure):
      return {
        ...state,
        fetchingNotifications: false,
        fetchNotificationsSuccess: false,
      };
    case getType(sendMsgToAuthorAsync.request):
      return {
        ...state,
        sendingMsgToAuthor: true,
        sentMsgToAuthorSuccessfully: null,
      };
    case getType(sendMsgToAuthorAsync.success):
      return {
        ...state,
        sendingMsgToAuthor: false,
        sentMsgToAuthorSuccessfully: true,
      };
    case getType(sendMsgToAuthorAsync.failure):
      return {
        ...state,
        sendingMsgToAuthor: false,
        sentMsgToAuthorSuccessfully: false,
      };
    case getType(updateProfileImageAsync.request):
      return {
        ...state,
        updatingProfile: true,
      };
    case getType(updateProfileImageAsync.success):
      return {
        ...state,
        updatingProfile: false,
        profilePhotoUrl: action.payload,
      };
    case getType(updateProfileImageAsync.failure):
      return {
        ...state,
        updatingProfile: false,
      };
    case getType(fetchOtherUserDataAsync.success): {
      const { otherUserId, aboutMe, testimony, profilePhotoUrl, enrollmentReason } = action.payload;
      return {
        ...state,
        otherUserProfiles: {
          allUserIds: uniq([...state.otherUserProfiles.allUserIds, otherUserId]),
          byUserId: {
            ...state.otherUserProfiles.byUserId,
            [otherUserId]: {
              aboutMe, profilePhotoUrl, enrollmentReason, testimony,
            },
          },
        },
      };
    }
    case getType(updateNotificationReceivedStatusAsync.success):
      return {
        ...state,
        notifications: state.notifications.filter(notification => !action.payload.includes(notification.id)),
      };
    case getType(setInitialNotification):
      return {
        ...state,
        initialNotification: action.payload,
      };
    case getType(resetInitialNotification):
      return {
      ...state,
      initialNotification: undefined,
    };
    case getType(deleteAccountAsync.success):
      return USER_INITIAL_STATE;
    default:
      return state
  }
}
