import React, { Component } from 'react';
import { Container, Content, Tabs, Text, Spinner, Toast, View, Button } from 'native-base';
import { Chapter, Section } from '../../models/course';
import { Dimensions, Platform, StatusBar } from 'react-native';
import { contentStyles} from './content.styles';
import { QuestionTab } from './components/question-tab.component';
import { NotesTab } from './components/note-tab.component';
import CommonService from '../../common/common.service';
import VoiceService  from '../../common/services/voice.service';
import { ContentTab } from './components/content-tab.component';
import { ContentFab } from './components/fab.component';
import ContentContext from './content.context';
import { RootState } from '../../redux/redux.typescript';
import { connect } from 'react-redux';
import {
  getChapterById, getCurrentCoursePublisher, getCurrentCourseTitle,
  getIndividualChapterAnswerCollection
} from '../../redux/selectors';
import { fetchPagesApi, setCurrentSection, setCurrentChapter, submitAnswer, setCurrentPageIndex } from '../../redux/actions';
import { NewSection } from '../../models/new-section';
import { AnswerObject } from '../../models/user';
import { ContentNavigationProps } from '../../navigation/navigation';
import * as Print from 'expo-print';
import Ionicon from 'react-native-vector-icons/Ionicons';
import { shareAsync } from 'expo-sharing';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { ACTION_COLOR } from '../../common/global.styles';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface State {
  readonly startingChapterId: number;
  readonly startingSectionIndex: number;
  readonly currentSection: Section;
  readonly isLoading: boolean;
  readonly changeChapter: boolean;
}

interface Props extends ContentNavigationProps<'Content'> {
  readonly author: string,
  readonly courseTitle: string,
  readonly currentCourseId: string,
  readonly currentChapterId: number,
  readonly currentSectionId: number,
  readonly currentPageIndex: number,
  readonly chapter: Chapter,
  readonly chapterAnswersCount: number;
  readonly fetchingPages: boolean;
  readonly fetchingPagesSuccess: boolean | null;
  readonly fontSize: number;
  readonly submittingAnswer: boolean;
  readonly submitAnswerSuccessful: boolean | null;
  readonly fetchPagesApi: (courseId: string, chapterId: number, sectionIndex: number, sectionUid: string) => void;
  readonly setCurrentSection: (sectionId: number) => void;
  readonly setCurrentChapter: (chapterId: number) => void;
  readonly setCurrentPageIndex: (index: number) => void;
  readonly submitAnswer: (data: AnswerObject) => void
}

class ContentContainer extends Component <Props, State> {
  public readonly state: State;
  private activeTab: 0 | 1 | 2 = 0;


  constructor(props: Props) {
    super(props);
    const { chapter, currentChapterId, currentSectionId } = props;

    props.navigation.setParams({
      header: chapter.sections[currentSectionId]?.title || chapter.sections[currentSectionId],
    });

    this.state = {
      startingChapterId: currentChapterId,
      startingSectionIndex: currentSectionId,
      currentSection: new NewSection(),
      isLoading: true,
      changeChapter: false,
    };

    this.chapterNavigationHandler = this.chapterNavigationHandler.bind(this);
    this.sectionNavigationHandler = this.sectionNavigationHandler.bind(this);
    this.submitAnswerHandler = this.submitAnswerHandler.bind(this);
    this.printSection = this.printSection.bind(this);
    this.shareSection = this.shareSection.bind(this);
    this.convertToHtml = this.convertToHtml.bind(this);
  }

  public componentDidMount(){
    void this.checkForCachedContent();
  }

  private checkForCachedContent = async () => {
    const { currentCourseId, currentChapterId, currentSectionId, chapter } = this.props;
    const storageKey = `@TheologicalAcademy:ContentLastCachedForCourse_${currentCourseId}:chapter_${currentChapterId}:section_${currentSectionId}`;
    const lastCached = await AsyncStorage.getItem(storageKey);
    const millisecondsInMonth = 2419200000;
    // Check to see if pages data is pre-loaded or set initial section
    if (!chapter.sectionsData?.[currentSectionId]?.pages?.length || !lastCached || (JSON.parse(lastCached) < (Date.now() - millisecondsInMonth))) {
      const sectionUid = chapter.sectionsData?.[currentSectionId]?.id;
      this.props.fetchPagesApi(currentCourseId, currentChapterId, currentSectionId, sectionUid);
      await AsyncStorage.setItem(storageKey, JSON.stringify(Date.now()));
    } else {
      this.setState({
        currentSection: chapter.sectionsData[currentSectionId],
        isLoading: false,
      })
    }
  };

  public componentDidUpdate(prevProps: Props, prevState: State){
    const {fetchingPages, submittingAnswer, fetchingPagesSuccess, chapter, currentSectionId } = this.props;
    // Check if Fetching Page Data for current section
    if (prevProps.fetchingPages && !fetchingPages && fetchingPagesSuccess) {
      this.setState({
        currentSection: chapter.sectionsData[currentSectionId],
        isLoading: false
      });
    }

    if(prevProps.submittingAnswer && !submittingAnswer) {
      this.setState({
        isLoading: false
      });
    }

    this.checkAnswerSubmission(prevProps);

    if(this.state.changeChapter && !prevState.changeChapter) {
      this.triggerChapterContentChange();
    }
  }

  public componentWillUnmount(): void {
    VoiceService.stopSpeaking();
  }

  private checkAnswerSubmission = (prevProps: Props) => {
    const { submittingAnswer, submitAnswerSuccessful } = this.props;

    if(this.state.isLoading && submittingAnswer || prevProps.submittingAnswer){
      if(prevProps.submittingAnswer && !submittingAnswer){
        if(submitAnswerSuccessful) {
          Toast.show({
            duration: 3000,
            text: 'Answer submitted successfully',
            type: 'success'
          })
        } else {
          Toast.show({
            duration: 3000,
            text: 'There was a problem submitting the answer. Copy the answer. Restart the app and try again.',
            type: 'danger'
          })
        }

        this.setState({
          isLoading: false
        })
      }
    }
  };

  private sectionNavigationHandler(incrementation: number) {
    // Flip sections within a Chapter
    const { currentCourseId, currentChapterId, currentSectionId, chapter } = this.props;
    const newSectionId = currentSectionId + incrementation;
    // Fetch Page data for new section if not pre-loaded
    if(!chapter.sectionsData[newSectionId].pages) {
      const sectionId = chapter.sectionsData[newSectionId].id;
      this.props.fetchPagesApi(currentCourseId, currentChapterId, newSectionId, sectionId);
      this.setState({
        isLoading: true,
      });
    } else {
      this.setState({
        currentSection: chapter.sectionsData[newSectionId]
      });
    }

    this.props.setCurrentPageIndex(0);
    this.props.setCurrentSection(newSectionId);
    this.props.navigation.setParams({
      header: chapter.sections[newSectionId]?.title || chapter.sections[newSectionId],
    });
  }

  private chapterNavigationHandler(incrementation: number): void {
    const { currentChapterId } = this.props;
    this.props.setCurrentChapter(currentChapterId + incrementation);
    this.props.setCurrentSection(0);

    this.setState({
      changeChapter: true
    });
  }

  private triggerChapterContentChange(): void {
    const { currentCourseId,  currentChapterId, chapter } = this.props;

    this.props.navigation.setParams({
      header: chapter.sections[0]?.title || chapter.sections[0],
    });

    if(!chapter.sectionsData[0].pages) {
      const sectionUid = chapter.sectionsData[0]?.id;

      this.props.fetchPagesApi(currentCourseId, currentChapterId, 0, sectionUid);
      this.setState({
        isLoading: true,
        changeChapter: false,
      });
    } else {
      this.setState({
        currentSection: chapter.sectionsData[0],
        changeChapter: false,
      });
    }

    this.props.setCurrentPageIndex(0);
  }

  private submitAnswerHandler(answer: string, share: boolean, resubmission: boolean) {
    const { currentSection } = this.state;
    const { currentChapterId, currentCourseId, currentPageIndex, chapterAnswersCount, chapter } = this.props;
    const answerId = resubmission ? currentSection.pages[currentPageIndex].answerId : '';
    const finalChapterAnswer = !resubmission && (chapter?.questionsCount - chapterAnswersCount === 1);

    const data = {
      answer,
      share,
      courseId: currentCourseId,
      chapterId: currentChapterId,
      pageId: currentSection.pages[currentPageIndex].id,
      answerId,
      final: finalChapterAnswer,
      topic: currentSection.topic,
      topicId: `chapter_${currentChapterId}_questions`,
    }

    this.props.submitAnswer(data);

    this.setState({
      isLoading: true
    });
  };

  convertToHtml(): string {
    const { chapter, author, courseTitle } = this.props;
    const { currentSection } = this.state;
    return `
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
        </head>
        <body style="text-align: center; padding-left: 16px; margin-top: 24px; padding-right: 16px">
          <h1 style="font-size: 32px; font-family: Garamond; font-weight: normal;">
            ${currentSection?.topic}
          </h1>
          <h2 style="font-size: 24px; font-family: Garamond; font-weight: normal;">
            Taken From Chapter ${chapter?.id}: "${chapter.title}" of "${courseTitle}"
          </h2>
          <h3 style="font-size: 18px; font-family: Garamond; font-weight: normal;">
            Written By ${author}
          </h3>
          ${currentSection?.pages?.map(page => `<p style="font-size: 18px; font-family: Garamond; font-weight: normal; text-align: justify">${page?.content}</p>`)}
          <h1 style="font-size: 28px; font-family: Garamond; font-weight: normal; margin-top: 24px">
            Questions
          </h1>
          <ol>
            ${currentSection?.pages?.filter(page => page?.question).map(page => `<li style="font-size: 18px; font-family: Garamond; font-weight: normal;">${page?.question}</li>`)}
          </ol>
        </body>
      </html>
      `;
  }

  async printSection (){
    const html = this.convertToHtml();
    await Print.printAsync({ html });
  }

  async shareSection() {
    const html = this.convertToHtml();
    const { uri } = await Print.printToFileAsync({ html });
    await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
  }

  public render() {
    const { currentSection, isLoading } = this.state;
    const { currentSectionId, currentChapterId, currentPageIndex, chapter, fontSize } = this.props;
    const courseData = this.props.route?.params?.course ?? null;

    if (currentSection && !isLoading && currentSection?.pages?.[currentPageIndex]) {
      const { content, note, question, userAnswer = '', shareAnswer = false } = currentSection.pages[currentPageIndex];
      const finalFontSize = Platform.OS === 'web' && Dimensions.get('screen').width > 750 ? (fontSize * 1.5) : fontSize;

      return (
        <Container style={{backgroundColor: '#474646'}} >
          <StatusBar barStyle="light-content"/>
          <ContentContext.Provider value={{ fontSize: finalFontSize }}>
            <Tabs
              tabBarUnderlineStyle={{backgroundColor: '#ffe57f'}}
              onChangeTab={({i}) => this.activeTab = i}
            >
              { CommonService.customTab('Content',
                <ContentTab
                  currentChapterId={currentChapterId}
                  currentPageIndex={currentPageIndex}
                  currentSection={currentSection}
                  content={content}
                  currentSectionId={currentSectionId}
                  numberOfChapters={courseData.chapters.length}
                  numberOfSections={chapter.sections.length}
                  sectionNavigationHandler={this.sectionNavigationHandler}
                  chapterNavigationHandler={this.chapterNavigationHandler}
                  returnHome={this.props.navigation.goBack}
                />)}
              { note ? CommonService.customTab('Notes', <NotesTab note={note}/>) : null }
              { question ? CommonService.customTab('Question',
                <QuestionTab
                  question={question}
                  alreadyAnswered={userAnswer.length > 0}
                  userAnswer={userAnswer}
                  shareAnswer={shareAnswer}
                  submitHandler={this.submitAnswerHandler}
                />) : null }
            </Tabs>
            <View style={[Platform.OS !== 'web' ? { flex: .15, marginBottom: -8 } : { marginBottom: 16 }, { alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginTop: 8 }]}>
              <Button onPress={this.printSection} icon rounded style={{  borderWidth: .5, borderColor: 'white'  }}>
                <Ionicon
                  name="print"
                  style={{ color: 'white', fontSize: 24, paddingHorizontal: 12 }}
                />
              </Button>
              <Button rounded disabled style={{  marginLeft: 16, borderWidth: .5, borderColor: 'white', backgroundColor: 'transparent' }}>
                <Text>{currentPageIndex + 1}</Text>
              </Button>
              <Button onPress={this.shareSection} icon rounded style={{ marginLeft: 16, borderWidth: .5, borderColor: 'white' }}>
                <MaterialIcon
                  name="share"
                  style={{ color: 'white', fontSize: 24, paddingHorizontal: 12 }}
                />
              </Button>
            </View>
            <ContentFab
              currentSection={currentSection}
              currentPageIndex={currentPageIndex}
              activeTab={this.activeTab}
            />
          </ContentContext.Provider>
        </Container>
      )
    }

    return <View style={contentStyles.spinner}><Spinner color={ACTION_COLOR} /></View>;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    chapterAnswersCount: getIndividualChapterAnswerCollection(state),
    currentCourseId: state.coursesData.currentCourseId,
    currentChapterId: state.coursesData.currentChapterId,
    currentPageIndex: state.coursesData.currentPageIndex,
    currentSectionId: state.coursesData.currentSectionId,
    fetchingPages: state.coursesData.fetchingPages,
    fetchingPagesSuccess: state.coursesData.fetchingPagesSuccess,
    fontSize: state.userData.fontSize,
    submittingAnswer: state.userData.submittingAnswer,
    submitAnswerSuccessful: state.userData.submitAnswerSuccessful,
    chapter: getChapterById(state),
    author: getCurrentCoursePublisher(state),
    courseTitle: getCurrentCourseTitle(state),
  }
};

export default connect(mapStateToProps, {
  fetchPagesApi, setCurrentSection, setCurrentChapter, submitAnswer, setCurrentPageIndex,
})(ContentContainer);


