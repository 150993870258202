import React, { FC, useEffect, useState } from 'react';
import { Modal, Platform, View } from 'react-native';
import {
  Fab, Button, Icon, Header, Content, List, H2, Body, CardItem, Title, ListItem, Text, Left, Right, Container, Card,
} from 'native-base';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useDispatch, useSelector } from 'react-redux';
import { PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../common/global.styles';
import VoiceService from '../../../common/services/voice.service';
import { RootState } from '../../../redux/redux.typescript';
import { Section } from '../../../models/course';
import { adjustTextToSpeechPitch, adjustTextToSpeechRate, adjustTextToSpeechVoice } from '../../../redux/actions';
import Slider from '@react-native-community/slider';

type Props = {
  readonly currentSection: Section;
  readonly currentPageIndex: number;
  readonly  activeTab: 0 | 1 | 2;
}

const textToSpeechIcon =  <MaterialCommunityIcon name="text-to-speech" style={{fontSize: 32, paddingTop: 8, color: 'black'}}/>;
const settingsIcon = (onPress) => (
  <Button style={{backgroundColor: PRIMARY_COLOR}} onPress={onPress}>
    <Icon name="settings" style={{color: 'black'}}/>
  </Button>
);

export const ContentFab: FC<Props> = ({ activeTab, currentSection, currentPageIndex }: Props) => {
  const [displayState, setDisplayState] = useState(false);
  const [textToSpeechActiveState, setTextToSpeechActiveState] = useState(false);
  const [textToSpeechPausedState, setTextToSpeechPausedState] = useState(false);
  const [displayVoicePicker, setDisplayVoicePicker] = useState(false);
  const [displayVoicePitchSlider, setDisplayVoicePitchSlider] = useState(false);
  const [displayVoiceRateSlider, setDisplayVoiceRateSlider] = useState(false);
  const dispatch = useDispatch();
  const textToSpeechOptions = useSelector((state: RootState) => state.userData.textToSpeechOptions);

  useEffect(() => {
    void VoiceService.getVoices();
    if (VoiceService.autoContinueActivated) {
      textToSpeech();
    }
  }, [currentPageIndex]);

  function onTextToSpeechDone() {
    setTextToSpeechActiveState(false);

    if (textToSpeechOptions?.autoContinue) {
      if (VoiceService.autoContinueHandler) {
        VoiceService.autoContinueHandler();
        VoiceService.autoContinueActivated = true;
      } else {
        VoiceService.autoContinueActivated = false;
      }
    } else {
      VoiceService.autoContinueActivated = false;
    }
  }

  function onTextToSpeechStopped() {
    VoiceService.autoContinueActivated = false;
  }

  function textToSpeech() {
    const options = {
      ...textToSpeechOptions,
      onDone: onTextToSpeechDone,
      onStopped: onTextToSpeechStopped,
    };

    if (currentSection) {
      const { content, note, question } = currentSection.pages[currentPageIndex];
      if(note) {
        if (activeTab === 0){
          VoiceService.speak(content, options);

        } else if (activeTab === 1){
          VoiceService.speak(note, options);

        } else if (activeTab === 2){
          VoiceService.speak(question, options);
        }
      } else {
        if (activeTab === 0){
          VoiceService.speak(content, options);

        } else if (activeTab === 1){
          VoiceService.speak(question, options);
        }
      }

      setTextToSpeechActiveState(true);

      if(textToSpeechPausedState) {
        setTextToSpeechPausedState(false)
      }
    }
  }

  function turnOffTextToSpeech() {
    VoiceService.autoContinueActivated = false;
    VoiceService.stopSpeaking();
    setTextToSpeechActiveState(false);
  }

  function pauseTextToSpeech() {
    VoiceService.pauseSpeaking();
    setTextToSpeechPausedState(true);
  }

  function renderVoiceSliderModal(mode: 'pitch' | 'rate') {
    return (
      <Modal visible={mode === 'pitch' ? displayVoicePitchSlider : displayVoiceRateSlider} animationType="slide" transparent={true}>
        <View style={{ marginTop: '25%', height: 200, backgroundColor: '#474646'}}>
          <Card>
            <CardItem header>
              <Left>
                <H2 style={{ color: 'black',  }}>{mode === 'pitch' ? 'Pitch' : 'Rate'} Slider</H2>
              </Left>
              <Right>
                <Button icon transparent onPress={() => {
                  void VoiceService.stopSpeaking();
                  mode === 'pitch' ? setDisplayVoicePitchSlider(false) : setDisplayVoiceRateSlider(false);
                }}>
                  <Icon name="close-circle" style={{color: '#ff4081', fontSize: 32 }}/>
                </Button>
              </Right>
            </CardItem>
            <CardItem>
              <Body>
                <Slider
                  style={{width: '100%', marginBottom: 8}}
                  minimumValue={0}
                  value={textToSpeechOptions[mode === 'pitch' ? 'pitch' : 'rate']}
                  maximumValue={mode === 'pitch' ? 2 : 1.5}
                  onSlidingComplete={async (value) => {
                    dispatch(mode === 'pitch' ? adjustTextToSpeechPitch(value) : adjustTextToSpeechRate(value));
                    await VoiceService.stopSpeaking();
                    await VoiceService.speak('Love the Lord your God with all your heart, mind, and strength.', { ...textToSpeechOptions, ...(mode === 'pitch' ? { pitch: value } : { rate: value }) });
                  }}
                  thumbTintColor="#ffe57f"
                />
              </Body>
            </CardItem>
          </Card>
        </View>
      </Modal>
    )
  }

  function renderVoiceSelectionModal() {
    return (
      <Modal visible={displayVoicePicker} animationType="slide" transparent={true}>
        <Container style={{ backgroundColor: '#ffe57f' }}>
          <Header style={{ backgroundColor: '#474646'}}>
            <Body>
              <Title style={{ color: '#ffe57f'}}>Voice Selection</Title>
            </Body>
            <Right>
              <Button icon transparent onPress={() => {
                void VoiceService.stopSpeaking();
                setDisplayVoicePicker(false)
              }}>
                <Icon name="close-circle" style={{color: '#ff4081'}}/>
              </Button>
            </Right>
          </Header>
          <Content style={{ backgroundColor: '#ffe57f',}}>
            <List>
              {VoiceService.englishVoiceList.map(voice => {
                return (
                  <ListItem noIndent style={{ borderColor: '#373737', backgroundColor: textToSpeechOptions?.voice === voice?.identifier ? '#FFC400' : '#ffe57f' }}>
                    <Left>
                      <Text style={{ color: 'black', fontSize: 20, fontFamily: 'Roboto' }}>{voice.name}</Text>
                    </Left>
                    <Right style={{ flexDirection: 'row', flex: 1}}>
                      <Button transparent bordered onPress={async () => {
                        await VoiceService.stopSpeaking();
                        await VoiceService.speak('Love the Lord your God with all your heart, mind, and strength.', { ...textToSpeechOptions, voice: voice?.identifier });
                      }}>
                        <Text style={{ color:'black', fontFamily: 'Roboto'}}>Sample</Text>
                      </Button>
                      <Button style={{ marginLeft: 8 }} onPress={() => {
                        void VoiceService.stopSpeaking();
                        dispatch(adjustTextToSpeechVoice(voice.identifier));
                        setDisplayVoicePicker(false);
                      }}>
                        <Text style={{ fontWeight: 'bold', fontFamily: 'Roboto_medium'}} >Select</Text>
                      </Button>
                    </Right>
                  </ListItem>
                )}
              )}
            </List>
          </Content>
        </Container>
    </Modal>
    )

  }

  const fabProps = {
    active: displayState,
    direction: 'up' as 'up' | 'down' | 'left' | 'right',
    style: { backgroundColor: PRIMARY_COLOR_ACCENT },
    position:'bottomRight' as 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft',
    onPress: setDisplayState.bind(this, !displayState),
  };

  if (Platform.OS === 'android') {
    return (
      <>
        <Fab {...fabProps}>
          {textToSpeechIcon}
          <Button
            style={{backgroundColor: PRIMARY_COLOR}}
            onPress={textToSpeechActiveState ? turnOffTextToSpeech : textToSpeech}
          >
            <Icon name={textToSpeechActiveState ? 'stop' : 'play'} style={{color: 'black'}} />
          </Button>
          {settingsIcon(() => VoiceService.openSettings(textToSpeech, dispatch, setDisplayVoicePicker, setDisplayVoicePitchSlider, setDisplayVoiceRateSlider, textToSpeechOptions.autoContinue))}
        </Fab>
        {renderVoiceSelectionModal()}
        {renderVoiceSliderModal('pitch')}
        {renderVoiceSliderModal('rate')}
      </>
    );
  }

  return (
    <>
      <Fab {...fabProps}>
        {textToSpeechIcon}
        <Button
          style={{backgroundColor: PRIMARY_COLOR}}
          onPress={textToSpeechActiveState && !textToSpeechPausedState ? pauseTextToSpeech : textToSpeech}
        >
          <Icon name={textToSpeechActiveState && !textToSpeechPausedState ? 'pause' : 'play'} style={{color: 'black'}}/>
        </Button>
        { textToSpeechActiveState &&
        <Button
          onPress={turnOffTextToSpeech}
          style={{backgroundColor: PRIMARY_COLOR}}
        >
          <Icon name="stop" style={{color: 'black'}} />
        </Button>}
        {settingsIcon(() => VoiceService.openSettings(textToSpeech, dispatch, setDisplayVoicePicker, setDisplayVoicePitchSlider, setDisplayVoiceRateSlider, textToSpeechOptions.autoContinue))}
      </Fab>
      {renderVoiceSelectionModal()}
      {renderVoiceSliderModal('pitch')}
      {renderVoiceSliderModal('rate')}
    </>
  );

};
