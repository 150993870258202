import React, { FC, useEffect } from 'react';
import { Platform, Text, View } from 'react-native';
import { Accordion, Body, CardItem, H1, Icon, List, ListItem, Right } from 'native-base';
import { ForumTopics } from '../../../models/user';
import { ACTION_COLOR, PRIMARY_COLOR } from '../../../common/global.styles';
import { NavigationScreenProp } from 'react-navigation';
import { PrivateChat, SortedPrivateChats } from '../../../models/private-chat';
import { getAuth } from 'firebase/auth';

interface SortedForumTopics {
  id: string;
  title: string;
  topics: ForumTopics[]
}

type Props = {
  privateChats: SortedPrivateChats[];
  fetchAnswerCounts: (courseId: string) => void;
  navigation: NavigationScreenProp<any, any>;
}

export const PrivateTab: FC<Props> = ({ privateChats, fetchAnswerCounts, navigation }: Props): JSX.Element => {
  function renderTopicsHeader(item: SortedPrivateChats, expanded: boolean): JSX.Element {
    const { currentUser } = getAuth();
    const otherUser = item.chatThreads[0].originalCommenterId === currentUser.uid ? item.chatThreads[0].chatStarter : item.chatThreads[0].originalCommenter;

    return (
      <View style={[{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        padding: 8,
        alignItems: 'center' ,
        backgroundColor: '#FFC400',
      }, expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null]}>
        <View style={{flex: .90}}>
          <Text style={{ fontWeight: expanded ? '800' : '600', fontSize: 15, paddingLeft: 8, paddingRight: 16, textAlign: 'left', lineHeight: 20 }}>{item.chatThreads.length} Conversation{item.chatThreads.length !== 1 ? 's': ''} with {otherUser}</Text>
        </View>
        <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
          {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} style={{ fontSize: 20, color: ACTION_COLOR }} />
            : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} style={{ fontSize: 20, color: ACTION_COLOR }} />
          }
        </View>
      </View>
    )
  }

  function renderTopicsContent (item: SortedPrivateChats): JSX.Element {
    const { currentUser } = getAuth();

    return (
      <View style={{ backgroundColor: '#ffe57f'}}>
        <List>
          {item.chatThreads.map((record: PrivateChat) => {
            if(record) {
              return (
                <ListItem
                  button
                  onPress={() => navigation.navigate('PrivateChatRoom', { new: false, chatRoomId: record.chatRoomId })}
                  key={record.chatRoomId}>
                  <Body style={{marginRight: -36}}>
                    <Text style={{ fontWeight: 'bold',fontSize: 15 }}>Prompted by {record.originalCommenterId === currentUser.uid ? 'you' : record.originalCommenter} saying, "{record.originalComment}"</Text>
                    <Text style={{ fontSize: 14, textAlign: 'right', paddingTop: 4 }}>Started: {new Date(record.startDate).toLocaleDateString()}</Text>
                    <Text style={{ fontSize: 16, textAlign: 'center', paddingTop: 8 }}>{record.msgCount || 0} Messages</Text>
                  </Body>
                  <Right >
                    <Icon name="arrow-forward" style={{color: ACTION_COLOR}} />
                  </Right>
                </ListItem>
              )
            }
          })}
        </List>
      </View>
    )
  }

  if (!privateChats?.length) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#474646'}}>
        <H1 style={{ color: PRIMARY_COLOR, padding: 24, textAlign: 'center'}}>{`You have not started any private chats.\n\nYou can start them from the forum by pressing on another user's post and starting a chat.`}</H1>
      </View>
    );
  }

  return (
    <View style={[{flex: 1, margin: 0, padding: 16, backgroundColor: '#474646', alignItems: 'center'}]}>
      <CardItem style={[{ backgroundColor: '#5f5f5f', marginBottom: -10 }, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
        <Accordion
          dataArray={privateChats}
          renderHeader={renderTopicsHeader}
          renderContent={renderTopicsContent}
          expanded={[]}
          // onAccordionOpen={(item: SortedForumTopics) => fetchAnswerCounts(item.id)}
        />
      </CardItem>
    </View>
  )
};