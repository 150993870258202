// tslint:disable:forin
import _,  {Dictionary } from 'lodash';
import { RootState } from '../redux.typescript';
import { AnswerObject, OrganizedForumAnswersByCourse, OrganizedForumResponseByCourse } from '../../models/user';
import {
    getChapterById,
    getCourseById,
    getCourseChapters,
    getCoursesState,
    getCurrentCourseId
} from './courses.selectors';
import { Chapter, Course, UserForumAnswer, UserForumResponse } from '../../models/course';
import { createSelector } from 'reselect';
import { PrivateChat, SortedPrivateChats } from '../../models/private-chat';
import { getAuth } from 'firebase/auth';

export const getAnswersState: (store: RootState) => Array<AnswerObject> = (store: RootState) => store.userData.answers;
export const getForumResponsesState: (store: RootState) => Array<UserForumResponse> = (store: RootState) => store.userData.forumResponses;
export const getForumAnswersState: (store: RootState) => Array<UserForumAnswer> = (store: RootState) => store.userData.forumAnswers;
export const getPrivateChatsState: (store: RootState) => Array<PrivateChat> = (store: RootState) => store.userData.privateChats;

export const getNumberOfAnswersByCourseId = createSelector<RootState, Array<AnswerObject>, string, number>
(getAnswersState, getCurrentCourseId, (answers: Array<AnswerObject>, id: string) => {
    return answers.filter(answer => answer.courseId === id).length;
});

export const getNumberOfAnswersByChapterId = (store: RootState, id: number, courseId: string): number => {
    return getAnswersState(store) ?
        getAnswersState(store).filter((answer: AnswerObject) => answer.chapterId === id && answer.courseId === courseId).length : 0;
};

export const getSortedForumResponses = (store: RootState): OrganizedForumResponseByCourse[] => {
    const organizedResponses: OrganizedForumResponseByCourse[] = [];

    if (!getForumResponsesState(store)) {
        return [];
    }

    const sortedByCourses: Dictionary<UserForumResponse[]> =  _.groupBy(getForumResponsesState(store), (response) => response.courseId);
    const sortedByTopicId: Dictionary<Dictionary<UserForumResponse[]>> = {};

    for(const key in sortedByCourses) {
        sortedByTopicId[key] = _.groupBy(sortedByCourses[key], (group) => group.topicId);
    }

    const sortedByAnswerId = {...sortedByTopicId};


    for(const key in sortedByTopicId) {
        for(const key2 in sortedByTopicId[key]) {
            sortedByAnswerId[key][key2] = _.groupBy(sortedByTopicId[key][key2], (group) => group.answerId);
        }
    }

    for(const key in sortedByAnswerId) {
        let chapterTitleAssigned = false;

        for(const key2 in sortedByAnswerId[key]) {

            const topic = {
                courseId: key,
                topic: '',
                courseTitle: '',
                answers: [],
            };
            for(const key3 in sortedByAnswerId[key][key2]) {
                topic.topic = sortedByAnswerId[key][key2][key3][0].topic;

                if(!chapterTitleAssigned) {
                    topic.courseTitle = getCourseById(store, key).title;
                    chapterTitleAssigned = true;
                }

                topic.answers.push({
                    answer: sortedByAnswerId[key][key2][key3][0].answer,
                    responses: sortedByAnswerId[key][key2][key3],
                })
            }
            organizedResponses.push(topic);
        }
    }

    return organizedResponses;
};


export const getSortedForumAnswers = (store: RootState): OrganizedForumAnswersByCourse[] => {
    const organizedAnswers: OrganizedForumAnswersByCourse[] = [];

    if (!getForumAnswersState(store)) {
        return [];
    }

    const sortedByCourses: Dictionary<UserForumAnswer[]> =  _.groupBy(getForumAnswersState(store), (response) => response.courseId);
    const sortedByTopicId: Dictionary<Dictionary<UserForumAnswer[]>> = {};

    for(const key in sortedByCourses) {
        sortedByTopicId[key] = _.groupBy(sortedByCourses[key], (group) => group.topicId);
    }

    for(const key in sortedByTopicId) {
        let chapterTitleAssigned = false;

        for(const key2 in sortedByTopicId[key]) {
            const topic = {
                courseId: key,
                topic: '',
                topicId: '',
                courseTitle: '',
                answers: [],
            };
            topic.topic = sortedByTopicId[key][key2][0].topic;
            topic.topicId = sortedByTopicId[key][key2][0].topicId;

            if(!chapterTitleAssigned) {
                topic.courseTitle = getCourseById(store, key).title;
                chapterTitleAssigned = true;
            }

            sortedByTopicId[key][key2].forEach((answer, index) => {
                topic.answers.push({
                    answer: answer.answer,
                    answerId: answer.answerId,
                    pageId: answer.pageId,
                });
            });

            organizedAnswers.push(topic);
        }
    }
    return organizedAnswers;
};


export const getChapterAnswerCollection = createSelector
((state) => state, getCourseChapters, getCurrentCourseId, (state: RootState, chapters: Array<Chapter>, courseId: string) => {
    if (chapters) {
        return chapters.map((chapter: Chapter) => {
            return {
                id: chapter.id,
                answerCount: getNumberOfAnswersByChapterId(state, chapter.id, courseId)
            }
        });
    } else {
        return null;
    }
});

export const getIndividualChapterAnswerCollection = createSelector
((state) => state, getChapterById, getCurrentCourseId, (state: RootState, chapter: Chapter, courseId: string) => {
    if(chapter) {
        return getNumberOfAnswersByChapterId(state, chapter.id, courseId);
    } else {
        return 0;
    }
});
// export const getNumberOfAnswersByCourseId = (store: RootState, id: string): number => {
//     return getAnswersState(store) ?
//         getAnswersState(store).filter((answer: AnswerObject) => answer.courseId === id).length : 0;
// };

export const getPrivateChatRoomById = (store: RootState, id: string): PrivateChat => {
    if(getPrivateChatsState(store)) {
        const matchingRecord: any = {...getPrivateChatsState(store).find((chat: PrivateChat) => chat.chatRoomId === id)};
        if (matchingRecord && matchingRecord.messages && matchingRecord.messages.length > 0) {
            matchingRecord.messages = matchingRecord.messages.map(message => {
                return {
                    _id: message.id,
                    text: `${message.msg}${message.updateTimeStamp ? ' (edited)' : ''}`,
                    createdAt: message.timeStamp,
                    user: {
                        _id: message.userId,
                        name: message.userId === matchingRecord.originalCommenterId ? `${matchingRecord.originalCommenter}`
                          : matchingRecord.chatStarter,
                    }
                }
            })
        }

        return matchingRecord;
    } else {
        return null;
    }
};

export const getPrivateChatsSortedByOtherUserId = (store: RootState): SortedPrivateChats[] => {
    if(getPrivateChatsState(store)) {
        const privateChatsCopy = [...getPrivateChatsState(store)];
        let privateChatsSorted: SortedPrivateChats[]= [];
        const { currentUser } = getAuth();
        const userIdList: string[] = [];

        privateChatsCopy.forEach((chat) => {
            const userId = chat.originalCommenterId === currentUser.uid ? chat.otherUserId : chat.originalCommenterId;
            if (!userIdList.includes(userId)) {
                userIdList.push(userId);
                privateChatsSorted.push({
                    userId: chat.originalCommenterId,
                    chatThreads: [chat],
                });
            } else {
                privateChatsSorted = privateChatsSorted.map((record) => record.userId === userId ? {...record, chatThreads: [...record.chatThreads, chat]}: record);
            }
        });

        return privateChatsSorted;
    } else {
        return [];
    }
}