import React, { Component } from 'react';
import { Dimensions, Platform, Text, View } from 'react-native';
import { connect } from 'react-redux';
import {
  Content,
  Container,
  Card,
  CardItem,
  Icon,
  Textarea,
  Fab,
  Form,
  Toast
} from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR_ACCENT } from '../../common/global.styles';
import { sendMsgToAuthor } from '../../redux/actions';
import { InputModal } from '../../common/components/input-modal-component';
import { SubmitButton } from '../../common/components/submit-button';
import signUpStyles from '../../features/auth/signup/signup.styles';
import { RootState } from '../../redux/redux.typescript';
import { Spinner } from '../../common/components/spinner/spinner.component';
import { DrawerNavigationProps } from '../../navigation/navigation';

interface State {
  readonly displayFeedbackModal: boolean;
  readonly displayQuestionModal: boolean;
  readonly displayReportModal: boolean;
  readonly input: string;
  readonly isLoading: boolean;
}

interface Props extends DrawerNavigationProps<'Contact'> {
  readonly sendingMsgToAuthor: boolean;
  readonly sentMsgToAuthorSuccessfully: boolean | null;
  readonly sendMsgToAuthor: (type: string, msg: string) => void;
}

class ContactContainer extends Component <Props, State> {

  constructor(props: Props){
    super(props);

    this.state = {
      displayFeedbackModal: false,
      displayQuestionModal: false,
      displayReportModal: false,
      input: '',
      isLoading: false,
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if(prevProps.sendingMsgToAuthor && !this.props.sendingMsgToAuthor) {
      if (this.props.sentMsgToAuthorSuccessfully) {
        Toast.show({
          duration: 5000,
          text: 'The message was sent successfully. The author may contact you by email.',
          type: 'success'
        });
      } else {
        Toast.show({
          duration: 5000,
          text: 'There was a problem submitting the message. Restart the app and try again.',
          type: 'danger'
        });
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  public renderInputModal = (property: string, placeholder: string,) => {
    const { input } = this.state;
    return (
      <InputModal displayModal={this.state[property]}>
        <Form>
          <Textarea
            bordered
            rowSpan={5}
            placeholder={placeholder}
            placeholderTextColor="#ffe57f"
            value={input}
            style={{ marginLeft: 16, marginTop: 32, color: '#ffe57f'}}
            onChangeText={(val) => this.setState({ input: val })}
          />
          {
            input.length < 25 &&
              <View style={signUpStyles.errorTextContainer}>
                <Text style={signUpStyles.errorTextStyle}>
                  Must be at least 25 characters
                </Text>
              </View>
          }
        </Form>
        <View style={{marginTop: 16}}>
          <SubmitButton
            loading={false}
            disabled={input.length < 25}
            onPress={() => {
              const type = property === 'displayFeedbackModal' ? 'Feedback' : property === 'displayQuestionModal' ? 'Question' : 'Report';
              this.props.sendMsgToAuthor(type, input);
              // @ts-ignore
              this.setState({ [property] : false, input: '', isLoading: true })
            }}
            text="submit"
          />
          <SubmitButton
            text="cancel"
            color={PRIMARY_COLOR_ACCENT}
            // @ts-ignore
            onPress={() => this.setState({ [property] : false, input: '' })}
          />
        </View>
      </InputModal>
    )
  };

  public render = (): React.ReactNode =>  {

    if(this.state.isLoading){
      return (
        <View style={{flex: 1, backgroundColor: '#474646'}}>
          <Spinner size="large"/>
        </View>
      );
    }

    return (
      <Container style={{backgroundColor: '#474646'}}>
        <Content
          style={{margin: 0, padding: 16, backgroundColor: '#474646'}}
          contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}
        >
          <Card style={[ Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
            <CardItem
              header
              bordered
              first
              style={{ backgroundColor: PRIMARY_COLOR_ACCENT,  borderColor: '#474646'}}
            >
              <Text style={{fontWeight: 'bold'}}>Contact the Author</Text>
            </CardItem>
            <CardItem style={{ backgroundColor: '#ffe57f', marginBottom: -10 }} button onPress={() => this.setState({ displayQuestionModal: true })}>
              <View
                style={
                  {
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                    padding: 8,
                    alignItems: 'center' ,
                    backgroundColor: '#ffe57f',
                  }}
              >
                <View style={{flex: .90}}>
                  <Text style={{ fontWeight: '400', fontSize: 14, paddingLeft: 8, paddingRight: 16, textAlign: 'left'}}>
                    Get Help or ask a Question
                  </Text>
                </View>
                <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
                  <Icon name={Platform.OS === 'ios' ? 'ios-arrow-forward' : 'md-arrow-forward'} style={{ fontSize: 20, color: ACTION_COLOR }} />
                </View>
              </View>
            </CardItem>
            <CardItem style={{ backgroundColor: '#ffe57f', marginBottom: -10 }} button onPress={() => this.setState({ displayFeedbackModal: true })}>
              <View
                style={
                  {
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                    padding: 8,
                    alignItems: 'center' ,
                    backgroundColor: '#ffe57f',
                  }}
              >
                <View style={{flex: .90}}>
                  <Text style={{ fontWeight: '400', fontSize: 14, paddingLeft: 8, paddingRight: 16, textAlign: 'left'}}>
                    Provide Feedback or Suggestion
                  </Text>
                </View>
                <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
                  <Icon name={Platform.OS === 'ios' ? 'ios-arrow-forward' : 'md-arrow-forward'} style={{ fontSize: 20, color: ACTION_COLOR }} />
                </View>
              </View>
            </CardItem>
            <CardItem style={{ backgroundColor: '#ffe57f' }} button onPress={() => this.setState({ displayReportModal: true })}>
              <View
                style={
                  {
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                    padding: 8,
                    alignItems: 'center' ,
                    backgroundColor: '#ffe57f',
                  }}
              >
                <View style={{flex: .90}}>
                  <Text style={{ fontWeight: '400', fontSize: 14, paddingLeft: 8, paddingRight: 16, textAlign: 'left'}}>
                    Report a Bug or Problem
                  </Text>
                </View>
                <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
                  <Icon name={Platform.OS === 'ios' ? 'ios-arrow-forward' : 'md-arrow-forward'} style={{ fontSize: 20, color: ACTION_COLOR }} />
                </View>
              </View>
            </CardItem>
          </Card>
          <Fab
            style={{ backgroundColor: PRIMARY_COLOR_ACCENT, justifyContent: 'center'  }}
            onPress={this.props.navigation.toggleDrawer}
            position={Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? "topLeft" : "bottomRight"}
          >
            <Icon
              name="menu"
              style={{ fontSize: 30, paddingTop: Platform.OS === 'ios' ? 8 : 0  }}
            />
          </Fab>
        </Content>
        {this.renderInputModal('displayFeedbackModal' ,'Please provide your feedback. You can also make a suggestion on how to make the app better.')}
        {this.renderInputModal('displayQuestionModal', 'Please provide a question you have about the functionality of the app. You can also ask a question about how something works.')}
        {this.renderInputModal('displayReportModal', 'Please report a bug in the app or a problem you have using it. Please provide as much details as possible to help the author identify or reproduce the issue and address it.')}
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    sendingMsgToAuthor: state.userData.sendingMsgToAuthor,
    sentMsgToAuthorSuccessfully: state.userData.sentMsgToAuthorSuccessfully,
  }
};

export default connect(mapStateToProps, { sendMsgToAuthor })(ContactContainer);
