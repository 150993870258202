import React, { Component } from 'react'
import { Platform, View, StatusBar, Image } from 'react-native';
import { Container, Content, Card, Form, Toast } from 'native-base'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SubmitButton } from '../../../common/components/submit-button';
import loginStyles from './login.styles';
import { connect } from 'react-redux';
import { initializePasswordRecovery } from '../../../redux/actions';
import signUpStyles from '../signup/signup.styles';
import SignUpService from '../signup/signup.service';
import { RootState } from '../../../redux/redux.typescript';
import { MatInput } from '../../../common/components/mat-input/mat-input.component';
import { AuthNavigationProps } from '../../../navigation/navigation';

interface Props extends AuthNavigationProps<'PasswordRecovery'> {
  readonly initializePasswordRecovery: (emailTrimmed: string) => void;
	readonly passwordRecoveryProcessing: boolean;
	readonly passwordRecoverySuccessful: boolean | null;
}

type State = {
	readonly email: string;
	readonly loading: boolean;
	readonly hasError: boolean;
	readonly valid: boolean | null;
}

class PasswordRecoveryPage extends Component<Props, State> {

	public static navigationOptions = {
		title: 'Reset Password',
    headerStyle: { backgroundColor: '#ffe57f'}
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
      hasError: false,
      valid: null
    };

    this.sendRecoveryEmail = this.sendRecoveryEmail.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if(!this.props.passwordRecoveryProcessing && prevProps.passwordRecoveryProcessing) {
      if (this.props.passwordRecoverySuccessful) {
        this.onSuccess();
			} else {
				this.setState({ loading: false });
			}
		}
	}

  public onSuccess = () => {
	  Toast.show({
		  duration: 5000,
		  text: 'Reset password email sent. Please check your email and check your junk folder just in case.',
		  type: 'success'
	  });

		this.setState({
			loading: false,
			email: ''
	    }, () => this.props.navigation.navigate('Login'),
	  );
  };

  public sendRecoveryEmail(): void {
    this.setState({
      loading: true
    });

		const emailTrimmed = this.state.email.trim();
    const emailIsValid = SignUpService.validateEmail(emailTrimmed);

    if (emailIsValid) {
      this.setState({ valid: true });
			this.props.initializePasswordRecovery(emailTrimmed);
    } else {
      this.setState({
        loading: false,
        valid: false
      });
    }

  };

  public render(){
    const { recoveryContainer, formContainer, form} = loginStyles;
		const { valid, email, loading } = this.state;

    return(
      <Container style={recoveryContainer}>
				{Platform.OS === 'ios' ? <StatusBar barStyle="dark-content" animated={true}/> : null}

				<KeyboardAwareScrollView
	        enableOnAndroid={true}
					keyboardShouldPersistTaps="handled"
					enableAutomaticScroll={true}
	        extraScrollHeight={0}
	        extraHeight={Platform.OS === 'ios' ? 24 : 140}
					keyboardOpeningTime={200}
				>
	        <Content>
		        <View style={[formContainer, { marginHorizontal: 16 }]}>
			        <View style={{flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
				        <Image source={require('../../../../assets/logo.png')} style={{ width: 250, height: 250 }}/>
			        </View>
              <Card style={signUpStyles.cardStyle}>
								<Form style={form}>
									<MatInput
										label="Email"
										keyword="email"
										onChange={val => this.setState({ email: val })}
										value={email}
										validation={valid}
										extraProps={{
											keyboardType: 'email-address',
										}}
									/>
								</Form>
              </Card>
		        </View>
		        <SubmitButton
			        text="Reset"
			        onPress={this.sendRecoveryEmail}
              disabled={email.length < 3}
              loading={loading}
		        />
	        </Content>
        </KeyboardAwareScrollView>
      </Container>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  const { passwordRecoveryProcessing, passwordRecoverySuccessful } = state.userData;

  return {
    passwordRecoveryProcessing, passwordRecoverySuccessful
  }
};

export default connect(mapStateToProps, { initializePasswordRecovery })(PasswordRecoveryPage);