export enum NotificationType {
  UserTopicAnswered,
  AnswerResponseReceived,
  PrivateChatStarted,
  PrivateChatResponseReceived,
  NewUserCreated,
}


export interface Notification extends ExpoNotification {
  id: string;
}

export interface ExpoNotification {
  type: NotificationType;
  timestamp: Date;
  notification: string;
  received: boolean;
  courseId?: string;
  topicId?: string;
  answerId?: string;
  chatId?: string;
  chatRoomId?: string;
  pageId?: string;
  questionPageId?: string;
}

export interface ExpoNotification {
  message: string;
  title: string;
  body: Notification;
}
