import React, { Component } from 'react';
import { Animated, Dimensions, Easing, Platform, Text, TextInput, View } from 'react-native';
import matInputStyles from './mat-input.styles';
import ValidationService from '../../../features/auth/signup/validation.service';
import { Icon, Item } from 'native-base';

const { container, input, textInputContainer } = matInputStyles;
const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);
const AnimatedItem = Animated.createAnimatedComponent(Item);
const AnimatedIcon = Animated.createAnimatedComponent(Icon);

type Props = {
	readonly backgroundColor: string;
	readonly label: string;
	readonly onChange: (data: string) => void;
	readonly value: string;
	readonly keyword: string;
	readonly validation: boolean | null;
	readonly empty?: boolean | null;
	readonly margin?: boolean;
	readonly errMsg?: string;
	readonly autoCompleteType?: string;
	readonly autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
	readonly textArea?: boolean;
	readonly bold?: boolean;
	readonly numberOfLines?: number;
	readonly invalidPassword?: boolean | null;
	readonly extraProps?: {};
	readonly icon?: string;
	readonly inputTextColor?: string;
	readonly iconAction?: () => void;
	readonly displayValidationIcons?: boolean;
	readonly activeColor?: string;
	readonly inactiveColor?: string;
};

const ACTIVE_COLOR = '#ffe57f';
const INACTIVE_COLOR = '#FFF';
const INACTIVE_VALUE = 0;
const ACTIVE_VALUE = 1;
const MAX_LINE_NUM = 4;
const INACTIVE_MARGIN_LEFT = 50;
const ACTIVE_MARGIN_LEFT = 8;
const LABEL_MARGIN_BOTTOM = -4;
const LABEL_ACTIVE_MARGIN_BOTTOM = -3;
const LABEL_PADDING_TOP = 15;
const LABEL_PADDING_BOTTOM = 4;
const LABEL_SM_PADDING_TOP = 4;
const LABEL_LG_FONT_SIZE = 15;
const LABEL_SML_FONT_SIZE = 11;
const INPUT_INACTIVE_EMPTY_HEIGHT = 15;
const INPUT_MAX_HEIGHT = 32;
const UNDERLINE_DIVISOR = 3;
const UNDERLINE_ACTIVE_MARGIN = 16;
const ICON_MARGIN_TOP_ACTIVE = -24;
const ICON_MARGIN_TOP_INACTIVE = -8;
const ICON_FLEX = .9;
const DURATION = 250;

export class MatInput extends Component<Props, { focus: boolean }> {
	private interpolatedColor: Animated.Value = new Animated.Value(INACTIVE_VALUE);
	private animatedUnderlineMarginLeft: Animated.Value = new Animated.Value(INACTIVE_MARGIN_LEFT);
	private animatedUnderlineLength: Animated.Value = new Animated.Value(0);
	private animatedLabelMarginBottom: Animated.Value = new Animated.Value(LABEL_MARGIN_BOTTOM);
	private animatedLabelPaddingTop: Animated.Value = new Animated.Value(LABEL_PADDING_TOP);
	private animatedLabelPaddingBottom: Animated.Value = new Animated.Value(LABEL_PADDING_BOTTOM);
	private animatedLabelFontSize: Animated.Value = new Animated.Value(LABEL_LG_FONT_SIZE);
	private animatedInputHeight: Animated.Value = new Animated.Value(INPUT_INACTIVE_EMPTY_HEIGHT);
	private animatedIconMarginTop: Animated.Value = new Animated.Value(ICON_MARGIN_TOP_INACTIVE);
	private textInputRef = React.createRef();
	private displayErrMsg: boolean;
	private inputWidth: number;

	constructor(props: Props) {
		super(props);

		this.state = {
			focus: false,
		};

		this.containerOnPress = this.containerOnPress.bind(this);
		this.onFocusHandler = this.onFocusHandler.bind(this);
		this.measureView = this.measureView.bind(this);
		this.displayErrMsg = false;
		this.inputWidth = 0;
	}

	private onFocusHandler(focus: boolean): void {
		if (!focus && !this.displayErrMsg) {
			this.displayErrMsg = true;
		}
		this.setState({ focus });
		this.interpolatedColor = new Animated.Value(INACTIVE_VALUE);
		this.animatedUnderlineLength = new Animated.Value((this.inputWidth / UNDERLINE_DIVISOR));

		let animationArray: Animated.CompositeAnimation[] = [
			Animated.timing(this.interpolatedColor, {
				duration: DURATION,
				easing: Easing.in(Easing.ease),
				toValue: ACTIVE_VALUE,
				useNativeDriver: false,
			}),
			Animated.timing(this.animatedUnderlineLength, {
				duration: DURATION,
				toValue: focus ? this.inputWidth - UNDERLINE_ACTIVE_MARGIN : 0,
				useNativeDriver: false
			}),
		];

		if (focus) {
			animationArray.push(Animated.timing(this.animatedUnderlineMarginLeft, {
				duration: DURATION,
				toValue: focus ? ACTIVE_MARGIN_LEFT : INACTIVE_MARGIN_LEFT,
				useNativeDriver: false
			}));
		} else {
			this.animatedUnderlineLength = new Animated.Value(0);
			this.animatedUnderlineMarginLeft = new Animated.Value(INACTIVE_MARGIN_LEFT);
		}

		if (this.props.value === '') {
			animationArray = animationArray.concat([
				Animated.timing(this.animatedLabelFontSize, {
					duration: DURATION,
					toValue: focus ? LABEL_SML_FONT_SIZE : LABEL_LG_FONT_SIZE,
					useNativeDriver: false
				}),
				Animated.timing(this.animatedLabelMarginBottom, {
					duration: DURATION,
					toValue: focus ? LABEL_ACTIVE_MARGIN_BOTTOM : LABEL_MARGIN_BOTTOM,
					useNativeDriver: false
				}),
				Animated.timing(this.animatedLabelPaddingBottom, {
					duration: DURATION,
					toValue: focus ? 0 : LABEL_PADDING_BOTTOM,
					useNativeDriver: false
				}),
				Animated.timing(this.animatedInputHeight, {
					duration: DURATION,
					toValue: focus ? INPUT_MAX_HEIGHT : INPUT_INACTIVE_EMPTY_HEIGHT,
					useNativeDriver: false
				}),
				Animated.timing(this.animatedLabelPaddingTop, {
					duration: DURATION,
					toValue: focus ? LABEL_SM_PADDING_TOP : (LABEL_PADDING_TOP + 1),
					useNativeDriver: false
				}),
				Animated.timing(this.animatedIconMarginTop, {
					duration: DURATION,
					toValue: focus ? ICON_MARGIN_TOP_ACTIVE : ICON_MARGIN_TOP_INACTIVE,
					useNativeDriver: false
				}),
			]);
		}

		Animated.parallel(animationArray)
			.start();
	}

	private measureView(event: any): void {
		this.inputWidth = event.nativeEvent.layout.width;
	}

	private containerOnPress(): void {
		this.textInputRef.current?.focus();
	}

	public render(): JSX.Element {
		const {onChange, label, value, autoCapitalize = 'none', autoCompleteType = 'off', keyword, empty = null, icon = null, validation = null,
			textArea = false, margin = false, inputTextColor, backgroundColor, activeColor, inactiveColor, errMsg = '', bold = false, displayValidationIcons = true, invalidPassword = null, iconAction, numberOfLines = MAX_LINE_NUM, textContentType = 'none', extraProps = {}} = this.props;
		let valid: boolean | null = validation;
		let invalid = !validation && validation !== null;
		let iconValidation = <Text />;
		const showSuccess = (keyword !== 'zip');

		const labelColor = this.interpolatedColor ? this.interpolatedColor.interpolate({
			inputRange: [INACTIVE_VALUE, ACTIVE_VALUE],
			outputRange: [inactiveColor || INACTIVE_COLOR, activeColor || ACTIVE_COLOR],
		}) : 'INACTIVE_COLOR';

		const labelStyles = {
			color: this.state.focus ? labelColor : (inactiveColor || 'white'),
			fontFamily: 'Roboto',
			fontSize: this.animatedLabelFontSize,
			marginBottom: this.animatedLabelMarginBottom,
			paddingBottom: 5,
			paddingLeft: 10,
			paddingTop: this.animatedLabelPaddingTop,
		};

		const underlineStyles = {
			backgroundColor: '#ffe57f',
			height: 2,
			marginBottom: 4,
			marginLeft: this.animatedUnderlineMarginLeft,
			width: this.animatedUnderlineLength,
		};

		const iconStyle = {
			marginBottom: this.animatedIconMarginTop,
			paddingBottom: 0,
			paddingRight: 8,
		};

		if (empty && invalidPassword) {
			valid = !empty && !invalidPassword;
			invalid = empty || invalidPassword;
		} else {
			valid = validation;
			invalid = (!validation && validation !== null)
		}

		if(icon) {
			if(invalid && displayValidationIcons) {
				iconValidation = <AnimatedIcon name="close-circle" style={{color: '#ff4081', marginBottom: this.animatedIconMarginTop, marginRight: -20 }}/>;
			}

			if(valid && showSuccess && displayValidationIcons) {
				iconValidation = <AnimatedIcon name="checkmark-circle" style={{color: '#FFB300', marginBottom: this.animatedIconMarginTop, marginRight: -20 }} />;
			}
		}

		if (Platform.OS === 'web') {
			const screenWidth = Dimensions.get('screen')?.width;
			let width = 250;

			if (Platform.OS === 'web' && screenWidth > 1000) {
				width = 500;
			}
			 return (
				 <View >
					 <Item
						 inlineLabel
						 onPress={this.containerOnPress}
						 bordered
						 onLayout={this.measureView}
						 style={[container, { marginBottom: margin ? ACTIVE_MARGIN_LEFT : 0, borderBottomWidth: 0 }, backgroundColor && { backgroundColor }, { marginTop: 16 }]}
						 success={valid}
						 error={invalid}
					 >
						 <View style={{flex: 1, flexDirection: 'row', alignItems: 'flex-end', marginVertical: 4 }}>
							 <View style={{flex: 1}}>
								 <Text style={[labelStyles, { color: '#ffe57f' }, bold && { fontWeight: 'bold', fontFamily: 'Roboto_bold', letterSpacing: 1} ]}>
									 {label}
								 </Text>
							 </View>
							 {icon ? <View style={{ marginLeft: 16, flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 4, paddingBottom: 4 }}>
								 <Icon
									 active
									 name={icon}
									 onPress={iconAction}
									 style={{
										 ...iconStyle,
										 color: '#ffe57f'
									 }}
								 />
							 </View> : null}
							 {icon ? iconValidation : null}
						 </View>
						 <View style={textInputContainer}>
							 <TextInput
								 {...extraProps}
								 autoCapitalize={autoCapitalize}
								 autoCompleteType={autoCompleteType}
								 autoCorrect={textArea}
								 enablesReturnKeyAutomatically
								 label={label}
								 multiline={textArea}
								 numberOfLines={textArea ? numberOfLines : 1}
								 onBlur={this.onFocusHandler.bind(this, false)}
								 onChangeText={onChange}
								 onFocus={this.onFocusHandler.bind(this, true)}
								 ref={this.textInputRef}
								 returnKeyType="done"
								 spellCheck={textArea}
								 style={[input, inputTextColor && { color: inputTextColor }, {  flex: iconAction ? .9 : 1, paddingRight: icon ? 24 : 0, height: 50 }, inactiveColor && { borderColor: inactiveColor }, {width}]}
								 textContentType={textContentType}
								 underlineColorAndroid="transparent"
								 value={value}
							 />
							 {icon || !displayValidationIcons ? null : ValidationService.renderValidationIcon(valid, invalid)}
						 </View>
					 </Item>
					 {ValidationService.renderSignupErrorMsg(keyword, invalid, empty, invalidPassword === null ? invalid : invalidPassword, (value.length > 0 && value.length < 100) )}
				 </View>
			 )
		}
		return (
			<View >
				<AnimatedItem
					inlineLabel
					onPress={this.containerOnPress}
					bordered
					onLayout={this.measureView}
					style={[container, { marginBottom: margin ? ACTIVE_MARGIN_LEFT : 0, borderBottomWidth: 0 }, backgroundColor && { backgroundColor }]}
					success={valid}
					error={invalid}
				>
					<View style={{flex: 1, flexDirection: 'row', alignItems: 'flex-end'}}>
						<View style={{flex: .75,}}>
							<Animated.Text style={[labelStyles, bold && { fontWeight: 'bold', fontFamily: 'Roboto_bold', letterSpacing: 1} ]}>
								{label}
							</Animated.Text>
						</View>
						{icon ? <View style={{flex: .25, flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 4, paddingBottom: 4 }}>
							<AnimatedIcon
								active
								name={icon}
								onPress={iconAction}
								style={{
									...iconStyle,
									color: this.state.focus ? labelColor : 'white'
								}}
							/>
						</View> : null}
						{icon ? iconValidation : null}
					</View>
					<View style={textInputContainer}>
						<AnimatedTextInput
							{...extraProps}
							autoCapitalize={autoCapitalize}
							autoCompleteType={autoCompleteType}
							autoCorrect={textArea}
							enablesReturnKeyAutomatically
							label={label}
							multiline={textArea}
							numberOfLines={textArea ? numberOfLines : 1}
							onBlur={this.onFocusHandler.bind(this, false)}
							onChangeText={onChange}
							onFocus={this.onFocusHandler.bind(this, true)}
							ref={this.textInputRef}
							returnKeyType="done"
							spellCheck={textArea}
							style={[input, inputTextColor && { color: inputTextColor }, { minHeight: this.animatedInputHeight, flex: iconAction ? .9 : 1, paddingRight: icon ? 24 : 0, maxHeight: 150 }, inactiveColor && { borderColor: inactiveColor }]}
							textContentType={textContentType}
							underlineColorAndroid="transparent"
							value={value}
						/>
						{icon || !displayValidationIcons ? null : ValidationService.renderValidationIcon(valid, invalid)}
					</View>
					<Animated.View style={underlineStyles} />
				</AnimatedItem>
				{ValidationService.renderSignupErrorMsg(keyword, invalid, empty, invalidPassword === null ? invalid : invalidPassword, (value.length > 0 && value.length < 100) )}
			</View>
		);
	}
}
