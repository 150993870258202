import React, { FC } from 'react';
import { Platform, Text, View } from 'react-native';
import { Accordion, Body, CardItem, H1, Icon, List, ListItem, Right } from 'native-base';
import { OrganizedForumResponseByCourse } from '../../../models/user';
import { ACTION_COLOR, PRIMARY_COLOR } from '../../../common/global.styles';
import { ChatRoomStackParamList } from '../../../navigation/navigation';
import { StackNavigationProp } from '@react-navigation/stack';

type Props = {
  responsesByCourse: OrganizedForumResponseByCourse[];
  navigation: StackNavigationProp<ChatRoomStackParamList, 'Forum'>;
}

export const ResponsesTab: FC<Props> = ({ responsesByCourse, navigation }: Props): JSX.Element => {
  function renderTopicsHeader(item: OrganizedForumResponseByCourse, expanded: boolean): JSX.Element {
    let bodyText = <Text style={{ fontWeight: expanded ? '800' : '600', fontSize: 15, paddingLeft: 8, paddingRight: 16, textAlign: 'left', lineHeight: 20 }}>{item.topic}</Text>;

    if (item.courseTitle) {
     bodyText = (
       <View>
        <Text style={{ fontWeight: '800', fontSize: 16, textAlign: 'center', lineHeight: 20, paddingBottom: 8 }}>Topics in "{item.courseTitle}"</Text>
         <Text style={{ fontWeight: expanded ? '800' : '600', fontSize: 15, paddingLeft: 8, paddingRight: 16, textAlign: 'left', lineHeight: 20 }}>{item.topic}</Text>
       </View>
     );
    }

    return (
      <View style={[{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        padding: 8,
        alignItems: 'center' ,
        backgroundColor: '#FFC400',
      }, expanded ? {borderColor: '#FFAB00', borderWidth: 1}: null]}>
        <View style={{flex: .90}}>
          {bodyText}
        </View>
        <View style={{flex: .10, justifyContent: 'flex-end', paddingLeft: 8}}>
          {expanded ? <Icon name={Platform.OS === 'ios' ? 'ios-arrow-up' : 'md-arrow-up'} style={{ fontSize: 20, color: ACTION_COLOR }} />
            : <Icon name={Platform.OS === 'ios' ? 'ios-arrow-down' : 'md-arrow-down'} style={{ fontSize: 20, color: ACTION_COLOR}} />
          }
        </View>
      </View>
    )
  }

  function renderTopicsContent (item: OrganizedForumResponseByCourse): JSX.Element {
    return (
      <View style={{ backgroundColor: '#ffe57f'}}>
        <List>
          <ListItem>
            <Text>Conversations inspired by the following statements:</Text>
          </ListItem>
          {item.answers.map((record, index) => {
            if(record) {
              return (
                <ListItem
                  onPress={() => navigation.navigate('ChatRoom', {
                    courseId: record.responses[0].courseId,
                    topicId: record.responses[0].topicId,
                    answerId: record.responses[0].answerId,
                    isChapterQuestion: record.responses[0].topicId.includes('chapter'),
                    questionPageId: record.responses[0].pageId || ''
                  })}
                  key={index}>
                    <Body style={{marginRight: -36}}>
                      <Text style={{ fontWeight: 'bold',fontSize: 14, textAlign: 'center' }}>"{record.answer}"</Text>
                    </Body>
                    <Right >
                      <Icon name="arrow-forward" style={{color: ACTION_COLOR}} />
                    </Right>
                </ListItem>
              )
            }
          })}
        </List>
      </View>
    )
  }

  if (!responsesByCourse?.length) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#474646'}}>
        <H1 style={{ color: PRIMARY_COLOR, padding: 24, textAlign: 'center'}}>{`You have not responded to any posts.\n\nYou can make a response by visiting a course forum and making a comment on someone's shared answer.`}</H1>
      </View>
    );
  }

  return (
    <View style={[{flex: 1, margin: 0, padding: 16, backgroundColor: '#474646', alignItems: 'center'}]}>
      <CardItem style={[{ backgroundColor: '#5f5f5f', marginBottom: -10 }, Platform.OS === 'web' && { width: '50%', minWidth: 350 }]}>
        <Accordion
          dataArray={responsesByCourse}
          renderHeader={renderTopicsHeader}
          renderContent={renderTopicsContent}
          expanded={[]}
        />
      </CardItem>
    </View>
  )
};