
import * as React from 'react';

export class NavigationService {
    static _navigator = React.createRef();
    //
    static resetStack(data) {
        console.log('navigate', data)
        this._navigator.current?.resetRoot(data)
    }

    // static navigate(routeName: string, params: {}) {
    //     this._navigator.dispatch(
    //         NavigationActions.navigate({
    //             routeName,
    //             params
    //         })
    //     )
    // }
    //
    // static resetProfileParams(key: string) {
    //     const setParamsAction = NavigationActions.setParams({
    //         params: {userId: '', searchMode: false},
    //         key
    //     });
    //
    //     this._navigator.dispatch(setParamsAction);
    //
    // }
    //
    // static goBack() {
    //     this._navigator.dispatch(
    //         NavigationActions.back()
    //     )
    // }

    static setTopLevelNavigator(navigatorRef: any) {
        this._navigator = navigatorRef;
    }

	// gets the current screen from navigation state
  //   static getActiveRouteName(navigationState: NavigationState | NavigationRoute<NavigationParams>): string | null {
  //       if (!navigationState) {
  //           return null;
  //       }
  //       const route = navigationState.routes[navigationState.index];
  //       // dive into nested navigators
  //       if (route.routes) {
  //           return this.getActiveRouteName(route);
  //       }
  //       return route.routeName;
  //   }
}
