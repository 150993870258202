import { createSelector } from 'reselect';
import { typescriptFind } from '../../common/helper-methods';
import { Chapter, Course, ForumAnswers, ForumResponse, ForumTopic } from '../../models/course';
import { RootState } from '../redux.typescript';
import { NewChapter } from '../../models/newChapter';
import { uniq } from 'lodash';

export const getCoursesState = (store: RootState) => store.coursesData.courses;
export const getCurrentCourseId = (store: RootState) => store.coursesData.currentCourseId;
export const getCurrentCourse = (store: RootState): Course => {
  return typescriptFind(store.coursesData.courses.find((course: Course) => course.id === store.coursesData.currentCourseId));
};

export const getCurrentCoursePublisher = createSelector(getCurrentCourse, (course: Course) => {
  return `${course?.publisherFirstName} ${course?.publisherLastName}`;
});

export const getCurrentCourseTitle = createSelector(getCurrentCourse, (course: Course) => {
  return `${course?.title}`;
});

export const getCourseChapters = createSelector(getCurrentCourse, (course: Course) => {
  return course.chapters;
});

export const getChapter = createSelector(getCurrentCourse, (course: Course) => {
  return course.chapters;
});

export const getCourseById = (store: RootState, id: string, updateDatabase = false): Course => {
  if (getCoursesState(store)) {
    // const db = getFirestore(firebaseApp);
    const course = getCoursesState(store).find((course: Course) => course.id === id);
    let courseTotalQuestionCount = 0;

    if (course && !course?.questionsCount) {
      course?.chapters?.forEach(chapter => {
        let chapterTotalQuestionCount = 0;
        chapter.sectionsData?.forEach(section => {
          section.pages?.forEach(page => {
            if (page?.question) {
              chapterTotalQuestionCount++;
              courseTotalQuestionCount++;
            }
          })
        });
        // if (updateDatabase) {
        //   console.log('update chapter', chapter.id)
        //   const chapterRef = doc(db, 'courses', id, 'chapters', `${chapter.id}`);
        //   void updateDoc(chapterRef, { questionsCount: chapterTotalQuestionCount });
        // }
        chapter.questionsCount = chapterTotalQuestionCount;
      });
      // if (updateDatabase) {
      //   const courseRef = doc(db, 'courses', id);
      //   void updateDoc(courseRef, { questionsCount: courseTotalQuestionCount });
      // }
      course.questionsCount = courseTotalQuestionCount;
    }

    return course ? course : new Course();
  } else {
    return new Course();
  }
};

export const getChapterById = (store: RootState): Chapter => {
  const { currentCourseId, currentChapterId }  = store.coursesData;
  return getCoursesState(store) ?
    getCourseById(store, currentCourseId)?.chapters?.[currentChapterId - 1] ?? new NewChapter() : new NewChapter();
};

export const getTopicOrQuestionDataById = (store: RootState, courseId: string, topicId: string, isQuestion: boolean, questionPageId?: string): ForumTopic | {answers: ForumAnswers, question: string } => {
  if (getCourseById(store, courseId)) {
    const course = getCourseById(store, courseId);
    if (isQuestion) {
      if(!course.forumChQuestionTopics) {
        return null;
      }
      const chapterQuestionData = course?.forumChQuestionTopics?.find(record => record.id === topicId);
      if (!chapterQuestionData?.questions) {
        return null;
      }

      const question = chapterQuestionData?.questions.find(record => record.pageId === questionPageId).question;

      if(!chapterQuestionData.answers) {
        return null;
      }

      const answers = chapterQuestionData.answers.filter(record => record.pageId === questionPageId);
      return {
        question,
        answers,
      }
    }
    return course.forumTopics ? course.forumTopics.find(record => record.id === topicId) : null;
  } else {
    return {
      id,
      topic: '',
      answers: [],
    }
  }
};

export const getAnswerCountForChapter = (store: RootState, courseId: string, topicId: string, isQuestion: boolean): Map<string, number> => {
  if (getCourseById(store, courseId)) {
    const course = getCourseById(store, courseId);
    if (isQuestion) {
      if(!course.forumChQuestionTopics) {
        return null;
      }
      const chapterQuestionData = course.forumChQuestionTopics.find(record => record.id === topicId);
      if (!chapterQuestionData.answers) {
        return null;
      }

      const pageIdList = uniq(chapterQuestionData.answers.map(record => record.pageId));
      const pageAnswerCountMap: Map<string, number> = new Map();

      pageIdList.forEach(pageId => {
        pageAnswerCountMap.set(pageId, chapterQuestionData.answers.filter(record => record.pageId === pageId).length);
      });
      return pageAnswerCountMap;
    }

    // return course.forumTopics ? course.forumTopics.find(record => record.id === topicId) : null;
    return new Map();
  } else {
    return new Map();
  }
};

export const getAnswerByIdForChatRoom = (store: RootState, courseId: string, topicId: string, answerId: string, isQuestion: boolean, questionPageId: string = ''): ForumAnswers => {
  if (getTopicOrQuestionDataById(store, courseId, topicId, isQuestion, questionPageId )) {
    const record = getTopicOrQuestionDataById(store, courseId, topicId, isQuestion, questionPageId);
    if (!record.answers) {
      return null;
    }
    const matchingAnswer = record.answers.find((answer) => answer.id === answerId);
    const matchingAnswerCopy = {...matchingAnswer};

    matchingAnswerCopy.responses = matchingAnswerCopy.responses ? matchingAnswer.responses.map((record: ForumResponse) => {
      if(record.userId) {
        return {
          _id: record.id || record.responseId,
          text: record.response,
          createdAt: record.timeStamp,
          user: { _id: record.userId, name: `${record.firstName} ${record.lastName}`, avatar: record?.profilePhotoUrl ?? ''},
          originalData: record,
        }
      } else {
        return record;
      }

    }) : [];

    if (matchingAnswerCopy.responses.length) {
      matchingAnswerCopy.responses = matchingAnswerCopy.responses.sort((a: any, b: any) => b.createdAt - a.createdAt);
    }

    return matchingAnswerCopy;
  } else {

    return {
      answer: '',
      firstName: '',
      lastName: '',
      userId: '',
      timeStamp: null,
      pageId: '',
      chapterId: 0,
      courseId: '',
      id: '',
      responses: [],
      responseCount: 0,
    }
  }
};
