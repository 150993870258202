import React, { FC, useEffect, useState } from 'react';
import { GiftedChat } from 'react-native-gifted-chat'
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, Image, Alert, LayoutAnimation, TouchableOpacity } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import {
  deleteForumResponse, editForumResponse, fetchCourseForumApi, fetchForumAnswersApi, fetchResponsesApi, submitForumResponse
} from '../../../redux/actions';
import { ForumAnswers } from '../../../models/course';
import { RootState } from '../../../redux/redux.typescript';
import { getAnswerByIdForChatRoom } from '../../../redux/selectors';
import { Form, H1, H3, Textarea } from 'native-base';
import { Spinner} from '../../../common/components/spinner/spinner.component';
import { getAuth } from 'firebase/auth';
import { ContentNavigationProps } from '../../../navigation/navigation';
import { InputModal } from '../../../common/components/input-modal-component';
import { PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../common/global.styles';
import { SubmitButton } from '../../../common/components/submit-button';
import { generateColor } from '../../../common/helper-methods';
import { ProfileModal } from '../../profile/profile-modal.component';

export const ChatPage: FC<ContentNavigationProps<'CourseChatRoom'>> = ({ navigation, route }: ContentNavigationProps<'CourseChatRoom'>): JSX.Element => {
  const courseId = route.params?.courseId ?? '';
  const topicId = route.params?.topicId ?? '';
  const questionPageId = route.params?.questionPageId ?? '';
  const answerId = route.params?.answerId ?? '';
  const isChapterQuestion = route.params?.isChapterQuestion ?? false;
  const [displayUserProfile, setDisplayUserProfile]: [{ id: string, name: string; }, any] = useState({ id: '', name: '',});
  const dispatch = useDispatch();
  const { currentUser } = getAuth();
  const forumAnswer: ForumAnswers = useSelector((state: RootState) => getAnswerByIdForChatRoom(state, courseId, topicId, answerId, isChapterQuestion, questionPageId));
  const fetchingForumResponses: boolean = useSelector((state: RootState) => state.coursesData.fetchingForumResponses);
  const profilePhotoUrl: string = useSelector((state: RootState) => state.userData.profilePhotoUrl);
  const [editResponseState, setEditResponseState] = useState({
    response: '',
    responseId: ''
  });

  useEffect(() => {
    if(!forumAnswer || forumAnswer.courseId || !forumAnswer.id) {
      dispatch(fetchCourseForumApi(courseId));
      dispatch(fetchForumAnswersApi(courseId, topicId));
    }
    dispatch(fetchResponsesApi(topicId,answerId, courseId));
  }, []);

  function onSend(messages = []): void {
    messages.forEach((msg) => {
      dispatch(submitForumResponse({ forumTopicId: topicId, answerId, response: msg.text, answer: forumAnswer.answer.trim(), responseId: '', courseId }));
    });
  }

  function _longPressHandler(context, data): void {

    let options = [
      'Copy Text',
      'Start Private "Live" Chat Thread',
      'Cancel',
    ];

    let callback = buttonIndex => {
      switch (buttonIndex) {
        case 0:
          void Clipboard.setStringAsync(data?.text);
          Alert.alert('Copied', 'This message was copied to your clipboard.')
          return;
        case 1:
          navigation.navigate('PrivateCourseChatRoom', {
            newChatRoomData: data.originalData, new: true,
          });
          return;
        default:
      }
    }

    if (data.user._id === currentUser.uid) {
      options = [
        'Copy Text',
        'Edit Response',
        'Delete Response',
        'Cancel',
      ];

      callback = buttonIndex => {
        switch (buttonIndex) {
          case 0:
            void Clipboard.setStringAsync(data?.text);
            Alert.alert('Copied', 'This message was copied to your clipboard.')
            break;
          case 1:
            setEditResponseState({
              response: data?.text,
              responseId: data?._id,
            })
            break;
          case 2:
            dispatch(deleteForumResponse({
              responseId: data?._id,
              forumTopicId: topicId,
              answerId,
            }));
            break;
          default:
        }
      }
    }

    const cancelButtonIndex = options.length - 1;
    context.actionSheet().showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      callback,
    )
  }

  function renderResponseEditModal() {
    const { responseId, response } = editResponseState;
    return (
      <InputModal displayModal={responseId.length > 0}>
        <Form style={{margin: 16}}>
          <Textarea
            value={response}
            onChangeText={value => setEditResponseState({...editResponseState, response: value})}
            rowSpan={7}
            multiline={true}
            style={{color: PRIMARY_COLOR, fontSize: 16 }}
            autoCapitalize="sentences"
            autoCorrect={true}
            spellCheck={true}/>
        </Form>
        <View style={{marginTop: 16}}>
          <SubmitButton
            loading={false}
            disabled={response.length < 2}
            onPress={() => {
              LayoutAnimation.linear();
              dispatch(editForumResponse({
                response,
                responseId,
                forumTopicId: topicId,
                answerId
              }))
              setEditResponseState({ response: '', responseId: '' });
            }}
            text="update"
          />
          <SubmitButton
            text="cancel"
            color={PRIMARY_COLOR_ACCENT}
            onPress={() => setEditResponseState({ response: '', responseId: '' })}/>
        </View>
      </InputModal>
    )
  }

  if (fetchingForumResponses || !forumAnswer) {
    return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#474646'}}><Spinner size="large"/></View>;
  }

  if (!forumAnswer?.id) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#474646'}}>
        <H1 style={{ color: PRIMARY_COLOR}}>Sorry, the data no longer exists</H1>
      </View>
    );
  }


  return (
    <View style={{flex: 1}}>
      <H3 style={{ margin: 16, fontSize: 16 }}>{forumAnswer.firstName} {forumAnswer.lastName} said, "{forumAnswer.answer?.trim()}"</H3>
      <GiftedChat
        messages={forumAnswer.responses}
        onSend={messages => onSend(messages)}
        user={{
          _id: currentUser.uid,
        }}
        showUserAvatar
        renderAvatar={(data) => {
          const { _id, avatar, name } = data.currentMessage.user;
          const isUser = _id === currentUser?.uid;
          const source = isUser ? profilePhotoUrl : avatar;

          if (source) {
            return (
              <TouchableOpacity
                onPress={() => setDisplayUserProfile({ id: _id, name })}
                style={{ flex: 1 }} key={_id}
              >
                <Image source={{ uri: source}} style={{ width: 48, height: 48, borderRadius: 24 }} />
              </TouchableOpacity>
            );
          }
          const nameSplit = name.split(' ');
          return (
            <TouchableOpacity
              onPress={() => setDisplayUserProfile({ id: _id, name })}
              style={{ backgroundColor: generateColor(), maxHeight: 48, width: 48, borderRadius: 24, justifyContent: 'center', flex: 1, alignItems: 'center' }}>
              <Text style={{ fontSize: 20 }}>{nameSplit?.[0]?.[0]}{nameSplit?.[1]?.[0]}</Text>
            </TouchableOpacity>
          )
        }}
        isAnimated
        onLongPress={(context, data) => _longPressHandler(context, data)}
      />
      {renderResponseEditModal()}
      <ProfileModal
        userId={displayUserProfile.id}
        userName={displayUserProfile.name}
        onClose={() => setDisplayUserProfile({ id: '', name: '' })}
      />
    </View>
  )
}