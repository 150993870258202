import { ActionType, getType } from 'typesafe-actions';
import * as signUpActions from '../actions/sign-up.actions';

export type SignUpReducerState = Readonly< {
    authProfileBeingUpdated: boolean;
    authProfileUpdateSuccess: boolean | null;
    confirmPassword: string;
    confirmPasswordValid: boolean | null;
    email: string;
    emailConfirmation: string;
    emailConfirmationValid:  boolean | null;
    emailValid: boolean | null;
    enrollmentReason: string;
    enrollmentReasonValid: boolean | null;
    firstName: string;
    firstNameValid: boolean | null;
    formInvalid: boolean | null;
    lastName: string;
    lastNameValid: boolean | null;
    password: string;
    passwordConfirmationNotMissing: boolean | null;
    passwordNotMissing: boolean | null;
    passwordValid: boolean | null;
    signUpBeingProcessed: boolean;
    signUpFormBeingValidated: boolean;
    signUpFormValidated: boolean | null;
    signUpSuccess: boolean | null;
    userAccountBeingCreated: boolean;
    userAccountCreated: boolean | null;
    userDatabaseEntryBeingCreated: boolean;
    userDatabaseEntryCreatedSuccess: boolean | null;
}>;

const INITIAL_STATE: SignUpReducerState = {
	// Form Values
	email: '',
	emailConfirmation: '',
	enrollmentReason: '',
	enrollmentReasonValid: null,
	firstName: '',
	lastName: '',
	password: '',
	confirmPassword: '',
	// Validation
    emailValid: null,
	emailConfirmationValid: null,
    firstNameValid: null,
    lastNameValid: null,
    passwordValid: null,
    confirmPasswordValid: null,
    passwordNotMissing: null,
    passwordConfirmationNotMissing: null,
    // Overall Sign up
    signUpBeingProcessed: false,
	signUpSuccess: null,

	// Step 1: Validation
    signUpFormBeingValidated: false,
    signUpFormValidated: false,
	formInvalid: null,
	// Step 2: Registration
    userAccountBeingCreated: false,
    userAccountCreated: false,
    // Step 3.a: Profile Name update
    authProfileBeingUpdated: false,
    authProfileUpdateSuccess: null,
    // Step 3.b: User Data Entry
    userDatabaseEntryBeingCreated: false,
    userDatabaseEntryCreatedSuccess: null
};

export type SignUpAction = ActionType<typeof signUpActions>;

const { fieldIsValid, firstNameValueChanged, enrollmentReasonChanged, lastNameValueChanged,
    emailValueChanged, emailConfirmationValueChanged, passwordValueChanged, confirmPasswordValueChanged,
    initValidation, signUpSuccess, validateFormAsync, createUserAuthAsync,
    authProfileUpdateAsync, emailVerificationAsync, createUserDataCollectionAsync } = signUpActions;

export default (state = INITIAL_STATE, action : SignUpAction): SignUpReducerState => {
    switch(action.type){
        case getType(firstNameValueChanged):
            return {...state, firstName: action.payload};
	    case getType(lastNameValueChanged):
		    return {...state, lastName: action.payload};
		case getType(enrollmentReasonChanged):
		    return {...state, enrollmentReason: action.payload};
        case getType(emailValueChanged):
            return {...state, email: action.payload};
		case getType(emailConfirmationValueChanged):
			return {...state, emailConfirmation: action.payload};
        case getType(passwordValueChanged):
            return {...state, password: action.payload};
        case getType(confirmPasswordValueChanged):
            return {...state, confirmPassword: action.payload};
		case getType(initValidation):
		    return {
					...state,
				signUpBeingProcessed: true,
				signUpFormBeingValidated: true,
				};
		case getType(fieldIsValid):
			return { ...state,
				[action.payload]: true};
		case getType(validateFormAsync.request): {
			const {firstNameValid, lastNameValid, emailValid, emailConfirmationValid, enrollmentReasonValid,
				passwordValid, passwordNotMissing, confirmPasswordValid, passwordConfirmationNotMissing} = action.payload;
			return {
				...state,
				emailValid,
				emailConfirmationValid,
				firstNameValid,
				lastNameValid,
				passwordValid,
				passwordNotMissing,
				confirmPasswordValid,
				passwordConfirmationNotMissing,
                enrollmentReasonValid
			}
		}
	    case getType(validateFormAsync.success):
		    return {
			    ...state,
				signUpFormBeingValidated: false,
				signUpFormValidated: true,
				formInvalid: false
		    };
		case getType(validateFormAsync.failure):
			return {...state,
				signUpFormBeingValidated: false,
				formInvalid: true,
				signUpFormValidated: false,
				signUpBeingProcessed: false,
				signUpSuccess: false
			};
		// Account Creation
        case getType(createUserAuthAsync.request):
            return {...state,
                userAccountBeingCreated: true
            };
        case getType(createUserAuthAsync.success):
            return {
                ...state,
				userAccountBeingCreated: false,
				userAccountCreated: true
            };
		case getType(createUserAuthAsync.failure):
			return {
				...state,
				userAccountCreated: false,
				userAccountBeingCreated: false,
				signUpBeingProcessed: false,
				signUpSuccess: false
			};
		// Auth Display Name
		case getType(authProfileUpdateAsync.request):
			return {...state,
				authProfileBeingUpdated: true,
			};
		case getType(authProfileUpdateAsync.success):
			return {
				...state,
				authProfileBeingUpdated: false,
				authProfileUpdateSuccess: true,
			};
		case getType(authProfileUpdateAsync.failure):
			return {
				...state,
				authProfileBeingUpdated: false,
				authProfileUpdateSuccess: false,
				signUpBeingProcessed: false,
				signUpSuccess: false
			};
		// Create user Database Entry
		case getType(createUserDataCollectionAsync.request):
			return {
				...state,
				userDatabaseEntryBeingCreated: true
			};

		case getType(createUserDataCollectionAsync.success):
			return {
				...state,
				userDatabaseEntryCreatedSuccess: true,
				userDatabaseEntryBeingCreated: false
			};
		case getType(createUserDataCollectionAsync.failure):
			return {
				...state,
				userDatabaseEntryBeingCreated: false,
				userDatabaseEntryCreatedSuccess: false,
				signUpBeingProcessed: false,
				signUpSuccess: false
			};
        case getType(signUpSuccess):
            return {
                ...state,
                signUpBeingProcessed: false,
                signUpSuccess: true,
				email: '',
				emailConfirmation: '',
				firstName: '',
				lastName: '',
				password: '',
				confirmPassword: '',
            };
        default:
            return state
    }
};
