import React, { FC, useEffect, useState } from 'react';
import { Body, Button, H1, H2, H3, Container, Content, Icon, List, ListItem, Fab, Form, Card } from 'native-base';
import { ACTION_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_ACCENT } from '../../../common/global.styles';
import {
  Dimensions, Image, Platform, ScrollView, Text, TouchableOpacity, View
} from 'react-native';
import { ChatRoom } from '../../course/components/forum/components/chat-room/chat-room.component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchForumAnswersApi, fetchOtherUserData, submitForumAnswer } from '../../../redux/actions';
import { RootState } from '../../../redux/redux.typescript';
import { getTopicOrQuestionDataById } from '../../../redux/selectors';
import { ForumAnswers, ForumTopic } from '../../../models/course';
import { InputModal } from '../../../common/components/input-modal-component';
import { AnimatedInput } from '../../../common/components/input.component';
import { SubmitButton } from '../../../common/components/submit-button';
import { ContentNavigationProps } from '../../../navigation/navigation';
import { ProfileModal } from '../../profile/profile-modal.component';

export const AnswersPage: FC<ContentNavigationProps<'CourseAnswers'>> = ({ navigation, route }: ContentNavigationProps<'CourseAnswers'>): JSX.Element => {
  const [newAnswerState, setNewAnswerState]: [string, any] = useState('');
  const [newAnswerModalState, setNewAnswerModalState]: [boolean, any] = useState(false);
  const [displayUserProfile, setDisplayUserProfile]: [{ id: string, name: string; }, any] = useState({ id: '', name: '',});
  const courseId = route.params?.courseId ?? '';
  const topicId = route.params?.topicId ?? '';
  const questionPageId = route.params?.questionPageId ?? '';
  const isChapterQuestion = route.params?.isChapterQuestion ?? false;
  const dispatch = useDispatch();
  const topicOrQuestionData: ForumTopic | {answers: ForumAnswers, question: string } = useSelector((state: RootState) => getTopicOrQuestionDataById(state, courseId, topicId, isChapterQuestion, questionPageId));
  const fontSize: number = useSelector((state: RootState) => state.userData.fontSize);

  useEffect(() => {
    if (!isChapterQuestion) {
      navigation.setParams({topic: topicOrQuestionData?.topic ? topicOrQuestionData.topic : ''});
      dispatch(fetchForumAnswersApi(courseId, topicId));
    }
    navigation.setParams({ question: topicOrQuestionData?.question ? topicOrQuestionData.question : ''})
  }, []);

  if (!topicOrQuestionData || !topicOrQuestionData.answers || topicOrQuestionData.answers?.length === 0) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#474646'}}>
        <H1 style={{ color: PRIMARY_COLOR}}>Sorry, no answers found</H1>
      </View>
    )
  }

  function navigateToChatPage(answerId){
    const fromRootForumScreen = route.params?.fromRootForumScreen ?? false;
    navigation.navigate(fromRootForumScreen ? 'ChatRoom' : 'CourseChatRoom', { topicId, courseId, answerId, isChapterQuestion, questionPageId });
  }

  function renderAnswerList(answer: ForumAnswers, index: number): JSX.Element {
    const timeString = typeof answer.timeStamp === 'string' ? answer.timeStamp : '';
    const updateTimeString = typeof answer.updateTimeStamp === 'string' ? answer.updateTimeStamp : '';
    const name = `${answer.firstName} ${answer.lastName}`;
    return (
      <ListItem noIndent key={index} style={{ backgroundColor: PRIMARY_COLOR, padding: 0, margin: 0, marginVertical: 8 }}>
        <Body>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setDisplayUserProfile({id: answer.userId, name })}>
              {answer?.profilePhotoUrl?.length > 0 && (
                <View style={{ marginBottom: 8, paddingRight: 8 }}>
                  <Image
                    source={{ uri: answer.profilePhotoUrl }}
                    style={{
                      height: 48,
                      width: 48,
                      borderRadius: 16,
                    }}
                  />
                </View>
              )}
              <Text style={{fontWeight: 'bold', marginBottom: 8, paddingHorizontal: 0, marginHorizontal: 0, fontSize: 18}}>{name}</Text>
            </TouchableOpacity>
            <View>
              {updateTimeString ? <Text style={{ marginRight: 8}}><Text style={{ fontWeight: 'bold'}}>Updated:</Text> {updateTimeString}</Text> : null}
              {timeString ? <Text style={{marginBottom: 8, marginRight: 8}}><Text style={{ fontWeight: 'bold'}}>Published:</Text> {timeString}</Text> : null}
            </View>
          </View>
          <Text style={{ fontSize, marginBottom: 8 }}>"{answer.answer.trim()}"</Text>
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: 8}}>
            <Text style={{fontSize: 14, paddingHorizontal: 8, fontWeight: 'bold', color: ACTION_COLOR }}>{answer.responseCount || 0} comment{answer.responseCount !== 1 ? 's' : ''}</Text>
            <Button iconRight onPress={navigateToChatPage.bind(this, answer.id)}>
              <Text style={{fontSize: 12, paddingHorizontal: 8, fontWeight: 'bold', color: 'white' }}>Open Chat</Text>
              <Icon name="chatbubbles"/>
            </Button>
          </View>
        </Body>
      </ListItem>
    )
  }

  function renderNewAnswerModal(): JSX.Element {
    return (
      <InputModal displayModal={newAnswerModalState}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 16 }}>
          <H2 style={{ color: 'white' }}>Add Your Answer</H2>
        </View>
        <Form>
          <AnimatedInput
            value = {newAnswerState}
            valid = {newAnswerState.length > 100}
            label="Statement"
            placeholder="Share thoughts on this topic..."
            errMsg="The answer needs to be at least 100 characters"
            textArea = {true}
            numberOfLines={8}
            onChange={setNewAnswerState}
          />
        </Form>
        <View style={{marginTop: 16}}>
          <SubmitButton
            loading={false}
            disabled={newAnswerState.length < 100}
            onPress={() => {
              dispatch(submitForumAnswer({ topic: (topicOrQuestionData as ForumTopic).topic , forumTopicId: topicId, answer: newAnswerState }));
              setNewAnswerState('');
              setNewAnswerModalState(false);
            }}
            text="submit"
          />
          <SubmitButton
            text="cancel"
            color={PRIMARY_COLOR_ACCENT}
            onPress={() => setNewAnswerModalState(false)}/>
        </View>
      </InputModal>
    )
  }

  return (
    <Container>
      <Content style={{flex: 1, paddingHorizontal: 8, marginLeft: 0, paddingTop: 8, paddingBottom: 32, backgroundColor: '#5f5f5f'}}>
        <Text style={{ color: 'white', fontFamily: 'Roboto', fontSize: 17 }}>Answers</Text>
        <List style={{ marginBottom: 64 }}>
        {(topicOrQuestionData.answers as ForumAnswers[]).map((record, index) => renderAnswerList(record, index))}
        </List>
      </Content>
      {!isChapterQuestion && (
        <Fab
          style={{backgroundColor: ACTION_COLOR}}
          position={Platform.OS === 'web' && Dimensions.get('screen').width > 500 ? "bottomLeft" : "bottomRight"}
          onPress={() => setNewAnswerModalState(true)}
        >
          <Icon name="add" style={Platform.OS === 'ios' ? {fontSize: 50, paddingTop: 28} :
            {fontSize: 34}}/>
        </Fab>
      )}
      {renderNewAnswerModal()}
      <ProfileModal
        userId={displayUserProfile.id}
        userName={displayUserProfile.name}
        onClose={() => setDisplayUserProfile({ id: '', name: '' })}
      />
    </Container>
  )
}
