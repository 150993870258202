import { Dispatch } from 'redux';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, User } from 'firebase/auth';

import { SignUpFormResults, ValidationFields } from '../../models/validation.model';
import SignUpService from '../../features/auth/signup/signup.service';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { AccountCreationObject, AccountDataObject, SignUpSuccessObject } from '../../models/user';
import * as Sentry from 'sentry-expo';
import { Toast } from 'native-base';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { firebaseApp } from '../../../App';
import { Platform } from 'react-native';

const fieldIsValid = createAction('VALIDATION_TRUE')<string>();
const firstNameValueChanged = createAction('FIRST_NAME_CHANGED')<string>();
const lastNameValueChanged = createAction('LAST_NAME_CHANGED')<string>();
const enrollmentReasonChanged = createAction('ENROLLMENT_REASON_CHANGED')<string>();
const emailValueChanged = createAction('EMAIL_CHANGED',
  (email: string) => email.trim(),
)();

const emailConfirmationValueChanged = createAction(
  'EMAIL_CONFIRMATION_CHANGED',
  (email: string) => email.trim(),

)();

const passwordValueChanged = createAction('PASSWORD_CHANGED')<string>();

const confirmPasswordValueChanged = createAction('CONFIRM_PASSWORD_CHANGED')<string>();

const initValidation = createAction('INITIALIZE_SIGN_UP_VALIDATION')<void>();

// VALIDATION
const processValidationResults = (validationResults: SignUpFormResults, authenticatedButNotRegistered: boolean): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(validateFormAsync.request(validationResults));

    const { firstNameValid, lastNameValid, enrollmentReasonValid, emailConfirmationValid,
      passwordValid, confirmPasswordValid, firstName, lastName, email,
      enrollmentReason, password } = validationResults;

    if (firstNameValid && lastNameValid && enrollmentReasonValid && emailConfirmationValid &&
      passwordValid && confirmPasswordValid ) {
      dispatch(validateFormAsync.success());
      dispatch(createUserAuth({email, password, firstName, lastName, enrollmentReason, authenticatedButNotRegistered }));
    } else {
      dispatch(validateFormAsync.failure());
    }

  }
};

const validateFormAsync = createAsyncAction(
  'PROCESS_VALIDATION_RESULTS',
  'VALID_SIGN_UP_SUBMISSION',
  'INVALID_SIGN_UP_SUBMISSION'
)<SignUpFormResults, void, void>();

// CREATE USER
const createUserAuth = ({ email, password, firstName, lastName, enrollmentReason, authenticatedButNotRegistered }: AccountCreationObject)
  : any =>
{
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(createUserAuthAsync.request());
    const auth = getAuth();

    if (!authenticatedButNotRegistered) {
      const data = await createUserWithEmailAndPassword(auth, email, password)
        .catch((error) => {
          Toast.show({
            duration: 5000,
            text: error && error.message ? error.message : 'There was an error creating the account. Restart the app and try again.',
            type: 'danger'
          });
          (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
          dispatch(createUserAuthAsync.failure())
        });
      if(data && data?.user?.uid) {
        dispatch(createUserAuthAsync.success());
        await dispatch(createUserDataRecord({ user: data.user, email, password, firstName, lastName, enrollmentReason }))
      }
    } else if (auth?.currentUser) {
      dispatch(createUserAuthAsync.success());
      await dispatch(createUserDataRecord({ user: auth?.currentUser, email, password, firstName, lastName, enrollmentReason }))
    }
  }
};

const createUserAuthAsync = createAsyncAction(
  'INITIALIZE_USER_ACCOUNT_CREATION',
  'USER_ACCOUNT_CREATION_SUCCESSFUL',
  'USER_ACCOUNT_CREATION_FAILED'
)<void, void, void>();

const createUserDataRecord = ({ email, password, firstName, lastName, enrollmentReason, user }: AccountDataObject)
  : any => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      await Promise.all([
        dispatch(setProfileName(user, firstName, lastName)),
        dispatch(sendEmailValidation(user)),
        dispatch(initializeUserInDatabase(user, firstName, lastName, email, enrollmentReason))
      ]);

      dispatch(signUpSuccess({
        userId: user.uid,
        firstName,
        lastName,
        email,
        enrollmentReason
      }));
    } catch(error){
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
    }
  }
};

const signUpSuccess = createAction('SIGN_UP_SUCCESSFUL')<SignUpSuccessObject>();

const setProfileName = (user: User, firstName: string, lastName: string): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(authProfileUpdateAsync.request());
    try {
      await user.updateProfile({displayName: `${firstName} ${lastName}` });
      dispatch(authProfileUpdateAsync.success());
    } catch(error) {
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(authProfileUpdateAsync.failure());
    }
  }
};

const authProfileUpdateAsync = createAsyncAction(
  'INITIALIZE_AUTH_PROFILE_UPDATE',
  'AUTH_PROFILE_UPDATE_SUCCESSFUL',
  'AUTH_PROFILE_UPDATE_FAILED'
)<void, void, void>();


const sendEmailValidation = (user: User): any  => {
  return async (dispatch: Dispatch) => {
    dispatch(emailVerificationAsync.request());
    try {
      await sendEmailVerification(user);
      dispatch(emailVerificationAsync.success());
    } catch(error){
      dispatch(emailVerificationAsync.failure());
    }
  }
};

const emailVerificationAsync = createAsyncAction(
  'INITIALIZE_SENDING_EMAIL_VERIFICATION',
  'SENDING_EMAIL_VERIFICATION_SUCCESSFUL',
  'SENDING_EMAIL_VERIFICATION_FAILED'
)<void, void, void>();

const initializeUserInDatabase = (user: User, firstName: string, lastName: string, email: string, enrollmentReason: string) :
  any => {
  return async (dispatch: Dispatch) => {
    dispatch(createUserDataCollectionAsync.request());
    const db = getFirestore(firebaseApp);
    const userDataRef = doc(db, 'users', user.uid);
    const userProfileRef = doc(db, 'users', user.uid, 'public', 'profile');

    try {
      await setDoc(userDataRef, {
        firstName,
        lastName,
        email,
      });

      await setDoc(userProfileRef, {
        aboutMe: '',
        profilePhotoUrl: '',
        testimony: '',
        enrollmentReason,
      });

      dispatch(createUserDataCollectionAsync.success());
    } catch(error){
      (Platform.OS === 'web' ? Sentry.Browser : Sentry.Native).captureException(error);
      dispatch(createUserDataCollectionAsync.failure());
    }
  }
};

const createUserDataCollectionAsync = createAsyncAction(
  'INITIALIZE_USER_DATABASE_CREATION',
  'USER_DATABASE_CREATION_SUCCESSFUL',
  'USER_ACCOUNT_CREATION_FAILED'
)<void, void, void>();

const initializeFormValidation = ( {
                                     firstName, lastName, email, emailConfirmation, enrollmentReason, password, confirmPassword, authenticatedButNotRegistered
                                   }: ValidationFields ) => {
  return ( dispatch: Dispatch ) => {
    const validationResults: SignUpFormResults = SignUpService.validationChecks({firstName, lastName, email: email.toLowerCase(), enrollmentReason,
      emailConfirmation: emailConfirmation.toLowerCase(), password, confirmPassword, authenticatedButNotRegistered,  dispatch});

    dispatch(processValidationResults(validationResults, authenticatedButNotRegistered));
  };
};

export {
  sendEmailValidation,
  fieldIsValid,
  firstNameValueChanged,
  enrollmentReasonChanged,
  lastNameValueChanged,
  emailValueChanged,
  emailConfirmationValueChanged,
  passwordValueChanged,
  confirmPasswordValueChanged,
  initValidation,
  processValidationResults,
  signUpSuccess,
  validateFormAsync,
  createUserAuthAsync,
  authProfileUpdateAsync,
  initializeFormValidation,
  emailVerificationAsync,
  createUserDataCollectionAsync,
};


