import { StyleSheet } from 'react-native';

const spinnerStyles = StyleSheet.create({
	spinner: {
		justifyContent: 'center',
		flex: 1,
		alignItems: 'center'
	}
});

export { spinnerStyles }