import { Tab } from "native-base";
import React from 'react';

export default class CommonService {

    static customTab = (heading: string, children: JSX.Element) => {
        return React.createElement(Tab, Object.assign({}, {
            heading, children,
            activeTabStyle: { backgroundColor: '#303030' },
            tabStyle: { backgroundColor: '#303030'},
            activeTextStyle: {color: '#ffe57f'},
            textStyle: { color: '#cccccc' }
        }));
    };


}