/**
 *File:  rn-polyfill-depricated-proptypes.js
 **/
const reactnative = require('react-native');

Object.defineProperty(reactnative, 'ColorPropType', {
    configurable: true,
    get() {
        return {};
    },
});

Object.defineProperty(reactnative, 'EdgeInsetsPropType', {
    configurable: true,
    get() {
        return {};
    },
});

Object.defineProperty(reactnative, 'PointPropType', {
    configurable: true,
    get() {
        return {};
    },
});

Object.defineProperty(reactnative, 'ViewPropTypes', {
    configurable: true,
    get() {
        return {};
    },
});